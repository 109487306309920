import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import React, { useEffect, useRef, useState } from "react";
import { getHeader } from "../Helpers/header";
import extractJSON from "../Helpers/extractJSON";
import Loader from "../Components/Loader/Loader";
import { AMAZONS3 } from "../http";
import { useTrainCV } from '../hooks/apihooks/useTrainCV';

function AiChatCandidate({ setOpen, open, Data }) {
  const handleClose = () => {
    setOpen(false);
  };

  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [docquery, setDocQuery] = useState("");
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  const { mutateAsync } = useTrainCV();

  async function trainCV() {
    const payload = {
      bucket: "hp-in-s3-bucket-prod",
      folder: Data?.resume.replace(Data?.resume?.split("/")[4], ""),
      files: [Data?.resume?.split("/")[4]],
      file_content_type: "CV",
      callback_url:
        "https://api-in.hiringplug.com/manage-candidate-service/v1/push-ai-training-status",
      callback_authentication: {
        username: "HIPL_user",
        password: "jvrQV7UDby6eug24",
      },
      user_id: "SYSTEM",
    };

    mutateAsync(payload).then((data) => {});
  }

  const onSubmit = async (data) => {
    setResponse("");
    let payLoad = {
      filename: Data?.resume.split("/")[4],
      query: docquery,
    };

    if (true) {
      setIsLoading(true);
      try {
        let header = getHeader("genai");
        const res = await fetch(process.env.REACT_APP_DOC_QUERY_API, {
          method: "POST",
          headers: header,
          body: JSON.stringify(payLoad),
        });

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }

        const reader = res.body.getReader();
        let eventStream = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            let jsonResponse =  eventStream.split("#FINAL_RESPONSE#")[1];
            let response = extractJSON(jsonResponse ? jsonResponse : eventStream);
            if(response){
              let json = response[0] || {};
              if(json?.code === 422){
                setResponse('');
                trainCV();
                toast.success('Your Candidate is added in queue. Please run your query after a few minutes')
              }else if (json?.code !== 200) {
                setResponse("");
                toast.warn(json?.message);
              }
            }
            break;
          } else {
            setIsLoading(false);
            eventStream = new TextDecoder().decode(value);
            let cleanResponse = eventStream.split("#FINAL_RESPONSE#")[0];
            setResponse((prevResponse) => prevResponse + cleanResponse);
           
          }
        }
      } catch {
        setIsLoading(false);
      }
    }
  };


  return (
    <>
      {open && (
        <React.Fragment>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-primary bg-pop-up">
            <div className="absolute top-10 rounded-[10px] my-6 mx-auto lg:w-6/12 w-80   ">
              <div
                ref={wrapperRef}
                className="border-0  shadow-lg relative flex p-4  flex-col w-full bg-white outline-none focus:outline-none gap-3 rounded-[10px]"
              >
                {isLoading ? <Loader /> : ""}
                <FaTimes
                  onClick={handleClose}
                  className="cursor-pointer text-[20px] absolute right-3 top-3"
                />

                <div className="text-[18px] font-primary font-medium  w-full flex items-center justify-center gap-2">
                  <p>Candidate Chat Assistant</p>
                  <img
                    src={`${AMAZONS3}/icons/google-gemini-icon.webp`}
                    className="w-[25px] "
                    alt="gemini"
                  />
                </div>
                <div>
                  <textarea
                    placeholder={`Enter your query about the candidate like...\n1. Candidate total experience\n2. Candidate current CTC \n3. Candidate specific skillset `}
                    rows={5}
                    onChange={(e) => setDocQuery(e.target.value)}
                    className="w-full border p-2 border-border-col placeholder:text-[14px] placeholder:text-gray rounded-sm"
                  />
                </div>
                <div className="w-full flex items-center justify-end">
                  <button
                    className="px-4 py-1 aibutton rounded-full text-white"
                    onClick={onSubmit}
                  >
                    Generate
                  </button>
                </div>
                {response ? (
                  <div className="text-[14px] flex items-start justify-center flex-col">
                    <p className="text-[16px] font-semibold">Here is your response :</p>
                    <div className="w-full bg-[#f4f5f8] p-2  rounded-md">{response}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </React.Fragment>
      )}
    </>
  );
}

export default AiChatCandidate;
