import React from "react";
import { FaArrowUp } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { SlMenu } from "react-icons/sl";
import { Link } from "react-router-dom";

export default function PlugComponent({ open, setOpen, header }) {
// console.log(header , "header")
  const { data : { campaignuid } = {} } = header || {};

  const handleAction = () => {
    setOpen((open) => !open);
  };

  return (
    <div
      className={`h-[100vh] w-full  pb-5 bg-green-50 flex items-center gap-3 justify-end flex-col `}
    >
      <div className="w-full fixed top-0 block sm:hidden">
        <div className="w-full flex items-center justify-between px-6 min-h-[80px]">
          <p className="font-robo text-[20px] text-green-900">{process.env.REACT_APP_APP_NAME}</p>
          {open ? "" : <SlMenu onClick={handleAction} size={20} />}
        </div>
      </div>
      <div className="w-10/12 flex items-center justify-center my-auto flex-col sm:flex-row sm:flex-wrap gap-4">
        <Link
          to={`/campaigns${campaignuid ? `/${campaignuid}` : ""}`}
          className=" p-3 border min-h-[80px] border-gray-200 rounded-[10px] bg-white hover:scale-105 transition-all cursor-pointer"
        >
          <div className="text-[15px] flex items-start justify-center flex-col gap-3 mb-1">
            <MdCampaign size={25} className="text-blue-400" />
            <p> Create a Campaign</p>
          </div>
        </Link>
        <Link
          to={"/jobs"}
          className=" p-3 border min-h-[80px] border-gray-200 rounded-[10px] bg-white hover:scale-105 transition-all cursor-pointer"
        >
          <div className="text-[15px] flex items-start justify-center flex-col gap-3 mb-1">
            <IoCreateOutline size={25} className="text-yellow-400" />
            <p>Create a Job</p>
          </div>
        </Link>
        <Link
          to={"/jobs"}
          className=" p-3 border min-h-[80px] border-gray-200 rounded-[10px] bg-white hover:scale-105 transition-all cursor-pointer"
        >
          <div className="text-[15px] flex items-start justify-center flex-col gap-3 mb-1">
            <IoCreateOutline size={25} className="text-yellow-400" />
            <p>Any Query ? </p>
            
          </div>
        </Link>
      </div>

      <div className="w-10/12 relative">
        <input
          className="border border-gray-300 shadow-lg  w-full h-10 rounded-full px-5 text-[15px] outline-green-700 "
          placeholder={`Message  Coffeecrew`}
        />
        <button className="bg-green-900 p-2 rounded-[20px] absolute right-3 bottom-1">
          {" "}
          <FaArrowUp color="white" />
        </button>
      </div>
      <p className="text-[10px]">
        Ai Plug can make mistakes. Check important info.
      </p>
    </div>
  );
}
