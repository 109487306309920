
import React from 'react'
// import { MdAddCall,MdOutlineEmail } from "react-icons/md";
import Header from '../Header';
import Footer from '../Footer';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function AboutUs() {
  return (
    <>
      <Header />
      <section className=''>
        <div className='patterlayer relative max-sm:h-[250px]'>
          <div className="flex justify-center items-center absolute max-sm:w-[80%] max-sm:left-[30px] max-sm:right-0 top-[50%] right-[35%] transform align-middle">
            <h1 className='text-white text-[30px] w-full max-sm:text-[20px]'>Introducing ai.hiringplug.com</h1>
          </div>
        </div>
      </section>
      <section className='py-[70px]'>
        <div className='container max-sm:px-3 m-[auto]'>
          <div className='flex justify-between flex-wrap items-start'>
            <div className='w-[48%] max-sm:w-full'>
              <div className='py-[150px] rounded-[10px] text-white text-center' style={{backgroundImage: "url('/images/video-1.jpg')"}}>
                <Link to={"https://www.youtube.com/watch?v=nfP5N9Yc72A&amp;t=28s"} className="lightbox-image video-btn">
                  <img src="/images/icon-46.png" alt="sdfdsfdssdfds"/>
                </Link>
                <h6>How Its’ Work</h6>
              </div>
              
            </div>
            <div className='w-[48%] max-sm:w-full max-sm:mt-3'>
              <h3 className='text-[40px] max-sm:text-[25px]'>Exploring the Future of Content Creation</h3>
              <p className='text-green-300'>Undertakes laborious physical exercise except.</p>
              <p>To obtain some advantage from it take a trivial example which obtain some advantage itself because it is pain but because occasionally circumstances.</p>
            </div>
          </div>
        </div>
        <div className='container max-sm:px-3 m-[auto] py-[70px]'>
          <div className='flex text-center flex-wrap'>
            <div className='w-[32%] max-sm:w-full max-sm:mb-3'>
              <h2 className='flex justify-center items-center'><span><FaArrowLeft className='text-[20px] mr-2' /></span> <span className='text-[25px] font-bold'>10X Output</span> </h2>
              <p>Trouble that are bound to ensue equal blame weakness what we like best.</p>
            </div>
            <div className='w-[32%] max-sm:w-full max-sm:mb-3'>
              <h2 className='flex justify-center items-center'><span><FaArrowLeft className='text-[20px] mr-2' /></span> <span className='text-[25px] font-bold'>10X Output</span> </h2>
              <p>Trouble that are bound to ensue equal blame weakness what we like best.</p>
            </div>
            <div className='w-[32%] max-sm:w-full max-sm:mb-3'>
              <h2 className='flex justify-center items-center'><span><FaArrowLeft className='text-[20px] mr-2' /></span> <span className='text-[25px] font-bold'>10X Output</span> </h2>
              <p>Trouble that are bound to ensue equal blame weakness what we like best.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='funfact-section py-[70px]'>
        <div className="container max-sm:px-3 m-[auto] before:content-[''] after:content-['']">
          <div className='flex justify-between flex-wrap text-white'>
            <div className='w-[48%] max-sm:w-full'>
                <h2 className='font-bold'>Copyrighters</h2>
                <div className='flex items-center'>
                  <div className='text-[80px] mr-[30px]'>24k</div>
                  <div><p>There are many variations of passages of available but the majority.</p></div>
                </div>
            </div>
            <div className='w-[48%] max-sm:w-full'>
                <h2>Copyrighters</h2>
                <div className='flex items-center'>
                  <div className='text-[80px] mr-[30px]'>24k</div>
                  <div><p>There are many variations of passages of available but the majority.</p></div>
                </div>
            </div>
            <div className='w-[48%] max-sm:w-full'>
                <h2>Copyrighters</h2>
                <div className='flex items-center'>
                  <div className='text-[80px] mr-[30px]'>24k</div>
                  <div><p>There are many variations of passages of available but the majority.</p></div>
                </div>
            </div>
            <div className='w-[48%] max-sm:w-full'>
                <h2>Copyrighters</h2>
                <div className='flex items-center'>
                  <div className='text-[80px] mr-[30px]'>24k</div>
                  <div><p>There are many variations of passages of available but the majority.</p></div>
                </div>
            </div>
          </div>
        </div>
      </section>
    <Footer />
    </>
  )
}
