import React from "react";
import endpoint from "../../../../../Helpers/endpoint";
import Http from "../../../../../http";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../Loader/Loader";
import { FaTimes } from "react-icons/fa";
import { IoSendSharp } from "react-icons/io5";
import moment from "moment";
import { useFetchAttachment } from "../../../../../hooks/apihooks/Campaigns/useFetchAttachment";
import { toast } from "react-toastify";
import { ImImage } from "react-icons/im";
import { convertToIST } from "../../../../../Helpers/convertToIST";
import { BsChatDots } from "react-icons/bs";

export default function CampaignChat({ chatid, setChat }) {
  // functions
  function getCampaign() {
    let endpoints = endpoint.campaign.getchat.replace("{chatid}", chatid);
    return Http().get(endpoints);
  }

  // queries
  const { data, isLoading } = useQuery({
    queryKey: ["Campaigns", chatid],
    queryFn: () => getCampaign(),
  });

  const groupedMessages = data?.data?.reduce((acc, message) => {
    const messageDate = moment(message.timestamp).format("YYYY-MM-DD");
    if (!acc[messageDate]) {
      acc[messageDate] = [];
    }
    acc[messageDate].push(message);
    return acc;
  }, {});

  const { isPending, mutateAsync } = useFetchAttachment();

  const getAttachment = (message_id, attachmentid) => {
    let postData = {
      message_id,
      attachmentid,
    };
    mutateAsync(postData)
      .then((response) => {
        console.log(response, "response");
        if (response?.status) {
          const link = document.createElement("a");
          link.href = response?.data?.fileurl;
          link.target = "_blank";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          toast.warn("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Failed to download attachment", error);
      });
  };


  return (
    <div className="fixed z-[10] right-2 bottom-10 sm:h-[46] border flex items-center justify-between flex-col border-gray-200 sm:w-[400px] bg-white w-[300px] rounded-[10px] shadow-xl">
      {isLoading ? <Loader /> : ""}
      <div className="aibutton py-6 w-full rounded-t-[10px]"></div>
      <div className="w-full flex items-center justify-start gap-2 p-2 border border-gray-300">
        <img
          className="w-[40px] h-[40px] rounded-full"
          src={data?.candidate?.profile_pic}
          alt="profile_pic"
        />

        <div>
          <p className="text-[14px] font-semibold">
            {data?.candidate?.full_name}
          </p>
          <p className="text-[10px]">{data?.candidate?.headline}</p>
        </div>

        <p
          className="ml-auto cursor-pointer absolute top-2 right-2 text-white"
          onClick={() => setChat(null)}
        >
          <FaTimes />
        </p>
      </div>

      <div className="w-full flex h-[320px] items-center overflow-scroll justify-start flex-col gap-1 p-2">
        {Object.keys(groupedMessages ?? {}).map((date, index) => (
          <React.Fragment key={index}>
            {/* Display the date label once for each date group */}
            <div className="text-xs font-semibold text-gray-500 my-2">
              {moment(date).local("Asia/Kolkata").calendar(null, {
                sameDay: "[Today]",
                lastDay: "[Yesterday]",
                lastWeek: "dddd",
                sameElse: "DD/MM/YYYY",
              })}
            </div>

            {/* Render messages for this specific date */}
            {groupedMessages[date].map((val, idx) => (
              <div
                key={idx}
                className={`${
                  val?.is_sender === 1
                    ? "ml-auto text-right bg-gray-200"
                    : "mr-auto bg-green-200 text-left"
                }  p-2 max-w-[80%] flex items-start justify-start gap-1 rounded-[5px]`}
              >
                {val?.is_sender === 0 && (
                  <img
                    className="w-[25px] h-[25px] rounded-full"
                    src={data?.candidate?.profile_pic}
                    alt="profile_pic"
                  />
                )}

                <div className="text-[14px] flex items-start justify-center flex-col">
                  <span className="inline-block">{val?.text}</span>
                  {val?.attachments?.map((attachment, index) => (
                    <div key={index} className="attachment">
                      {attachment?.file ? (
                        <>
                          {attachment?.type === "img" ? (
                            <a
                              target="_blank"
                              href={attachment?.file}
                              rel="noreferrer"
                            >
                              <img
                                src={attachment?.file}
                                alt="attachment"
                                style={{
                                  width: attachment?.size?.width,
                                  height: attachment?.size?.height,
                                }}
                                className="attachment-image"
                              />
                            </a>
                          ) : (
                            <a
                              target="_blank"
                              href={attachment?.file}
                              rel="noreferrer"
                            >
                              <div className="attachment-file flex items-center gap-1">
                                <ImImage />
                                <span className="file-name">Download File</span>
                              </div>
                            </a>
                          )}
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() =>
                            getAttachment(val?.message_id, attachment?.id)
                          }
                        >
                          <p className="attachment-file flex items-center gap-1">
                            {isPending ? (
                              <div role="status">
                                <svg
                                  aria-hidden="true"
                                  class="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <ImImage />
                            )}
                            <span className="file-name">Download File</span>
                          </p>
                        </button>
                      )}
                    </div>
                  ))}

                  <span className="text-[8px] ml-auto inline-block">
                    {moment(val?.timestamp)
                      .local("Asia/Kolkata")
                      .format("hh:mm A")}
                  </span>
                </div>
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
      <div className="w-full flex items-center justify-center flex-col p-2">
        <textarea
          placeholder="Write Your Message"
          className="w-full border-t border-gray-300 p-2 outline-none"
          rows={3}
        />
        <button className="aibutton p-2 rounded-full ml-auto">
          <IoSendSharp className="text-white" />
        </button>
      </div>
    </div>
  );
}
