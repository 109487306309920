import React from "react";
import ReactDOMServer from "react-dom/server";
import RatingSystem from "./RatingSystem";

export default function ProcessText(text) {
  function generateButton(candidateId, index, dynamicRatingValue) {
    const ratingSystem = ReactDOMServer.renderToString(
      <RatingSystem
        className="m-0 ml-[-12px] inline-block"
        Ratper={dynamicRatingValue / 2}
        plugpoints={true}
      />
    );
    let imgHTML = `${ratingSystem}`;

    return `<div className="flex items-center justify-start gap-2 ${
      index > 0 ? "mt-5" : ""
    } ">  <input
        value=${candidateId}
        checked
        name="candidateid"
        type="checkbox"
        className=" w-4 h-4  border-0 accent-[#00a63f] top-1 inline"
      /><span onclick="openpreview('${candidateId}')" className=" cursor-pointer  inline-block font-gothic px-3 py-[px] text-white bg-green-600 border border-green-600 rounded-full">Candidate Preview</span> ${
      dynamicRatingValue ? `${imgHTML}\n` : ""
    } </div>`;
  }

  function processText(text) {

    let newLine = text?.replace(/(\n\s*)(Rating=)/, "$1 $2");
    newLine = newLine?.replaceAll('*','')?.replaceAll('```','')?.replaceAll('^^','');
    newLine = newLine?.replace('#START-CANDIDATES#','');
    newLine = newLine?.replace('#END-CANDIDATES#','')
    
    const textWithoutConsecutiveNewlines = newLine?.replace(
      /\n(\s*\n)+/g,
      "\n"
    );

    const textWithoutSerialNumberNewlines =
      textWithoutConsecutiveNewlines?.replace(/(\d+\.\s*)\n/g, "$1");

    let processedText = textWithoutSerialNumberNewlines?.replace(
      /\*\*(.*?)\*\*/g,
      '<span style="font-weight: 600;">$1</span>'
    );

     processedText = processedText?.replace(
      /Candidate\s*(Name|domain|Location|Experience|Total Experience|Skills|Location|Summary|Contact No|Email|Contact|salary|Domain|Mobile|Gender|Certification|Description)/g,
      '<span style="font-weight: 600; text-transform: capitalize">$1</span>'
    );

    processedText = processedText?.replace(
      /(Candidate Name|Name|Candidate domain|Location|Experience|Total Experience|Skills|Candidate Location|Summary|Contact No|Email|Contact|Candidate salary|Candidate Salary|Candidate Domain|Mobile|Salary|Domain|Candidate Gender|Gender|Certification|Description)/g,
      '<span style="font-weight: 600;">$1</span>'
    );

    let candidateMatches = processedText?.match(
      /Candidate\s*id:\s*([a-zA-Z0-9]{8})|Candidate\s*id\s*:\s*([a-zA-Z0-9-]+)|Candidate_id\s*:\s*([a-zA-Z0-9-]+)|Candidateid\s*:\s*([a-zA-Z0-9-]+)|Candidate-id\s*:\s*([a-zA-Z0-9-]+)|Candidate-id\s*-\s*([a-zA-Z0-9-]+)|Candidate\s*id\s*-\s*([a-zA-Z0-9-]+)|Candidate_id\s*-\s*([a-zA-Z0-9-]+)|Candidateid\s*-\s*([a-zA-Z0-9-]+)/gi
    );

    let candidates = [];
    if (candidateMatches) {
      candidateMatches.forEach((match, index) => {
        let candidateId = "";

        if (match.includes(":")) {
          candidateId = match?.split(":")[1].trim();
        } else if (match?.includes("-")) {
          candidateId = match?.split("-")[1].trim();
        }
        candidates.push({ candidateId });
        processedText = processedText?.replace(
          new RegExp("\\s*" + match?.trim() + "\\s*", "g"),
          ""
        );
      });
    }

    let ratingMatch = processedText?.match(/Rating\s*=\s*(\d+(\.\d+)?)|Rating\s*:\s*(\d+(\.\d+)?)/g);
    if (ratingMatch && candidates?.length) {
      ratingMatch.forEach((match, index) => {
        let dynamicRatingValue = parseFloat(match.split(match.includes('Rating:') ? ':' : '=')[1]?.trim());
        if (candidates[index]) {
          let buttonHTML = generateButton(
            candidates[index]["candidateId"],
            index,
            dynamicRatingValue
          );

          processedText = processedText?.replace(match, buttonHTML);
        }
      });
    }

    return { __html: processedText };
  }

  const result = processText(text);

  return result;
}
