import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const resetpass = (postdata) => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.resetPassword}`;
    return Http().post(URL, postdata);
}

export const useResetPass = () => {
    return useMutation({
        mutationFn: resetpass,
        onSuccess: (data, variables, context) => {
          // I will fire first
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}


