import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const addProduct = ({newdata, token}) => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.product.product}`;
    const headers  = {
        "Content-Type": "application/json", 
        "Authorization": `Bearer ${token}` 
      }
    return Http().post(URL, newdata, headers);
}

const updateProduct = ({newdata, productuid ,token}) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.product.product}/${productuid}`;
  const headers  = {
      "Content-Type": "application/json", 
      "Authorization": `Bearer ${token}` 
    }
  return Http().patch(URL, newdata, headers);
}



export const useProductAdd = () => {
    return useMutation({
        mutationFn: addProduct,
        onSuccess: (data, variables, context) => {
          // I will fire first
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}

export const useProductUpdate = () => {
  return useMutation({
      mutationFn: updateProduct,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

