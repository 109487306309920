export const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 40,
    }),
  };
  
  export const ErrorStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
      minHeight: "40px",
    }),
  };

export const  MaskiD = ({id}) => {
    // Check if the ID is long enough
    if (id.length < 4) return id;

    // Get the first 3 and last 3 characters
    const start = id.substring(0, 5);
    const end = id.substring(id.length - 5);

    // Mask the middle part with asterisks
    const maskedPart = '*'.repeat(id.length - 6); // 3 at the start + 3 at the end

    // Combine and return the masked ID
    return start + maskedPart + end;
}