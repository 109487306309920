import tokenService from "./Token";

export const getHeader = (headerType) => {
  const access_token = tokenService.getAccessToken();

  let header = {};

  switch (headerType) {
    case "json":
      header = {
        "Content-Type": "application/json",
        Authorization: access_token ? `Bearer ${access_token}` : "",
      };
      break;

    case "multipart":
      header = {
        "Content-Type": "multipart/form-data",
        Authorization: access_token ? `Bearer ${access_token}` : "",
      };
      break;

      case "pdf":
      header = {
        "Content-Type": "application/pdf",
        Authorization: access_token ? `Bearer ${access_token}` : "",
      };
      break;

    case "genai":
      header = {
        "Content-Type": "application/json",
        Authorization: `Basic ${process.env.REACT_APP_GEN_AI_BASIC_AUTH_PASS}`,
        "Auth-Token": process.env.REACT_APP_GEN_AI_Auth_Token,
      };
      break;
    case "genai-stream":
      header = {
        "Content-Type": "application/json",
        Authorization: `Basic ${process.env.REACT_APP_GEN_AI_BASIC_AUTH_PASS}`,
        "Auth-Token": process.env.REACT_APP_GEN_AI_Auth_Token,
      };
      break;
    case "default":
      header = { "Content-Type": "application/json" };
      break;

    default:
      header = { "Content-Type": "application/json" };
  }

  return header;
};
