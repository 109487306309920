import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const addcandidate = ({data, token}) => {
  console.log(token);
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.candidate.candidate}`;
    const headers  = {
        "Content-Type": "multipart/form-data", 
        "Authorization": `Bearer ${token}` 
    }
    return Http().post(URL, data, headers);
}

const updatecandidate = ({newdata, token}) => {
  console.log(token);
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.candidate.candidate}/${newdata?.candidateid}`;
    const headers  = {
        "Content-Type": "multipart/form-data", 
        "Authorization": `Bearer ${token}` 
      }
    return Http().post(URL, newdata, headers);
}

export const useCandidate = () => {
  return useMutation({
      mutationFn:addcandidate,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}


export const useCandidateUpdate = () => {
  return useMutation({
      mutationFn:updatecandidate,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}


