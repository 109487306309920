import React from "react";
function Loader() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-300 bg-opacity-70 z-50">
      <div className="relative">
        <img src={`https://hp-in-web-prod.s3.ap-south-1.amazonaws.com/public/my-assets/gifs/Alloaaderimage.gif`} alt="Loading..." className="w-32 h-32 relative z-10" />
        <div className="waves absolute inset-0 z-0"></div>
      </div>
    </div>
  );
}

export default Loader;
