import { useState } from "react";
import Token from "../../../../Helpers/Token";
import { useParseProfile } from "../../../../hooks/apihooks/useParseProfile";
import { toast } from "react-toastify";
import { Spinner } from "../../../Loader/Spinner";

export default function SampleSource({ open, setOpen, data, campaign }) {
    const [totalnorecord, setTotalNoOfRecord]  = useState(10);
    const {isPending, mutateAsync} = useParseProfile();
    const handleSearch = () => {
        const skillsArray = data?.skills.split(',').map(skill => skill.trim()); // Convert to array and trim whitespace
        const includedKeywords = skillsArray?.map(item => `"${item}"`).join(' AND ') || ''; 
        const query = `"${data?.name}" "${data?.location}" AND ${includedKeywords} -intitle:"profiles" -inurl:"dir/ "site:in.linkedin.com/in/ OR site:in.linkedin.com/pub/`;
        // Encode the query for the URL
        // const encodedQuery = encodeURIComponent(query);
        const encodedQuery = {
          
        }

        mutateAsync({encodedQuery,totalnorecord, campaign:{campaign, id : data?.id }, token: Token.getAccessToken()}).then((resp) => {
            if(resp?.status===true){
              toast.success(resp?.message);
              setOpen(false);
            }
        })
    };

  return (
    <>
    {isPending ? <Spinner size="medium" /> : "" }
   
    <div
      className={`relative z-10  ${open ? `` : `hidden tansition-all`}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed  inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className=" w-[500px] absolute top-20 ">
            <button
              type="button"
              className="bg-[red] absolute z-50 text-white right-[-10px] top-[-10px] rounded-full h-[30px] w-[30px]"
              onClick={() => setOpen(false)}
            >
              X
            </button>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full rounded transition-all duration-[1000ms] h-auto">
                <h3
                    className="text-[18px] font-semibold leading-6 text-gray-900"
                    id="modal-title"
                >
                    Candidates Sourcing
                </h3>
                <div className="mt-3 text-center sm:text-left w-full">
                    <div className="w-full flex items-start justify-center flex-col gap-2">
                        <p className="text-[13px] font-medium">Source Type : Linkedin </p>
                    </div>
                    <div className="w-full flex items-start justify-center flex-col gap-2">
                        <p className="text-[13px] font-medium">Country: {data?.country}</p>
                    </div>
                    <div className="w-full flex items-start justify-center flex-col gap-2">
                    <p className="text-[13px] font-medium">Job Title : {data?.name} </p>
                    </div>
                    <div className="w-full flex items-start justify-center flex-col gap-2">
                        <p className="text-[13px] font-medium">Location or keywords to include : {data?.skills} </p>
                    </div>

                    <div className="w-full flex items-start justify-center flex-col gap-2">
                        <p className="text-[13px] font-semibold">Total no of record</p>
                        <input
                            placeholder="Total no of record"
                            value={totalnorecord}
                            onChange={(e) => setTotalNoOfRecord(e.target.value)}
                            className="w-[220px]  text-[12px] border border-gray-300 rounded-md p-2"
                        />
                        </div>
                    </div>
                <div className="py-2 sm:flex sm:flex-row-reverse">
                    <button
                        type="submit"
                        onClick={handleSearch}
                        className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
                    >
                        Generate
                    </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
