import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../../Context/ContextWrapper';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

export default function Footer() {
  const [handleCurrency, setHandleCurrency] = useState(false);
  const [handleLocation, setHandleLocation] = useState(false);
  const {currency, setCurrency, location, setLocation } = useGlobalContext();
  const handleLanguageChange = (lng) => {
    setHandleLocation(false);
    setLocation(lng);
    // router.push(router.pathname, router.asPath, { locale: lng?.code});
  };
  return (
    <footer>
      <div className='py-[70px]'>
        <div className='container max-sm:px-3 m-[auto]'>
          <div className='flex justify-start items-center flex-wrap'>
            <div className='w-[30%]  max-sm:w-full'>
              <h3 className='text-[22px] font-bold py-3'>About Us</h3>
              <p className='py-3'>These cases are perfectly simple easy to distinguish. In a free hour, our power choice is untrammelled and when nothing.</p>
              <p>© 2024 Ai.hiringplug.com. All Rights Reserved.</p>
              <Link className='py-3'>{process.env.REACT_APP_APP_NAME}</Link>
            </div>
            <div className='w-[33%]  max-sm:w-full px-3 max-sm:px-0'>
              <h3 className='text-[22px] font-bold py-3'>Useful Links</h3>
              <ul>
                <li className='py-2'><Link to='/'>Home</Link></li>
                <li className='py-2'><Link to=''>About</Link></li>
                <li className='py-2'><Link to=''>Solution</Link></li>
                <li className='py-2'><Link to=''>Resource</Link></li>
                <li className='py-2'><Link to=''>Contact Us</Link></li>
              </ul>
            </div>
            <div className='w-[33%]  max-sm:w-full px-3 max-sm:px-0'>
              <h3 className='text-[22px] font-bold py-3'>Connect With Us</h3>
              <ul>
                <li className='py-2'><Link to=''>Facebook</Link></li>
                <li className='py-2'><Link to=''>Twitter</Link></li>
                <li className='py-2'><Link to=''>Instagram</Link></li>
                <li className='py-2'><Link to=''>Linkedin</Link></li>
                <li className='py-2'><Link to=''>Pinterest</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className='bg-[#e0e0e0] py-3 relative'>
          <div className='container m-[auto] flex'>
            <div className='mr-4'>
                <div className='relative py-2 flex justify-between items-center cursor-pointer border border-[#a1a1a1] rounded px-2 w-[150px]' onClick={() => setHandleCurrency(!handleCurrency)}><p to=''>{currency?.code} {currency?.codeSym} </p> <p>{handleCurrency ?<MdArrowDropDown fontSize={25} /> :<MdArrowDropUp fontSize={25}/>}</p></div>
                <div className={`bg-[white] absolute w-[150px] border  rounded absolute top-[-120px] ${handleCurrency===false?'hidden':''}`}>
                  <div className='flex py-2 px-2 cursor-pointer hover:bg-[white] transition-all' onClick={() => {setCurrency({code: 'USD', codeSym: '$'}); setHandleCurrency(false)}}>
                    <span className='w-[50%]'>USD</span> <span className='w-[50%]'>$</span>
                  </div>
                  <div className='flex py-2 px-2 cursor-pointer hover:bg-[white] transition-all' onClick={() => {setCurrency({code: 'CAD', codeSym: 'C$'}); setHandleCurrency(false)}}>
                    <span className='w-[50%]'>CAD</span> 
                    <span className='w-[50%]'>C$</span>
                  </div>
                  <div className='flex py-2 px-2 cursor-pointer hover:bg-[white] transition-all' onClick={() => {setCurrency({code: 'INR', codeSym: '₹'}); setHandleCurrency(false)}}>
                    <span className='w-[50%]'>INR</span>
                    <span className='w-[50%]'>₹</span>
                  </div>
                </div>
            </div>
            <div className='relative'>
              <div className='py-2 flex items-center justify-between cursor-pointer border border-[#a1a1a1] rounded px-2 w-[150px]' onClick={() => setHandleLocation(!handleLocation)}>
                <p className='flex justify-between w-full'><span>{location?.name}</span> <img src={location?.flag} width={25} alt='' /> </p>
                <p>{handleLocation ?<MdArrowDropDown fontSize={25} /> :<MdArrowDropUp fontSize={25}/>}</p>
              </div>
              <div className={`bg-[white] absolute top-[-120px] w-[150px] right-0 rounded ${handleLocation===false?'hidden':''}`}>
                <div className='flex py-2 px-2 justify-between cursor-pointer hover:bg-[white] transition-all' onClick={() => handleLanguageChange({name: 'Canada', code: "en", flag: 'https://www.sahaye.com/flags/ca.png'})}><span className='px-3'>Canada</span> <img width={25} src='https://www.sahaye.com/flags/ca.png' alt=''/></div>
                <div className='flex py-2 px-2 justify-between cursor-pointer hover:bg-[white] transition-all' onClick={() => handleLanguageChange({name: 'India', code: "hi", flag: 'https://www.sahaye.com/flags/in.png'})}><span className='px-3'>India</span> <img width={25} src='https://www.sahaye.com/flags/in.png' alt=''/></div>
                <div className='flex py-2 px-2 justify-between cursor-pointer hover:bg-[white] transition-all' onClick={() => handleLanguageChange({name: 'US', code: "en", flag: 'https://www.sahaye.com/flags/us.png'})}><span className='px-3'>Us</span> <img width={25} src='https://www.sahaye.com/flags/us.png' alt=''/></div>
              </div>
            </div>
          </div>    
        </div>
      </div>
      <div className='bg-[#f3f0f7] py-3'>
        <div className='container max-sm:px-3 m-[auto]'>
          <div className='flex flex-wrap max-sm:py-5'>
            <div className='w-[50%] max-sm:w-full text-[#948fa7] max-sm:flex max-sm:flex-wrap max-sm:mb-3 text-center'>
              <Link to={'/privacy-policy'} className='max-sm:w-full max-sm:mb-2 mr-2'>Privacy Policy</Link>
              <Link to={'/terms-and-condition'} className='max-sm:w-full max-sm:mb-2  mr-2'>Terms & Condition</Link>
              <Link to={'/legal'} className='max-sm:w-full max-sm:mb-2'>Legal</Link>
            </div>
            <div className='w-[50%] max-sm:w-full text-right max-sm:text-center text-[#948fa7]'>
              <span>© copyright 2024. All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
