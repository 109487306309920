import React from "react";
import UserLayout from "../../../Layouts/Users/UserLayout";
import PlugComponent from "./PlugComponent";

export default function Plug() {
  return (
    <UserLayout>
      {({ open, setOpen, header }) => (
        <PlugComponent open={open} setOpen={setOpen} header={header} />
      )}
    </UserLayout>
  );
}
