
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaCamera } from "react-icons/fa";
import { toast } from "react-toastify";
import endpoint from "../../../Helpers/endpoint";
import { useProfile } from "../../../hooks/apihooks/useProfile";
import Http from "../../../http";
import { Spinner } from "../../Loader/Spinner";
import { get } from "../../../Helpers/Storage";
import Token from "../../../Helpers/Token";
import UserLayout from "../../../Layouts/Users/UserLayout";
import Select from "react-select";
import useDebounce from "../../../Helpers/useDebounce";
export default function Profile({open , setOpen}) {
    const {
        register,
        reset,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const [countrySearch, setCountrySearch] = useState({
        id: "",
        country_id  : "",
        search : ""
    });
    const [stateSearch, setStateSearch] = useState({
        id: "",
        state_id  : "",
        search : ""
    });
    const [citiesSearch, setCitiesSearch] = useState({
        id: "",
        state_id  : "",
        search : ""
    });
    const debcountrysearch = useDebounce(countrySearch, 500)
    const debstateSearch = useDebounce(stateSearch, 500)
    const debcitiesSearch = useDebounce(citiesSearch, 500)
    const hiddenFileInput  = useRef();
    const [avatarURL, setAvatarURL]  = useState();

    const {mutateAsync:profileSave, isPending} = useProfile();
   
    const getprofile = () => {
        const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.profile}/${get('userAuth')?.userid}`;
        return Http().get(URL,{
            "Content-type": "application/json",
            "Authorization": `Bearer ${Token.getAccessToken()}`,
        });
    }

    const {data, isLoading} = useQuery({queryKey: ['getprofile', get('userAuth')?.userid], queryFn: getprofile, enabled : Boolean(Token.getAccessToken())});

    const  onSubmit = (data) => {
        const profileId = get('userAuth')?.userid;
        const form = new FormData();
        form.append('fname', data?.fname);
        form.append('lname', data?.lname);
        form.append('mobile', data?.mobile);
        form.append('state', data?.state);
        form.append('area', data?.area);
        form.append('address1', data?.address1);
        form.append('address2', data?.address2);
        form.append('picture', avatarURL?.filename);
        form.append('country', JSON.stringify(data?.countryname));
        form.append('states', JSON.stringify(data?.states));
        form.append('cities', JSON.stringify(data?.cities));

        profileSave({form, profileId, token : Token.getAccessToken()}).then((resp) => {
            if(resp?.status===true){
                toast.success(resp?.message);
            }else{
                toast.error(resp?.message);
            }
        });
    }

    const handleChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const filename = event.target.files[0];
            const cachefile = URL.createObjectURL(filename)
            setAvatarURL({cachefile, filename});
          }
    }

    // Countries
    const getcountries = () => {
        let url = endpoint.libraries.counties+'?search='+debcountrysearch?.search;
        return Http().get(url);
    };


    const { data: Counties, isLoading : loadcountries} = useQuery({
        queryKey: ["getcountries", debcountrysearch ,debcountrysearch],
        queryFn: () => getcountries(debcountrysearch),
        enabled:Boolean(debcountrysearch)
    });

    // States
    const getstates = (name='') => {
        let url = endpoint.libraries.states+'?search='+name.search+'&country_id='+debcountrysearch.country_id;
        return Http().get(url);
    };

    const { data: States, isLoading : loadstates} = useQuery({
        queryKey: ["getstates", debstateSearch ,debcountrysearch],
        queryFn: () => getstates(debstateSearch),
        enabled:Boolean(debcountrysearch.country_id)
    });

    // Cities
    const getcities = (name='') => {
        let url = endpoint.libraries.cities+'?search='+name.search+'&country_id='+debcountrysearch.country_id+'&state_id='+debstateSearch.state_id;
        return Http().get(url);
    };

    const { data: Cities, isLoading : loadcities} = useQuery({
        queryKey: ["getstates", debcitiesSearch, debstateSearch],
        queryFn: () => getcities(debcitiesSearch),
        enabled: Boolean(debstateSearch.state_id)
    });

    useEffect(() => {
        if(data){
            console.log("Country",  data?.data?.country);
            const newdata = {
                "fname" : data?.data?.fname,
                "lname" : data?.data?.lname,
                "email" : data?.data?.email,
                "mobile" : data?.data?.mobile,
                "address1" : data?.data?.address1,
                "address2" : data?.data?.address2,
            }
           
            setAvatarURL({cachefile : data?.data?.picture  });
            reset(newdata);
            setValue("cities", { value: 1222, label:  data?.data?.city});
            setValue("countryname", { value: 1222, label:  data?.data?.country}  )
            setValue("states",  { value: 1222, label:  data?.data?.state})
        }
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

  return (
    <>
    <UserLayout>
    {isPending || isLoading ? <Spinner size={'medium'}/> : ""}
    <div className="h-[100vh]  bg-green-50 flex items-center flex-col w-full">
      <div className=" w-[95%]  h-[auto] flex justify-center max-sm:mt-[50px]">
        <div className="bg-white w-[100%] mt-[30px] mb-[30px] rounded-[10px] border border-[#e0e0e0]">
            <div className="bannerprofile  p-[30px] rounded-t-[15px]">
                <div className="relative border rounded-[50%] w-[150px] h-[150px]">
                    <label htmlFor="fileprofile" className=" cursor-pointer  rounded-[50%] bottom-[20px] right-[10px]  absolute flex justify-center items-center">
                        <FaCamera size={20} className="text-[gray]" />
                    </label>
                    <span>{avatarURL?.cachefile!=="" ? <img alt={'avatarURL'} src={avatarURL?.cachefile} referrerPolicy="no-referrer"  className="w-[150px] h-[150px] rounded-[50%] object-cover" /> :  <FaCamera className="text-[30px] text-[gray]"/>}</span>
                    <input type="file" ref={hiddenFileInput} onChange={handleChange} accept="image/*" id="fileprofile"  className="hidden" />
                </div>
            </div>
            <div className="p-[30px] max-sm:p-[15px]">
                <form onSubmit={handleSubmit(onSubmit)}>
                    
                    <div className="flex justify-between flex-wrap">
                        <div className="w-[49%] max-sm:w-full mb-3">
                            <label>First Name</label>
                            <input type="text" name="fname" {...register('fname', {required: true})} className="border rounded border-[#e0e0e0] w-full px-3 py-2"  placeholder="First Name"/>
                            {errors?.fname ? <p>{errors?.message}</p> :""}
                        </div>
                        <div className="w-[49%] max-sm:w-full mb-3">
                            <label>Last Name</label>
                            <input type="text" name="lname" {...register('lname', {required: true})} className="border rounded border-[#e0e0e0] w-full px-3 py-2"  placeholder="Last Name"/>
                        </div>
                        <div className="w-[49%] max-sm:w-full mb-3">
                            <label>Email</label>
                            <input type="text" name="email" {...register('email', {required: true})} disabled className="border rounded border-[#e0e0e0] w-full px-3 py-2"  placeholder="Email"/>
                        </div>
                        <div className="w-[49%] max-sm:w-full mb-3">
                            <label>Mobile No</label>
                            <input type="text" name="mobile" {...register('mobile', {required: true})} className="border rounded border-[#e0e0e0] w-full px-3 py-2"  placeholder="Mobile No"/>
                        </div>
                        <div className="w-[100%] mb-3">
                            <label>Address Line 1</label>
                            <input type="text" name="address1" {...register('address1')} className="border rounded border-[#e0e0e0] w-full px-3 py-2"  placeholder="Address Line 1"/>
                        </div>
                        <div className="w-[100%] mb-3">
                            <label>Address Line 2</label>
                            <input type="text" name="address2" {...register('address2')} className="border rounded border-[#e0e0e0] w-full px-3 py-2"  placeholder="Address Line 2"/>
                        </div>
                        <div className="w-[49%] max-sm:w-full mb-3">
                            <label className="w-full flex items-start justify-center flex-col mt-1">
                                <span className="text-[14px]">
                                    Country <i className="text-[red]">*</i>
                                </span>
                                <Controller
                                    name="countryname"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            Searchable
                                            onChange={(e) => 
                                                {field.onChange(e); setCountrySearch({...countrySearch, ...{country_id : e.value}})}
                                            }
                                            onInputChange={(e) => setCountrySearch({...countrySearch, ...{search : e}})}
                                            className="w-full font-primary text-sm  rounded-sm"
                                            options={Counties?.data}
                                            isLoading={loadcountries}
                                            placeholder="--Select Country--"
                                        />
                                    )}
                                />
                            </label>
                        </div>
                        <div className="w-[49%] max-sm:w-full mb-3">
                            <label className="w-full flex items-start justify-center flex-col mt-1">
                                <span className="text-[14px]">
                                    States <i className="text-[red]">*</i>
                                </span>
                                <Controller
                                    name="states"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            
                                            onChange={(e) => 
                                                {field.onChange(e); setStateSearch({...stateSearch, ...{state_id : e.value}})}
                                            }
                                            onInputChange={(e) => setStateSearch({...stateSearch, ...{search : e}})}
                                            className="w-full font-primary text-sm  rounded-sm"
                                            options={States?.data}
                                            isLoading={loadstates}
                                            isDisabled={countrySearch.country_id===""?true :false}
                                            placeholder="--Select State--"
                                        />
                                    )}
                                />
                            </label>
                        </div>
                        <div className="w-[49%] max-sm:w-full mb-3">
                        <label className="w-full flex items-start justify-center flex-col mt-1">
                                <span className="text-[14px]">
                                    City <i className="text-[red]">*</i>
                                </span>
                                <Controller
                                    name="cities"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            onChange={(e) => 
                                                {
                                                    field.onChange(e); setCitiesSearch({...citiesSearch, ...{state_id : e.value}})
                                                }
                                            }
                                            onInputChange={(e) => setCitiesSearch({...citiesSearch, ...{search : e}})}
                                            className="w-full font-primary text-sm  rounded-sm"
                                            options={Cities?.data}
                                            isDisabled={stateSearch.state_id===""?true :false}
                                            isLoading={loadcities}
                                            placeholder="--Select City--"
                                        />
                                    )}
                                />
                            </label>
                        </div>
                        <div className="w-[100%] text-center">
                            <button type="submit" className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Save Profile</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </div>
    </UserLayout>
    </>
  );
}
