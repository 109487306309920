import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

function Schedule({ setOpen, open  }) {

  const handleClose = () => {
    setOpen(false);
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onChange",
  });

  const selectRef = useRef(null);

  const [interviewOnline, setInterviewOnline] = useState(false);


  const onSubmit = (interview) => {
    let a = moment(interview.datefrom);
    let b = moment(interview.dateto);

   
    interview['interviewercc'] = interview?.interviewercc.map(data => data.value) || '';
    interview['interviewermail'] = interview.interviewermail.value;


    if (a.diff(b, "days") * -1 > 30) {
      alert("The difference between interview dates should not exceed 30 days");
      return false;
    } else {
    //   setCampaignState({ ...campaignState, interview });
      handleClose();
    }
  };
  const [timeSlots, setTimeSlots] = useState(30);




  useEffect(() => {
    if(watch('interviewermail')){
      setValue('interviewername',watch('interviewermail').name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[watch('interviewermail')])

  return (
    <>
      {open && (
        <React.Fragment>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-gothic bg-pop-up">
            <div className="relative rounded-lg my-6 mx-auto lg:w-[550px] flex items-center justify-center w-80 -top-28 ">
              {/* <FontAwesomeIcon
                onClick={handleClose}
                icon={faTimes}
                className="absolute cursor-pointer text-gray right-3 top-2 text-[18px]"
              /> */}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="bg-white w-full pt-10 pb-8 rounded-[6px] px-4 flex items-center justify-center gap-2"
              >
                {/* <p className="w-1/12"> <FontAwesomeIcon icon={faClock} /></p> */}
                <div className="w-11/12 flex items-start justify-center gap-2 flex-col">
                  <input
                    placeholder="Add Subject"
                    value={watch("interviewtype") || "Telephonic interview"}
                    readOnly
                    className="w-full text-[20px] placeholder-[#2d3748] px-2 outline-none border-b-2 border-green "
                  />

                  <div className="w-full flex items-center justify-start gap-2">
                    <label
                      className={`px-4 py-1 cursor-pointer rounded-sm ${
                        watch("interviewtype") === "Telephonic interview"
                          ? "bg-green-500 bg-opacity-10 text-green-500 font-medium"
                          : ""
                      } `}
                    >
                      Telephonic{" "}
                      <input
                        {...register("interviewtype")}
                        name="interviewtype"
                        type="radio"
                        value="Telephonic interview"
                        className="hidden"
                      />
                    </label>
                    <label
                      className={`px-4 py-1 cursor-pointer rounded-sm ${
                        watch("interviewtype") === "Face To Face"
                          ? "bg-green-500 bg-opacity-10 text-green-500 font-medium"
                          : ""
                      } `}
                    >
                      Face To Face{" "}
                      <input
                        {...register("interviewtype")}
                        name="interviewtype"
                        type="radio"
                        value="Face To Face"
                        className="hidden"
                      />
                    </label>
                    <label
                      className={`px-4 py-1 cursor-pointer rounded-sm ${
                        watch("interviewtype") === "Online"
                          ? "bg-green-500 bg-opacity-10 text-green-500 font-medium"
                          : ""
                      } `}
                    >
                      Online{" "}
                      <input
                        {...register("interviewtype")}
                        name="interviewtype"
                        type="radio"
                        value="Online"
                        className="hidden"
                      />{" "}
                    </label>
                  </div>
                  <div className="w-full flex items-center justify-between gap-1 px-2">
                  <label className="w-full">
                  <input
                      type="datetime-local"
                      // min={min}
                      // defaultValue={defaultDate}
                      {...register("datefrom", { required: true })}
                      name="datefrom"
                      className=" border-b border-border-col w-full font-gothic text-sm h-10  py-2 outline-none mt-[3px]"
                    /></label> 
                    {/* <label className="w-full">
                    <input
                      type="time"
                      name="datefromtime"
                      {...register("datefromtime", { required: true })}
                      className="w-full text-[16px] bg-white placeholder-[#2d3748] py-2 outline-none border-b border-border-col "
                    />
                    </label> */}
                    
                    <label className="w-full ">
                    <input
                      type="datetime-local"
                      // max={max}
                      // min={defaultDate}
                      // defaultValue={defaultDate}
                      {...register("dateto", { required: true })}
                      name="dateto"
                      className=" w-full font-gothic text-sm h-10 outline-none  py-2 mt-[3px] border-b border-border-col"
                    />
                    </label>
                    {/* <label className="w-full">
                    <input
                      type="time"
                      name="datetotime"
                      {...register("datetotime", { required: true })}
                      className="w-full text-[16px] bg-white placeholder-[#2d3748] py-2 outline-none border-b border-border-col "
                    />
                    </label> */}
                   
                  </div>
                  <div className="w-full flex items-center justify-end gap-2 px-2">
                    <p className="whitespace-nowrap">Slots with duration</p>
                    <select
                      name="slots"
                      {...register("slots", { required: true })}
                      value={timeSlots}
                      onChange={(e) => setTimeSlots(Number(e.target.value))}
                      className="w-full text-[16px] bg-white placeholder-[#2d3748] p-2 outline-none border-b border-border-col "
                    >
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                      <option value="60">60</option>
                    </select>
                    <p className="whitespace-nowrap">minutes</p>
                  </div>

                  {/* <select
                    className={` ${
                      errors?.interviewermail
                        ? "validationerror shake"
                        : "validationerror"
                    } w-full text-[16px] bg-white border-b border-border-col placeholder-[#2d3748] p-2 outline-none   `}
                    name="interviewermail"
                    {...register("interviewermail", { required: true })}
                  >
                    <option>Interviewer Email</option>
                    {Users
                      ?.filter((data) => data.active === 1)
                      ?.map((data) => (
                        <option key={uuid()} value={data.username}>
                          {data.username}
                        </option>
                      ))}
                  </select> */}
                   <input
                    placeholder="Interviewer Email"
                    name='interviewermail'
                    className="w-full text-[14px] py-1 placeholder-[#2d3748] px-2 outline-none border-b border-green "
                  />
                  
                  <input
                    name="interviewername"
                    {...register("interviewername", { required: true })}
                    placeholder="Add Interviewer Name"
                    className={` ${
                      errors?.interviewername
                        ? "validationerror shake"
                        : "validationerror"
                    } w-full text-[14px] placeholder-[#2d3748] px-2 py-1 outline-none  border-b border-border-col`}
                  />
                  <div
                    ref={selectRef}
                    className="w-full flex items-center justify-center flex-col gap-2"
                  >
                  
                  <input
                    placeholder="Add colleague email"
                    name='interviewercc'
                    className="w-full text-[14px] py-1 placeholder-[#2d3748] px-2 outline-none border-b border-green "
                  />
                   
                  </div>

                  <textarea
                    name="comment"
                    {...register("comment")}
                    placeholder="Message for candidate"
                    className="w-full bg-[#edf0f5] text-[16px] placeholder-[#2d3748] p-2 outline-none  rounded-sm "
                  />

                  {watch("interviewtype") === "Face To Face" ? (
                    <input
                      name="location"
                      {...register("location", { required: true })}
                      placeholder="Location"
                      className={` ${
                        errors?.location
                          ? "validationerror shake"
                          : "validationerror"
                      } w-full text-[16px] placeholder-[#2d3748] p-2 outline-none border-b border-gray-300 `}
                    />
                  ) : (
                    ""
                  )}

                  {watch("interviewtype") === "Online" ? (
                    <div className="w-full flex items-center gap-2 justify-start ">
                      <button
                        onClick={() => setInterviewOnline((v) => !v)}
                        className="whitespace-nowrap px-4 py-1 bg-green text-white font-medium rounded-sm"
                      >
                        Online Interview Link
                      </button>
                      {interviewOnline ? (
                        <input
                          placeholder="Link"
                          name="interviewlink"
                          {...register("interviewlink", { required: true })}
                          className={` ${
                            errors?.interviewlink
                              ? "validationerror shake"
                              : "validationerror"
                          } w-full text-[16px] placeholder-[#2d3748] p-2 outline-none  `}
                        />
                      ) : (
                        ""
                      )}{" "}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="w-full flex items-center justify-end gap-2">
                    <button
                      onClick={handleClose}
                      type="button"
                      className="px-4 py-1 bg-admin-table-gray text-gray font-medium rounded-sm"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-1 bg-green-500 text-white font-medium rounded-sm"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
        </React.Fragment>
      )}
    </>
  );
}

export default Schedule;
