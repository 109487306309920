import { BsStars } from "react-icons/bs";
import { FaSearch, FaTimes } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
export default function NormalizeData({ openNoramal, setOpenNormal, data }) {

  return (
  <div
      className={`fixed top-0 right-0 w-[30%] shadow-lg h-[100vh] overflow-auto bg-[white] z-10 transform  transition-transform duration-500 ease-in-out  ${openNoramal ? ' translate-x-0 ' : 'translate-x-full'}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
  >
    <div className="">
      <div className="flex min-h-full items-end justify-center text-center sm:items-center relative">
        <div className="relative transform text-left transition-all sm:w-full ">
          <div className="w-full break-words">
            <div className="sm:flex flex-col rounded sm:items-start w-full">
              <button className="" onClick={() => setOpenNormal(false)}><FaTimes  size={30} className="px-2 py-2 text-[red]" /></button>
            </div>
            <div className="px-3">
              <div className="py-2">
              <label className="flex items-center pb-1"> <BsStars /> Gobal Query Using Gen AI</label>
                <textarea className="border w-full py-2 px-2 rounded" rows={5} placeholder="Write your query here to normalize data"></textarea>
              </div>
              <div>
                <button className="text-white bg-green-600 text-[14px] px-4 py-1 rounded-[5px] mr-3"> Run </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
