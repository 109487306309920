
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react"

import Http from "../../../http";
import endpoint from "../../../Helpers/endpoint";
import Token from "../../../Helpers/Token";
import { Spinner } from "../../Loader/Spinner";
import { useAtsConnect, useSetOrg } from "../../../hooks/apihooks/useAtsConnect";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import Atsintegrationlogs from "./Atsintegrationlogs";
import Atsintegration from "./Atsintegration";
import { Link } from "react-router-dom";

const AdminConnectATS = ({open, setOpen}) => {
    const [tabknit, setTabKnit] = useState('ats-and-hris');
    const {isPending} =  useAtsConnect();
    const {isPending: orgLoadin } = useSetOrg();

    const getIntegrationdata = () => {
        let originOrgId  = "hiringplug";
        const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.webhook.ats_integration}/${originOrgId}`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }
    const { isLoading : integrateloadind} = useQuery({queryKey : ['integrationdata'], queryFn : getIntegrationdata, enabled: Boolean(Token.getAccessToken())})

    // const getorgdetail = () => {
    //     const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.knit.getorgdetail}`;
    //     return Http().get(URL, {
    //         'Authorization' : `Bearer ${Token.getAccessToken()}`,
    //     });
    // }
    // const {isLoading : orgload, refetch} = useQuery({queryKey : ['getorgdetail'], queryFn : getorgdetail, enabled: Boolean(Token.getAccessToken())});

    return (
        <AdminLayouts>
        {isPending || integrateloadind || orgLoadin ? <Spinner size={'medium'} /> : ""}
        <div className={`w-full px-10 py-10 bg-green-50 flex items-center flex-col`}>
            <div className="border-b border-gray-200 dark:border-gray-700 w-full">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    <li className="me-2" onClick={() => setTabKnit('ats-and-hris')}>
                        <Link className={`${tabknit==='ats-and-hris'? 'text-blue-600 border-blue-600' : ''} inline-flex items-center   border-b-2  justify-center p-4  rounded-t-lg dark:text-blue-500 dark:border-blue-500 group`}>
                            <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                            </svg>ATS and HRIS
                        </Link>
                    </li>
                    <li className="me-2" onClick={() => setTabKnit('logs')}>
                        <Link className={`${tabknit==='logs'? 'active text-blue-600 border-blue-600 ' : ''} inline-flex items-center   border-b-2  justify-center p-4  rounded-t-lg dark:text-blue-500 dark:border-blue-500 group`} aria-current="page">
                            <svg className="w-4 h-4 me-2 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>Logs
                        </Link>
                    </li>
                    <li className="me-2" onClick={() => setTabKnit('settings')}>
                        <Link  className={`${tabknit==='settings'? 'active text-blue-600 border-blue-600 ' : ''} inline-flex items-center border-b-2 justify-center p-4 rounded-t-lg dark:text-blue-500 dark:border-blue-500 group`}>
                            <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z"/>
                            </svg>Settings
                        </Link>
                    </li>
                </ul>
                <div className="bg-white px-3 w-[100%] overflow-x-auto py-3">
                    {
                    tabknit==='ats-and-hris' ?  <Atsintegration />: 
                    tabknit==='settings' ? 'Setting': 
                    tabknit==='logs' ? <Atsintegrationlogs />: ""
                        }
                </div>
            </div>
        </div>
        </AdminLayouts>
    );
}

export default AdminConnectATS;