const CVSource = [
    { value: "Naukri - Job portal", label: "Naukri - Job portal" },
    { value: "Linkedin", label: "Linkedin" },
    { value: "Indeed - Job portal", label: "Indeed - Job portal" },
    { value: "Monster - Job portal", label: "Monster - Job portal" },
    { value: "Shine - Job portal", label: "Shine - Job portal" },
    { value: "Timesjobs - Job portal", label: "Timesjobs - Job portal" },
    { value: "IIMjobs - Job portal", label: "IIM jobs - Job portal" },
    { value: "Reference", label: "Reference" },
    { value: "Internal Database", label: "Internal Database" },
    { value: "workable", label: "workable" },
    { value: "Others", label: "Others (Please specify)" },
  ];

  const NoticePeriod = [
    { value: "Immediate", label: "Immediate" },
    { value: "Serving Notice period", label: "Serving Notice period" },
    { value: "15", label: "15 days" },
    { value: "30", label: "30 days" },
    { value: "45", label: "45 days" },
    { value: "60", label: "60 days" },
    { value: "75", label: "75 days" },
    { value: "90", label: "90 days" },
    { value: "Other", label: "Other - specify days" },
  ];


  const Genders = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "LGBTQI", label: "LGBTQI" },
    { value: "Undisclosed", label: "Undisclosed / Prefer not to say" },
  ];


export {CVSource, NoticePeriod, Genders};