import axios from "axios";
import { getHeader } from "../../Helpers/header";
import { useMutation } from "@tanstack/react-query";

const Create = async (postData) => {
  try {
    const newEndpoints = process.env.REACT_APP_ANTHROPIC_CREATED_JD;
    let headers = getHeader("genai");
    const res = await axios.post(newEndpoints, postData, { headers });
    return res.data;
  } catch (error) {
    console.error("An error occurred while JD the post:", error);
    throw error;
  }
};

export function useCreateJD() {
    return useMutation({
        mutationFn: Create,
        onSuccess: (data, variables, context) => {
         
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}
