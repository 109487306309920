
import React from "react";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import { Link } from "react-router-dom";
export default function APIDashboard() {
  return (
    <AdminLayouts>
      <div className="px-10 py-5 h-[100vh] w-full bg-green-50">
        <div className="p-6 bg-gray-100">
          <h2 className="text-2xl font-bold mb-6">API Dashboard Overview</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Link to={'/admin/api-list'} className="bg-white shadow-lg rounded-lg p-4">
              <h3 className="font-semibold" >Active APIs</h3>
              <p className="text-xl">12</p>
            </Link>
            <div className="bg-white shadow-lg rounded-lg p-4">
              <h3 className="font-semibold">API Errors Today</h3>
              <p className="text-xl">5</p>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4">
              <h3 className="font-semibold">Requests Processed</h3>
              <p className="text-xl">5000</p>
            </div>
          </div>
        </div>
      </div>
    </AdminLayouts>
  );
}
