import Http from "../../http";
import endpoint from "../../Helpers/endpoint";
import {useMutation } from "@tanstack/react-query";

const register = (postdata) => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.register}`;
    return Http().post(URL, postdata);
}

const twoauthlogin = (postdata) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.towauthsignin}`;
  return Http().post(URL, postdata);
}

const loginUser = (postdata) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.signin}`;
  return Http().post(URL, postdata);
}

const singuplogin = (postdata) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.googlesinguplogin}`;
  return Http().post(URL, postdata);
}

const googleLoginSingup = (postdata) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.googlesinguplogin}`;
  return Http().post(URL, postdata);
}



export const useSignup = (postdata) => {
    return useMutation({
        mutationFn: register,
        onSuccess: (data, variables, context) => {
          // I will fire first
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}


export const useLogin = (postdata) => {
  return useMutation({
      mutationFn: loginUser,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

export const useSingupLogin = () => {
  return useMutation({
      mutationFn: singuplogin,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

export const useTwoAuthLogin = () => {
  return useMutation({
      mutationFn: twoauthlogin,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}


export const useGoogleSingupLogin = () => {
  return useMutation({
      mutationFn: googleLoginSingup,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}



