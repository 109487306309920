import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CiSearch } from "react-icons/ci";
import { WiStars } from "react-icons/wi";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";
import extractJSON from "../../../Helpers/extractJSON";
import { useCreateJD } from "../../../hooks/apihooks/useCreateJD";
import Loader from "../../Loader/Loader";
import { ExperienceLevel } from "./config";
import { useAddJob } from "../../../hooks/apihooks/jobs/useAddJob";
import { toast } from "react-toastify";
import { useCreateCampaign } from "../../../hooks/apihooks/Campaigns/useCreateCampaign";

export default function JobQuery() {
  const { handleSubmit, register, getValues, control, watch } = useForm();
  const router = useNavigate();
  const { mutateAsync: Mutate, isPending : campaignStatus } = useCreateCampaign();
  const { isPending, mutateAsync } = useCreateJD();
  const { isPending: status, mutateAsync: createJob } = useAddJob();
  function createCampaign(query) {
    Mutate({}).then((res) => {
      if (res?.status) {
        router(
          `/campaigns/${res?.data?.campaignuid}?query=true&prompt=${btoa(
            query
          )}`,
          { scroll: false }
        );
      }
    });
  }

  const [json, setJson] = useState("");
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 40,
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "0px",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    placeholder: (base) => ({
      ...base,
      color: "gray",
    }),
    valueContainer: (base) => ({
      ...base,
      fontSize: "13px", // Set text size inside the select
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "13px", // Set text size for the selected option
    }),
    input: (base) => ({
      ...base,
      fontSize: "13px", // Set text size for the input field
    }),
  };

  function addJob() {
    let payload = {
      jobtitle: json?.job_description?.jobTitle,
      experiencefrom: getValues("experiencefrom")?.value,
      experienceto: getValues("experienceto")?.value,
      location: getValues("location")?.value,
      mandatoryskills: json?.job_description?.mandatorySkillsets,
      additionalskills: json?.job_description?.goodToHaveSkillsets,
      description: json?.job_description?.jobDescription,
    };
    createJob(payload).then((res) => {
      console.log(res?.status);
      if (res?.status) {
        toast.success(res?.message);
      } else {
        toast.warn("Something went wrong");
      }
    });
  }

  const onSubmit = (data) => {
    let postData = {
      job_role: data.jobtitle,
      skills: data.skills?.map((x) => x.label).toString(),
      experience_range: `${data.experiencefrom.value} to ${data.experienceto.value} years`,
      user_id: "SYSTEM",
      location: data.location.value,
    };

    mutateAsync(postData).then((res) => {
      if (res?.data?.summary) {
        let json = extractJSON(res?.data?.summary);
        let data = json.length ? json[0] : {};
        setJson(data);
      }
    });
  };

  // let navigate =
  function generateCandidates() {
    let jobtitle = watch("jobtitle");
    let skills = watch("skills")
      ?.map((x) => x.label)
      .toString();
    let experience = `${watch("experiencefrom")?.value} to ${
      watch("experienceto")?.value
    }`;
    let location = watch("location")?.value;
    let query = `Share a list of 10 candidates for ${jobtitle} profile with  experience from ${experience} years from location ${location} with a skillset of ${skills}`;

    createCampaign(query);
  }

  return (
    <div className="w-full md:[80%] h-[100vh] flex items-center justify-start flex-col gap-4  bg-green-50 overflow-y-scroll pb-10">
      {isPending ? <Loader width={100} /> : ""}
      {status ? <Loader width={100} /> : ""}
      {campaignStatus ? <Loader width={100} /> : ""}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" w-full  pb-5 flex items-center gap-3 justify-start pt-10 flex-col"
      >
        <div className="w-[95%] flex items-center justify-start">
          <p className="text-[16px] font-medium">
            Automate Job Creation with AI-Plug Solutions
          </p>
        </div>
        <div className="w-[95%] flex items-center justify-center gap-4 mt-5">
          <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
            <CiSearch />

            <input
              placeholder="Job Title"
              name="jobtitle"
              {...register("jobtitle")}
              className="w-full outline-none bg-transparent h-[38px] p-2 text-[13px] placeholder-gray-500"
            />
          </label>
          <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
            <Controller
              name="location"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  styles={customStyles}
                  className="w-full bg-transparent"
                  placeholder="Select location"
                  Searchable
                  options={[{ label: "Noida", value: "Noida" }]}
                />
              )}
            />
          </label>
        </div>
        <div className="w-[95%] flex items-center justify-center gap-4 mt-5">
          <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
            <Controller
              name="experiencefrom"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  styles={customStyles}
                  className="w-full bg-transparent"
                  placeholder="Experience from"
                  Searchable
                  options={ExperienceLevel}
                />
              )}
            />
          </label>
          <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
            <Controller
              name="experienceto"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  styles={customStyles}
                  className="w-full bg-transparent"
                  placeholder="Experience to"
                  Searchable
                  options={ExperienceLevel}
                  isOptionDisabled={(option) =>
                    Number(option.value) <=
                    Number(getValues("experiencefrom.value"))
                      ? true
                      : false
                  }
                />
              )}
            />
          </label>
        </div>
        <div className="w-[90%] px-[20px] flex items-center justify-center gap-4 mt-5">
          <label className="w-full flex items-center justify-center  border-b  border-gray-300 ">
            <Controller
              name="skills"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Creatable
                  {...field}
                  styles={customStyles}
                  className="w-full bg-transparent"
                  placeholder="Skills"
                  Searchable
                  isMulti
                />
              )}
            />
          </label>
        </div>
        <div className="w-[90%] flex items-center justify-end px-4">
          {watch("jobtitle") &&
          watch("skills") &&
          watch("experienceto") &&
          watch("experiencefrom") ? (
            <div className="flex items-center justify-center gap-2">
              <button
                type="submit"
                className="flex items-center justify-center text-[14px] aibutton px-4 py-1 rounded-full text-white hover:scale-90 transition-all"
              >
                <WiStars size={20} /> {json ? "Re-Generate" : "Generate"}
              </button>
              {json ? (
                <>
                  <button
                    onClick={generateCandidates}
                    type="button"
                    className="flex items-center justify-center text-[14px] aibutton px-4 py-1 rounded-full text-white hover:scale-90 transition-all"
                  >
                    Search Candidates
                  </button>
                  <button
                    onClick={addJob}
                    type="button"
                    className="flex items-center justify-center text-[14px] bg-green-600 px-4 py-1 rounded-full text-white hover:scale-90 transition-all"
                  >
                    Save this Job
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
      {json ? (
        <div className="w-[88%] border border-gray-200 bg-white p-8 rounded-md gap-3 flex items-start justify-center flex-col">
          <p className="font-medium text-[16px]">
            Job Title:{" "}
            <span className="font-normal text-[14px]">
              {json?.job_description?.jobTitle}
            </span>
          </p>
          <div className="font-medium text-[16px]">
            {" "}
            Job Overview:{" "}
            <div
              className="text-[14px] font-normal mt-3"
              dangerouslySetInnerHTML={{
                __html: json?.job_description?.jobDescription,
              }}
            />{" "}
          </div>
          <p className="font-medium text-[16px]">
            Mandatory Skills:{" "}
            <span className="font-normal text-[14px]">
              {json?.job_description?.mandatorySkillsets}
            </span>
          </p>
          <p className="font-medium text-[16px]">
            Good ToHave Skills:{" "}
            <span className="font-normal text-[14px]">
              {json?.job_description?.goodToHaveSkillsets}
            </span>
          </p>

          <p className="font-medium text-[16px]">Screening Questions: </p>

          {json?.job_description?.screeningQuestions?.map((data, index) => {
            return (
              <p className="font-normal text-[14px]" key={data}>
                {index + 1}. {data}
              </p>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
