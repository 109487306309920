import { useState } from "react";
import { useCopyPastJob } from "../../../../hooks/apihooks/useParseJob";
import Token from "../../../../Helpers/Token";
import { toast } from "react-toastify";
import PreviewPopup from "./PreviewPopup";
import { Spinner } from "../../../Loader/Spinner";
export function JobCopyPast({setGoogleSearch, campaign}){  
    const [jobData, setCopyPastData] = useState('');
    const [data, setData] = useState({});
    const {mutateAsync, isPending} = useCopyPastJob();
    const [open, setOpen] = useState();
    const handSubmitData = (e) => {
      e.preventDefault();
      mutateAsync({JdDescription : jobData, token : Token.getAccessToken()}).then((resp) => {
        if(resp?.status===true){
          setData(resp);
          setOpen(true);
        }else{
          toast.error(resp?.error);
        }
      })
    }

    return (
      <>
        {isPending ? <Spinner size="medium" /> : ""}
        <PreviewPopup   open={open} setOpen={setOpen} getdata={data} setGoogleSearch={setGoogleSearch} />
        <form className="w-full" onSubmit={handSubmitData}>
        <div className="mt-3 text-center sm:text-left w-full">
          <div className="w-full flex items-start justify-center flex-col gap-2">
            <p className="text-[13px] font-medium">Job details copy and past</p>
            <textarea
              placeholder="E.g 
                      Job Title: Prompt Engineer
                      Location: Noida (100km radius)
                      Job Summary: We are seeking a talented Prompt Engineer to design, develop, and optimize prompts for AI models, particularly large language models (LLMs). The ideal candidate will collaborate with cross-functional teams to understand business requirements and translate them into effective prompts.

                      Key Responsibilities:

                      Design, develop, and optimize prompts for AI models.
                      Collaborate with teams to gather requirements and translate them into effective prompt strategies.
                      Implement and fine-tune AI models using Python and relevant frameworks.
                      Conduct experiments to improve prompt performance and model outputs.
                      Analyze results from AI model interactions to refine strategies.
                      Stay updated with the latest advancements in AI and NLP technologies.
                      Develop and maintain documentation for best practices in prompt engineering.
                      Troubleshoot and debug issues related to AI model responses and prompt design.
                      Collaborate with data scientists and machine learning engineers to enhance model performance.
                      Qualifications:

                      Bachelor’s degree in Computer Science, Engineering, or a related field.
                      Experience with AI, machine learning, and natural language processing.
                      Proficiency in Python and relevant frameworks (e.g., TensorFlow, PyTorch).
                      Strong analytical skills and attention to detail.
                      Ability to work collaboratively in a team environment.
                      Preferred Skills:

                      Experience with prompt design and optimization.
                      Familiarity with AI model integration into production systems.
                      Knowledge of state-of-the-art NLP techniques and technologies.
                      Why Join Us:

                      Opportunity to work on cutting-edge AI technologies.
                      Collaborative and innovative work environment.
                      Competitive salary and benefits."
              value={jobData}
              rows={5}
                onChange={(e) => setCopyPastData(e.target.value)}
              className="w-full text-[12px] border border-gray-300 rounded-md p-2"
            />
          </div>
        </div>
        <div className="py-3 sm:flex sm:flex-row-reverse">
            <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto">
                Generate
            </button>
            </div>
        </form>
    </>
  )
}