
// import Http from '@/http'
// import endpoint from '@/utils/endpoint'
import { useQuery } from '@tanstack/react-query'
// import { useRouter } from 'next/navigation'
// import { useSession } from 'next-auth/react'
import React, { useEffect, useState } from 'react'
// import { Spinner } from '@/components/Spinner';
// import { useGlobalContext } from '@/context/ContextWrapper';
import Header from '../../Website/Header';
import Footer from '../../Website/Footer';
import endpoint from '../../../Helpers/endpoint';
import Http from '../../../http';
import { useGlobalContext } from '../../../Context/ContextWrapper';
import { Spinner } from '../../Loader/Spinner';
import { useParams } from 'react-router-dom';

export default function CartProduct() {
   const {productuid} = useParams();
  const getsingleplan = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.plan.plandetail}/${productuid}`;
    return Http().get(URL);
  }
  const [newamountprice,setAmountVar] = useState({})
  const {currency} = useGlobalContext();

  const {data, isLoading} = useQuery({queryKey: ['getsingleplan', productuid], queryFn : getsingleplan});
  
  useEffect(() => {
    if(data){
      let filterprice = JSON.parse(data?.data.currency);
      const newprice = filterprice.filter((mony) => mony?.currency === currency?.code);
      setAmountVar(newprice[0]);
    }
  },[data, currency])
  return (
    <>
    <Header />
      {isLoading ? <Spinner size='medium'/> :""}
      <section className=''>
        <div className='patterlayer2 relative max-sm:h-[250px]'>
          <div className="flex items-center justify-center h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h1 className='text-[white] text-[30px] w-full max-sm:text-[20px]'>Cart Detail</h1>
          </div>
        </div>
      </section>
      <div className="max-w-5xl mx-auto my-8 p-6 bg-white border shadow rounded-lg">
        <section className="mb-6">
          <h1 className="text-2xl font-bold text-gray-800">Your Cart</h1>
          <h2 className="text-xl font-semibold text-gray-800">{data?.data?.productname} Product - 1 {data?.data?.category} Plan</h2>
          <p className="text-gray-600 mt-2">Price: <span className="font-semibold"><del>{Number(newamountprice?.original_price).toLocaleString()}</del> <span className='text-[18px]'> {currency?.code} {Number(newamountprice?.sale_price).toLocaleString()} </span></span></p>
          <ul className="mt-4 list-disc pl-6 text-gray-600">
            <li>Generate 10k Words</li>
            <li>40+ Use Cases</li>
            <li>20+ Languages</li>
            <li>50+ Templates</li>
            <li>1 Brand Voice</li>
          </ul>
        </section>
        {/* Billing Details Form */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800">Billing Details</h2>
          <form className="mt-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="Full Name"
                className="p-2 border border-gray-300 rounded-md"
              />
              <input
                type="email"
                placeholder="Email Address"
                className="p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                placeholder="Address"
                className="p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                placeholder="City"
                className="p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                placeholder="State"
                className="p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                placeholder="ZIP Code"
                className="p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                placeholder="Country"
                className="p-2 border border-gray-300 rounded-md"
              />
            </div>
          </form>
        </section>
        {/* Order Details */}
        <section className="mt-6 flex justify-between items-center border-t border-gray-200 pt-4">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">Order Summary</h2>
            <p className="text-gray-600 mt-2">Tax and shipping will be calculated at checkout.</p>
          </div>
          <div>
            <p className="text-lg font-semibold text-gray-700">Subtotal: {currency?.code} {Number(newamountprice?.original_price).toLocaleString()} </p>
            <p className="text-lg font-semibold text-green-700">Discount : - {currency?.code} {Number(newamountprice?.original_price - newamountprice?.sale_price).toLocaleString() } </p>
            <p className="text-lg font-semibold text-gray-800">Shipping: Calculated at checkout</p>
            <p className="text-xl font-bold text-gray-800 mt-2">Total: {currency?.code} {Number(newamountprice?.sale_price).toLocaleString()} </p>
          </div>
        </section>
        {/* Checkout Button */}
        <div className="mt-6 flex justify-end">
          <button className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            Proceed to Checkout
          </button>
        </div>
      </div>
      <Footer />
    </>
  )
}
