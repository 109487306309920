import React, { useState } from "react";
import { FiFilter } from "react-icons/fi";
import { MdOutlineEmail, MdShare } from "react-icons/md";
import { TbFileExport } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import Tooltip from "../../../../Helpers/Tooltip";
import { useQuery } from "@tanstack/react-query";
import endpoint from "../../../../Helpers/endpoint";
import Http from "../../../../http";
import moment from "moment";
import { IoIosArrowForward } from "react-icons/io";
import { Spinner } from "../../../Loader/Spinner";
import { useCreateCampaign } from "../../../../hooks/apihooks/Campaigns/useCreateCampaign";
import { FaCheckCircle } from "react-icons/fa";
import { FaPauseCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import useDebounce from "../../../../Helpers/useDebounce";
import SelectCampaignPopUp from "../../../../Popups/SelectCampaignPopUp";

export default function CampaignsListing({ header }) {
  // params
  let navigate = useNavigate();
  
  // state
  const [ query, setQuery ] = useState({
    keyword : '',
    status : ''
  });

  const [actions, setActions] = useState({
    filters : false
  })

  const [ open, setOpen ] = useState(false);
  // hooks
  const { isPending, mutateAsync } = useCreateCampaign();
  let debouncesearch = useDebounce(query.keyword,500)

  // functions
  function getCampaign(keyword,status) {
    let endpoints = `${endpoint.campaign.getall}?keyword=${keyword}&status=${status}`;
    return Http().get(endpoints);
  }

  // queries
  const { data, isLoading } = useQuery({
    queryKey: ["Campaigns",debouncesearch,query.status],
    queryFn: () => getCampaign(debouncesearch,query.status),
  });
  // handlers

  const queryHandler = (key) => (value) => {
    setQuery((prevState) => ({
      ...prevState,
      [key] : value
    }))
   };

   const actionHandler = (key) => (value) => {
     setActions((prevState) => ({
      ...prevState,
      [key] : value
    }))
   };

  const createCampaign = () => {
    if (header?.data?.campaignuid) {
      navigate(`/campaigns/${header?.data?.campaignuid}`);
    } else {
      mutateAsync({}).then((res) => {
        if (res?.status) {
          navigate(`/campaigns/${res?.data?.campaignuid}`);
        } else {
          console.log(res);
        }
      });
    }
  };


   function handleCampaign() {
    
   }

  return (
    <div className="w-full flex items-center bg-green-50 min-h-[100vh] justify-start py-10 flex-col">
      <div className="w-11/12 flex items-start justify-center gap-2 flex-col">
        <p className="bg-white w-full text-[18px] p-3 border border-gray-200">
          Campaigns
        </p>

        <SelectCampaignPopUp open={open} setOpen={setOpen} />
        <div className="w-full flex items-center justify-between p-3  bg-white border border-gray-200">
          <div className="flex items-center justify-center gap-2">
            <input
              placeholder="Search"
              value={query.keyword}
              onChange={e => queryHandler('keyword')(e.target.value)}
              className="min-w-[300px] px-3 text-[14px] h-8 border border-gray-200"
            />
          </div>
          <div className="flex items-center justify-center gap-2 ">
            <Tooltip content="Filters">
              <div onClick={() => actionHandler('filters')(!actions.filters)} className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                <FiFilter />
              </div>
            </Tooltip>
            <Tooltip content="Share">
              <div className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                <MdShare />
              </div>
            </Tooltip>

            <Tooltip content="Email">
              <div className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                <MdOutlineEmail />
              </div>
            </Tooltip>

            <Tooltip content="Export">
              <div className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                <TbFileExport />
              </div>
            </Tooltip>

            <button
              onClick={() => setOpen(true)}
              className="text-white bg-green-600 text-[14px] px-4 py-1 rounded-[5px]"
            >
              + Create Campaign
            </button>
          </div>
         
        </div>
        {actions.filters ? <div className="w-full flex items-center justify-between p-3  bg-white border border-gray-200">
          <select onChange={e => queryHandler('status')(e.target.value)} value={query.status} className="w-4/12 border border-gray-300 rounded-[4px] p-2">
            <option value=''>Status</option>
            <option value='DRAFT'>Draft</option>
            <option value='ACTIVE'>Active</option>
            <option value='INACTIVE'>Inactive</option>
            <option value='DELETED'>Deleted</option>
          </select>
        </div> : ''}
        
        {isLoading || isPending ? <Spinner size="medium" /> : ""}
        <div className="w-full flex items-center justify-center flex-col gap-3">
          {data?.data?.data?.map((data) => (
            <div
              key={data.campaignid}
              className=" w-full flex items-start flex-col justify-center border border-border-col bg-white boxShadow rounded-[6px] py-5 px-6 gap-2 shadow-sm"
            >
              <div className="w-full flex items-center justify-between flex-row gap-2 ">
                {data?.status !== "DRAFT" ? (
                  <Link
                    to={data?.is_for_linkedin  ? `/campaigns-external/${data?.campaignuid}` : `/campaigns/${data?.campaignuid}/`}
                    target="_blank"
                    className="text-[18px] w-[80%] hover:text-admin-blue flex gap-1 items-center justify-start"
                  >
                    <b>{data.campaigntitle}</b>
                    <FaExternalLinkAlt className="text-blue-500 font-light text-[14px]" />
                  </Link>
                ) : (
                  <Link
                    to={`/campaigns/${data?.campaignuid}/`}
                    className="text-[18px] w-[80%] hover:text-admin-blue flex gap-1 items-center  justify-start"
                  >
                    <b>{data.campaigntitle}</b>
                    <FaExternalLinkAlt className="text-blue-500 font-light text-[14px]" />
                  </Link>
                )}

                <div className="flex items-start w-[20%] justify-between flex-col">
                  <div className="text-[16px] w-full  flex justify-start items-center flex-row gap-2 whitespace-nowrap">
                    <b>
                      {" "}
                      {data?.status === "ACTIVE"
                        ? "Active"
                        : data?.status === "INACTIVE"
                        ? "In-Active"
                        : data?.status === "DRAFT"
                        ? "Draft"
                        : "Closed"}{" "}
                    </b>
                    {data?.status === "ACTIVE" ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : data?.status === "INACTIVE" ? (
                      <FaPauseCircle className="text-orange-500" />
                    ) : data?.status === "DELETED" ? (
                      <FaTimesCircle className="text-red-500" />
                    ) : data?.status === "DRAFT" ? (
                      <FaPauseCircle className="text-orange-500" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center justify-start flex-row gap-2">
                <Link
                  target="_blank"
                  to={`/agency-view-job/${data?.jobuid}`}
                  className=" hover:text-admin-blue font-medium text-[16px]"
                >
                  <span>
                    {data?.jobtitle ? `${data?.jobtitle} ,` : ""}{" "}
                    {data?.companyname}
                  </span>
                </Link>
              </div>

              <div className="w-full flex items-end justify-between gap-2 mt-5">
                <div className="w-[80%] sm:flex hidden items-center justify-start gap-8">
                  <div className="flex items-center justify-center flex-col">
                    <div className="flex  items-center justify-center">
                      {" "}
                      <p className="text-[20px] font-medium">
                        {data?.open_count +
                          data?.submitted_count +
                          data?.pending_count +
                          data?.decline_count}
                      </p>{" "}
                      <IoIosArrowForward className="text-[24px]" />
                    </div>

                    <p className="text-[12px]">Recipients</p>
                  </div>
                  <div className="flex items-center justify-center flex-col">
                    <div className="flex  items-center justify-center">
                      {" "}
                      <p className="text-[20px] font-medium">
                        {" "}
                        {data?.open_count +
                          data?.submitted_count +
                          data?.decline_count}
                      </p>{" "}
                      <IoIosArrowForward className="text-[24px]" />
                    </div>

                    <p className="text-[12px]">Views</p>
                  </div>
                  <div className="flex items-center justify-center flex-col">
                    <div className="flex  items-center justify-center">
                      {" "}
                      <p className="text-[20px] font-medium">
                        {data?.submitted_count}
                      </p>{" "}
                      <IoIosArrowForward className="text-[24px]" />
                    </div>

                    <p className="text-[12px]">Responses</p>
                  </div>
                  {/* <div className="flex items-center justify-center flex-col">
                       <div className="flex gap-2 items-center justify-center">
                         {" "}
                         <p className="text-[20px] font-medium">
                           {data?.suitable_count}
                         </p>{" "}
                       </div>
           
                       <p
                         className={` ${
                           data?.suitable_count > 0 ? "font-semibold" : ""
                         } text-[16px]`}
                       >
                         Suitable
                       </p>
                     </div> */}
                  <div className="flex items-center justify-center flex-col">
                    <div className="flex  items-center justify-center">
                      {" "}
                      <p className="text-[20px] font-medium">
                        {data?.decline_count}
                      </p>{" "}
                      <IoIosArrowForward className="text-[24px]" />
                    </div>

                    <p className="text-[12px]">Declined</p>
                  </div>
                  <div className="flex items-center justify-center flex-col">
                    <div className="flex  items-center justify-center">
                      {" "}
                      <p className="text-[20px] font-medium">
                        {data?.pending_count}
                      </p>{" "}
                      <IoIosArrowForward className="text-[24px]" />
                    </div>

                    <p className="text-[12px]">Pending</p>
                  </div>
                </div>
                <div className="text-[14px] w-[20%] ">
                  Initiated:<b> {moment(data.created_at).fromNow()}</b>
                  {data?.isstatusupdate > 0 ? (
                    <Link
                      to={`/agency-campaigns/${data?.campaignid}`}
                      target="_blank"
                      className="w-6 h-6 bg-red z-1 cursor-pointer rounded-full text-white inline-flex items-center justify-center relative left-3"
                    >
                      {data?.isstatusupdate > 9 ? "9+" : data?.isstatusupdate}
                      <Tooltip
                        content={`${data?.isstatusupdate} Status updates`}
                      />
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
