import { useMutation, useQueryClient } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";

const getAttachment = (postData) => {
  const URL = endpoint.campaign.downloadattachment;
  return Http().post(URL, postData);
};

export const useFetchAttachment = () => {
  let queryclient = useQueryClient();
  
  return useMutation({
    mutationFn: getAttachment,
    onSuccess: (data, variables, context) => {
      queryclient.invalidateQueries(['Campaigns'])
    },
    onError: (error, variables, context) => {
      queryclient.invalidateQueries(['Campaigns'])
    },
    onSettled: (data, error, variables, context) => {
      queryclient.invalidateQueries(['Campaigns'])
    },
  });
};
