import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Token from "../../../Helpers/Token";
import { useChangePassword } from "../../../hooks/apihooks/useProfile";
import UserLayout from "../../../Layouts/Users/UserLayout";

export default function ChangePassword() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutateAsync } = useChangePassword();

  const onSubmit = (data) => {
    mutateAsync({ data, token: Token.getAccessToken() }).then((resp) => {
      if (resp?.status === false && resp?.error) {
        toast.error(resp?.error?.password[0]);
      }
      if (resp?.status === false && resp?.message) {
        toast.error(resp?.message);
      }

      if (resp?.status === true) {
        reset();
        toast.success(resp?.message);
      }
    });
  };
  return (
    <UserLayout>
      <div className="h-[100vh] w-full sm:w-[80%]pb-5 bg-green-50 py-3 flex items-center gap-3 justify-start flex-col relative ">
        <div className="bg-white w-[95%] mt-[30px] mb-[30px] rounded-[10px] border border-[#e0e0e0]">
          <div className="p-[30px] max-sm:p-[15px]">
            <h2 className="text-[20px] text-center">Change Password</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex justify-between flex-wrap">
                <div className="w-full mb-3">
                  <label>Old Password</label>
                  <input
                    type="password"
                    name="oldpassword"
                    {...register("oldpassword", { required: true })}
                    className="border rounded border-[#e0e0e0] w-full px-3 py-2"
                    placeholder="Old Password"
                  />
                  {errors?.oldpassword ? (
                    <p>{errors?.message?.oldpassword}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="w-full mb-3">
                  <label>New Password</label>
                  <input
                    type="password"
                    name="password"
                    {...register("password", { required: true })}
                    className="border rounded border-[#e0e0e0] w-full px-3 py-2"
                    placeholder="New Password"
                  />
                  {errors?.password ? <p>{errors?.message?.password}</p> : ""}
                </div>
                <div className="w-full mb-3">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    name="password_confirmation"
                    {...register("password_confirmation", { required: true })}
                    className="border rounded border-[#e0e0e0] w-full px-3 py-2"
                    placeholder="Confirm Password"
                  />
                  {errors?.password_confirmation ? (
                    <p>{errors?.message?.password_confirmation}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="w-[100%] text-center">
                  <button
                    type="submit"
                    className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
