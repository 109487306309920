import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const subscribe = (data) => {
    const URL = `${endpoint.subscribe.subscribe}`;
    return Http().post(URL, data);
  }


export const useSubscriber = () => {
    return useMutation({
        mutationFn:subscribe,
        onSuccess: (data, variables, context) => {
          // I will fire first
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}
