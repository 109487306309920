import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";


const sendmail = ({reqbody, token}) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.sendmail.sendmail}/${reqbody?.userid}`;
  const headers  = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
  };
  delete reqbody.userid;
  return Http().post(URL, reqbody, headers);
}

const userupdate = ({reqbody, token}) => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.user}/${reqbody?.userid}`;
    const headers  = {headers:{
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    }}
    delete reqbody.userid;
    return  Http().patch(URL, reqbody, headers);
  }


  


export const useUserUpdate = () => {
    return useMutation({
        mutationFn:userupdate,
        onSuccess: (data, variables, context) => {
          // I will fire first
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}

export const useSendMail = () => {
  return useMutation({
      mutationFn:sendmail,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}
