/**
 * Get value from storage for given key.
 * */
export function get(key) {
    const value = localStorage.getItem(key);
  
    if (!value) {
      return null;
    }
  
    return JSON.parse(value);
  }
  
  /**
   * Set key value pair in storage.
   *
  
   */
  export function set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  
  /**
   * Remove key value pair in storage.
   *
  
   */
  export function remove(key) {
    localStorage.removeItem(key);
  }
  
  /**
   * Clear storage.
   *
  
   */
  export function clear() {
    return localStorage.clear();
  }