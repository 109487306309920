import { useQuery } from "@tanstack/react-query"
import Token from "../../../Helpers/Token";
import Http from "../../../http";
import endpoint from "../../../Helpers/endpoint";
import { Spinner } from "../../Loader/Spinner";
import { MaskiD } from "../../../Helpers/Utils";
import { FaCircle, FaCopy } from "react-icons/fa";
import { useState } from "react";
import { IoCheckbox } from "react-icons/io5";

export default function Atsintegration(){
    const [indexcopy, setcompybuton] = useState();
    function getintegrationdetail(){
       let URL =  endpoint.knit.integrationlist;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const {data, isLoading} = useQuery({queryKey : ['getintegration', ], queryFn : getintegrationdetail, enabled : Boolean(Token.getAccessToken())});
    return (
        <>
        {isLoading ? <Spinner size="medium"/> : ""}
        <h3 className="my-5">Ats and HRIS</h3>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Integration ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            App ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Category
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Organization ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((row, index) => (
                        <tr key={`ats${index}`} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 flex font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <span>
                                    {indexcopy===index ? 
                                    <IoCheckbox className="text-[green] text-[20px]" />:  
                                    <FaCopy className="cursor-pointer text-[20px]" onClick={() =>  {navigator.clipboard.writeText(row?.integration_id); setcompybuton(index)}} />
                                    }
                                </span> &nbsp;
                                <MaskiD id={row?.integration_id} />
                            </th>
                            <td className="px-6 py-4">
                                {row?.appId}
                            </td>
                            <td className="px-6 py-4">
                                {row?.category_id}
                            </td>
                            <td className="px-6 py-4">
                                {row?.origin_orgid}
                            </td>
                            <td className="px-6 py-4">
                               {row?.status ? <span className="bg-[green] text-white rounded px-1 py-1" >Active</span> : <span className="bg-[red] text-white rounded px-1 py-1">Inactive</span>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    </>
    )
    
}