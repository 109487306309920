import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import PreviewPopup from "./PreviewPopup";
import { useParseJob } from "../../../../hooks/apihooks/useParseJob";
import { useForm } from "react-hook-form";
import Token from "../../../../Helpers/Token";
import { Spinner } from "../../../Loader/Spinner";
import { toast } from "react-toastify";

export function JobLink({campaign}){  
    const {register, handleSubmit} = useForm();
    const [open, setOpen] = useState(false);
    const {mutateAsync, isPending} = useParseJob();
    const [data, setParseData] = useState({});

    const onSubmitData = (data) => {
      mutateAsync({data, token: Token.getAccessToken()}).then((resp) => {
        if(resp?.status===true){
          setParseData(resp)
          setOpen(true);
        }else{
          toast.error(resp?.error);
        }
       
      });
    }

    return (
      <>
        {isPending ? <Spinner size="medium" /> : ""}
        <PreviewPopup open={open} setOpen={setOpen} getdata={data}/>
        <form className="w-full" onSubmit={handleSubmit(onSubmitData)}>
        <div className="mt-3 text-center sm:text-left w-full">
          {/* <div className="w-full flex items-start justify-center flex-col gap-2">
            <p className="text-[13px] font-medium">Select Type of Job Link</p>
            <select
              {...register('typdofLink', {required: true})}
              className="w-full text-[12px] border border-gray-300 rounded-md p-2"
            >
              <option value="">--Select Type of Link --</option>
              <option value={'TextFileLink'}>Txt URL</option>
              <option value={'GoogleDocx'}>Google Docx URL</option>
              <option value={'PdfLink'}>Pdf URL</option>
            </select>
          </div> */}
          <div className="w-full flex items-start justify-center flex-col gap-2">
            <p className="text-[13px] font-medium">Job URL</p>
            <input
              placeholder="E.g https://docs.google.com/document/d/1PeIE49e6k0-GsTCsB_paWxbzzWMrtit509yZwTzKzzQ/edit?tab=t.0"
              {...register('url', {required:true})}
              className="w-full text-[12px] border border-gray-300 rounded-md p-2"
            />
          </div>
        </div>
        <div className="py-3 sm:flex sm:flex-row-reverse">
            <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
            >
                Go Preview
            </button>
            </div>
        </form>
      </>
    )
}