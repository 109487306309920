import React, { useState, useEffect } from 'react';
import { useSubscriber } from '../../../hooks/apihooks/useSubscriber';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Spinner } from '../../Loader/Spinner';

const ComingSoon = () => {
    const {handleSubmit, register} = useForm();
    const {isPending, mutateAsync} = useSubscriber();
    // const launchDate = new Date("2024-12-31T00:00:00"); // Set your launch date here
    // const [timeLeft, setTimeLeft] = useState({});
    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         const now = new Date();
    //         const distance = launchDate - now;

    //         if (distance < 0) {
    //             clearInterval(timer);
    //             setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    //             return; // Exit if the date has passed
    //         }

    //         const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //         const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //         const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //         setTimeLeft({ days, hours, minutes, seconds });
    //     }, 1000);

    //     return () => clearInterval(timer);
    // }, [launchDate]);

    const subscribe = (data) => {
        console.log(data,"subscribe");
        mutateAsync(data).then((resp) => {
            if(resp?.status===true){
                toast.success(resp?.message);
            }else{
                toast.error(resp?.message)
            }
            
        })
    }


    return (
        <>
        <div className='commingsoon'>
            <div className="flex flex-wrap items-start text-[white] justify-center h-screen bg-[#00000069]">
                <div className='w-full py-10 text-center'><span className='text-[30px]'>Coffee Crew</span></div>
                <div className="w-full  text-center">
                    <h1 className="text-5xl font-bold">Coming Soon</h1>
                    <p className="mt-4 text-lg">Drop your email to be the first to know</p>
                    {/* <div className="flex justify-center mt-8">
                        <div className="mx-2">
                            <span className="text-4xl">{timeLeft.days !== undefined ? timeLeft.days : 0}</span>
                            <div>Days</div>
                        </div>
                        <div className="mx-2">
                            <span className="text-4xl">{timeLeft.hours !== undefined ? timeLeft.hours : 0}</span>
                            <div>Hours</div>
                        </div>
                        <div className="mx-2">
                            <span className="text-4xl">{timeLeft.minutes !== undefined ? timeLeft.minutes : 0}</span>
                            <div>Minutes</div>
                        </div>
                        <div className="mx-2">
                            <span className="text-4xl">{timeLeft.seconds !== undefined ? timeLeft.seconds : 0}</span>
                            <div>Seconds</div>
                        </div>
                    </div> */}
                    <div className="mt-8">
                        <form onSubmit={handleSubmit(subscribe)}>
                            <input 
                                type="email" 
                                {...register('email', {required:true})}
                                placeholder="Enter your email for updates" 
                                className="px-4 py-2 border placeholder-white bg-inherit w-[300px] rounded"
                            />
                            <button type="submit" disabled={isPending?true:false} class="text-white ml-2 bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-3 text-center me-2 mb-2">{isPending? "sending...":"Notify Me"}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default ComingSoon;
