import { useMutation, useQueryClient } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";

const updateCampaign = (postData) => {
  const URL = endpoint.campaign.update.replace('{campaignid}',postData.campaignuid);
  return Http().post(URL, postData);
};

export const useUpdateCampaign = () => {
  let queryclient = useQueryClient();
  return useMutation({
    mutationFn: updateCampaign,
    onSuccess: (data, variables, context) => {
      queryclient.invalidateQueries(['Campaign'])
      // I will fire first
    },
    onError: (error, variables, context) => {
      queryclient.invalidateQueries(['Campaign'])
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      queryclient.invalidateQueries(['Campaign'])
      // I will fire first
    },
  });
};
