import React, { useState } from "react";
import UserLayout from "../../../../Layouts/Users/UserLayout";
import { useQuery } from "@tanstack/react-query";
import endpoint from "../../../../Helpers/endpoint";
import Http from "../../../../http";
import Tooltip from "../../../../Helpers/Tooltip";
import { FiFilter } from "react-icons/fi";
import { MdOutlineEmail, MdShare } from "react-icons/md";
import { TbFileExport } from "react-icons/tb";
import JobCard from "./JobCard";
import Loader from "../../../Loader/Loader";
import { Link } from "react-router-dom";
import useDebounce from "../../../../Helpers/useDebounce";

export default function JobListing() {

  const [ query, setQuery ] = useState({
    keyword : ''
  });

  let debouncesearch = useDebounce(query.keyword,500)

  function getJobs(keyword) {
    let endpoints = `${endpoint.job.listing}?keyword=${keyword}`;
    return Http().get(endpoints);
  }

  const { data, isLoading } = useQuery({ queryKey: ["jobslisting", debouncesearch], queryFn: () => getJobs(debouncesearch) });

  const queryHandler = (key) => (value) => {
   setQuery((prevState) => ({
     ...prevState,
     [key] : value
   }))
  };

  return (
    <UserLayout>
      {({ open, setOpen }) => (
        <div className="w-full flex items-center justify-center bg-green-50 ">
          {isLoading ? <Loader /> : ""}
          <div className="w-[95%] gap-3 flex items-center justify-start flex-col min-h-[100vh] mt-10">
            <p className="bg-white w-full text-[18px] p-3 border border-gray-200">
              Jobs
            </p>
            <div className="w-full flex items-center justify-between p-3 bg-white border border-gray-200">
              <div className="flex items-center justify-center gap-2">
                <input
                  placeholder="Search"
                  value={query.keyword}
                  onChange={(event) => queryHandler('keyword')(event.target.value)}
                  className="min-w-[300px] px-3 text-[14px] h-8 border border-gray-200"
                />
              </div>
              <div className="flex items-center justify-center gap-2 ">
                <Tooltip content="Filters">
                  <div className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                    <FiFilter />
                  </div>
                </Tooltip>
                <Tooltip content="Share">
                  <div className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                    <MdShare />
                  </div>
                </Tooltip>

                <Tooltip content="Email">
                  <div className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                    <MdOutlineEmail />
                  </div>
                </Tooltip>

                <Tooltip content="Export">
                  <div className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                    <TbFileExport />
                  </div>
                </Tooltip>

                <Link
                  to="/jobs"
                  className="text-white bg-green-600 text-[14px] px-4 py-1 rounded-[5px]"
                >
                  + Create job
                </Link>
              </div>
            </div>

            <div className="w-full flex items-center justify-center flex-col gap-2">
              {data?.data?.map((data, index) => (
                <JobCard data={data} key={index} />
              ))}
            </div>
          </div>
        </div>
      )}
    </UserLayout>
  );
}
