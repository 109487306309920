import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";


const praseprofile = ({searchquery,totalnorecord,campaign=null, token}) => {
  const URL = `${endpoint.googleSearch.searchlink}`
  const headers  = {
      "Authorization": `Bearer ${token}` 
  }
  return Http().post(URL, {searchquery, totalnorecord,campaign}, headers);
}

export const useParseProfile = () => {
  return useMutation({
      mutationFn:praseprofile,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}





