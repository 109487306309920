import { useEffect, useRef } from "react";
import Token from "./Helpers/Token";
import { useAtsConnect } from "./hooks/apihooks/useAtsConnect";

function KnitButton({ data }) {

  const knitRef = useRef(null);
  const { mutateAsync } = useAtsConnect();

  // Backend call to generate & get the authSessionToken
  
  const newSessionFn = (e) => {
    e?.preventDefault();
    // Hit the backend to generate authSessionToken
    // let data = {
    //   originOrgId: "hiringplug", // Your end user's organization org id
    //   originOrgName: "Hiringplug", // Your end user's organization org name
    //   originUserEmail: get('userAuth')?.email, // Your end user's organization user email
    //   originUserName: "yogesh9889" // Your end user's organization user name
    // }
    const apiurl = process.env.REACT_APP_APIENDPOINT;
    fetch(apiurl + "/manage-user-service/v1/sessiontoken", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((r) => {
        // UI Auth component won't open with a empty session token
        knitRef?.current?.setAttribute("authsessiontoken", r.msg.token);
        // Set skipIntro attribute to disable the introduction screen
        // knitRef?.current?.setAttribute("skipIntro",'');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Upon finishing the integration flow
  const onFinishFn = (e) => {
    e.preventDefault();
    const respdata = {
      data: e["detail"]["integrationDetails"],
      token: Token.getAccessToken(),
    };
    mutateAsync(respdata).then((resp) => {
      console.log("Store response", resp);
      console.log("Integration Detail", e["detail"]["integrationDetails"]);
    });
  };

  // Upon deactivate of integration
  const onDeactivateFn = (e) => {
    e.preventDefault();
    console.log("On Deative Fn", e["detail"]["integrationDetails"]);
  };

  // Upon closing of the knit-auth component
  const onKnitCloseFn = (e) => {
    e.preventDefault();
    console.log("close Deative Fn", e["detail"]["knitClosed"]);
  };

  useEffect(() => {
    const KnitCurrentRef = knitRef.current;

    if (KnitCurrentRef) {
      KnitCurrentRef.addEventListener("onNewSession", newSessionFn);
      KnitCurrentRef.addEventListener("onFinish", onFinishFn);
      KnitCurrentRef.addEventListener("onDeactivate", onDeactivateFn);
      KnitCurrentRef.addEventListener("onKnitClose", onKnitCloseFn);

      newSessionFn();
    }

    return () => {
      if (KnitCurrentRef) {
        KnitCurrentRef.removeEventListener("onNewSession", newSessionFn);
        KnitCurrentRef.removeEventListener("onFinish", onFinishFn);
        KnitCurrentRef.removeEventListener("onDeactivate", onDeactivateFn);
        KnitCurrentRef.removeEventListener("onKnitClose", onKnitCloseFn);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <knit-auth ref={knitRef}>
      <button
        slot="trigger"
        className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
      >
        Connect ATS and HRIS
      </button>
    </knit-auth>
  );
}

export default KnitButton;
