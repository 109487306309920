import React from "react";

function CreateCampaignJob({ setOpen, open, data }) {
  const handleClose = () => {
    setOpen(false);
  };

  console.log(data , "data")

  

  return (
    <>
      {open && (
        <React.Fragment>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-gothic bg-pop-up">
            <div className="relative rounded-lg gap-3 my-6 mx-auto bg-white py-4 px-8 lg:w-7/12 flex items-center flex-col justify-center w-80 -top-28 ">

            <p className="font-semibold text-[24px]">Define your campaign</p>
            <div className="w-full flex items-start justify-center flex-col"><p className="text-[26px]">Campaign-9</p>
            <p>Edit name</p></div>
            <div className="w-full flex items-center flex-col justify-center  border border-gray-200">
               <div className="border-b w-full flex items-start justify-center p-3 flex-col border-gray-200 ">
               <p >Which <b>Job</b> are you hiring for?</p>
               </div>
               <div className="border-b w-full flex items-start justify-center p-3 flex-col border-gray-200 ">
               <p >Screening Questions</p>
               </div>
             
            </div>
              <div className="w-full flex items-center justify-center gap-2">
                <button
                  onClick={handleClose}
                  type="button"
                  className="px-4 py-1 bg-admin-table-gray text-gray font-medium rounded-sm"
                >
                  Cancel
                </button>
                <button
                type="button"
                  className="px-4 py-1 bg-green-600 text-white font-medium rounded-full"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
        </React.Fragment>
      )}
    </>
  );
}

export default CreateCampaignJob;
