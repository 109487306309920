// src/MyChart.js

import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the necessary components
ChartJS.register(RadialLinearScale, PointElement, LineElement, Tooltip, Legend);

const RadarChart = () => {
  const data = {
    labels: ['Running', 'Swimming', 'Eating', 'Cycling', 'Sleeping'],
    datasets: [
      {
        label: 'Activity Level',
        data: [20, 10, 4, 2, 10],
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      r: {
        min: 0,
        max: 20,
        ticks: {
          stepSize: 4,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div className='flex justify-center'>
      <div className='w-[400px]'>
      <Radar data={data} options={options} />
      </div>
    </div>
  );
};

export default RadarChart;
