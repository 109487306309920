
import {useState } from "react";
import { Link } from "react-router-dom";

export function Page(){
    const [search, setSearch] = useState("");
    // Sample data for subscription history
  const subscriptionData = [
    { id: 1, plan: 'Basic', startDate: '2023-01-01', endDate: '2023-12-31', status: 'Active' },
    { id: 2, plan: 'Pro', startDate: '2022-06-15', endDate: '2023-06-15', status: 'Expired' },
    { id: 3, plan: 'Premium', startDate: '2023-04-01', endDate: '2024-04-01', status: 'Active' },
    { id: 4, plan: 'Standard', startDate: '2023-03-15', endDate: '2023-09-15', status: 'Active' },
  ];
    return (
        <>
           <div className="h-[auto] w-full">
                <div className="px-5 py-5">
                    <div className="rounded font-semibold text-gray-800 mb-4 bg-white border w-full px-3 py-2 flex justify-between items-center"><h3>Manage your Subscription</h3> <div><input type="search" onChange={(e) => setSearch(e.target.value)} className="border py-2 text-[13px] px-2 rounded w-[250px]"  placeholder="Search Subscription"/></div></div>
                </div>
                <div className="min-h-screen px-5 w-full flex justify-between">
                    <div className="w-[70%]">
                    <div className="overflow-x-auto bg-white rounded-lg shadow-md">
                      <table className="min-w-full table-auto">
                        <thead className="bg-gray-100 border-b">
                          <tr>
                            <th className="px-4 py-2 text-left text-gray-600">Plan</th>
                            <th className="px-4 py-2 text-left text-gray-600">Start Date</th>
                            <th className="px-4 py-2 text-left text-gray-600">End Date</th>
                            <th className="px-4 py-2 text-left text-gray-600">Status</th>
                            <th className="px-4 py-2 text-left text-gray-600">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subscriptionData.map((subscription) => (
                            <tr key={subscription.id} className="border-b hover:bg-gray-50">
                              <td className="px-4 py-2 text-gray-800">{subscription.plan}</td>
                              <td className="px-4 py-2 text-gray-600">{subscription.startDate}</td>
                              <td className="px-4 py-2 text-gray-600">{subscription.endDate}</td>
                              <td className={`px-4 py-2 text-sm font-semibold ${subscription.status === 'Active' ? 'text-green-600' : 'text-red-600'}`}>
                                {subscription.status}
                              </td>
                              <td className="px-4 py-2">
                                <Link className="text-blue-500 hover:text-blue-700 mr-2" to={`/subscription-detail/${subscription.id}`}>View Details</Link>
                                {subscription.status === 'Active' && (
                                  <button className="text-red-500 hover:text-red-700">Cancel</button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    </div>
                    <div className="w-[28%]">
                        <div className="bg-white p-4 border rounded shadow">
                            <p>Yogesh, unklock you full potential with coffee crew premium</p>
                            <div className="text-center py-10">
                                <span className="text-[20px] font-bold">Coffee Crew</span>
                            </div>
                            <p>Unlimeted plan for all autonomus functionality </p>
                            <div className="text-center mt-3">
                                <button className="border border-green-600 rounded-full px-3 ">Try for free</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}