import { useContext, useState } from "react";
import DocumentUploadLoader from "../../../Loader/DocumentUploadLoader";
import ContextProvider from "../../../../Context/ContextProvider";
import { useParseProfile } from "../../../../hooks/apihooks/useParseProfile";
import Token from "../../../../Helpers/Token";
import { toast } from "react-toastify";
import { Spinner } from "../../../Loader/Spinner";

export function BooleanSearch({setOpen, campaign}){
    const [country, setCountry] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [sourceType, setSourceType] = useState('');
    const [locationKeywords, setLocationKeywords] = useState('');
    const [excludeKeywords, setExcludeKeywords] = useState('');
    const [totalnorecord, setTotalNoOfRecord] = useState(20);
    const {progress} = useContext(ContextProvider);
    const {isPending, mutateAsync} = useParseProfile();


    const handleSearch = (e) => {
        e.preventDefault();
        // const includedKeywords = locationKeywords.split(',').map(keyword => `"${keyword.trim()}"`).join(' AND ');
        // const excludedKeywords = excludeKeywords.split(',').map(keyword => `-"${keyword.trim()}"`).join(' - ');
        // Create the search query
        // "software developer" "Delhi" AND "PHP" AND "Laravel" AND "Codeigniter" -"J2EE" -intitle:"profiles" -inurl:"dir/ " site:in.linkedin.com/in/ OR site:in.linkedin.com/pub/
        // const query = `"${jobTitle}" ${includedKeywords} ${excludedKeywords} -intitle:"profiles" -inurl:"dir/ "site:in.linkedin.com/in/ OR site:in.linkedin.com/pub/`;
        // Encode the query for the URL
        // console.log(query);
        // const encodedQuery = encodeURIComponent(query);
        // Generate the Google search URL
        // setGoogleSearch({query : encodedQuery, totalrecord : totalnorecord });
        // Open the URL in a new tab
        const searchquery = {
          jobtitle : jobTitle,
          country : country,
          locationskills : locationKeywords,
          sourcetype : sourceType,
        }
        mutateAsync({searchquery, totalnorecord, campaign:{campaign}, token: Token.getAccessToken()}).then((resp) => {
          if(resp?.status===true){
            toast.success(resp?.message);
            setOpen(false);
          }
        })
    };


    return (
        <>
        {isPending ? <Spinner size="medium" /> : "" }
        <form onSubmit={handleSearch} className="w-full">
          <div className="mt-3 text-center sm:text-left w-full">
            <div className="w-full flex items-start justify-center flex-col gap-2">
              <p className="text-[13px] font-medium">Source Type</p>
              <select className="w-full text-[14px] border border-gray-300 rounded-md p-2" required  value={sourceType}
                  onChange={(e) => setSourceType(e.target.value)}>
                  <option>--Select Source--</option>
                  <option>Linkedin</option>
                  <option disabled>Naukari</option>
                  <option disabled>Monstar</option>
                  <option disabled>Shine</option>
                  <option disabled>Indeed</option>
              </select>
            </div>
            <div className="w-full flex items-start justify-center flex-col gap-2">
              <label>Country</label>
              <input
                placeholder="E.g India"
                required
                value={country}
              onChange={(e) => setCountry(e.target.value)}
                className="w-full text-[12px] border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="w-full flex items-start justify-center flex-col gap-2">
              <p className="text-[13px] font-medium">Job Title</p>
              <input
                placeholder="E.g. Php Developer"
                value={jobTitle}
                required
                  onChange={(e) => setJobTitle(e.target.value)}
                className="w-full text-[12px] border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="w-full flex items-start justify-center flex-col gap-2">
              <p className="text-[13px] font-medium">Location or keywords to include</p>
              <input
                placeholder="E.g. London OR Paris AND html"
                value={locationKeywords}
                  onChange={(e) => setLocationKeywords(e.target.value)}
                className="w-full text-[12px] border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="w-full flex items-start justify-center flex-col gap-2">
              <p className="text-[13px] font-medium">Keywords to exclude</p>
              <input
                placeholder="E.g. Assistant OR secretary"
                value={excludeKeywords}
                  onChange={(e) => setExcludeKeywords(e.target.value)}
                className="w-full text-[12px] border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="w-full flex items-start justify-center flex-col gap-2">
              <p className="text-[13px] font-medium">Total no of record</p>
              <input
                placeholder="Enter current employer"
                value={totalnorecord}
                onChange={(e) => setTotalNoOfRecord(e.target.value)}
                className="w-full text-[12px] border border-gray-300 rounded-md p-2"
              />
            </div>
            {/* <DocumentUploadLoader /> */}
            {/* {progress ? <DocumentUploadLoader /> : ''} */}
          </div>
          <div className="py-2 sm:flex sm:flex-row-reverse">
            <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
            >
                Generate
            </button>
          </div>
        </form>
        </>
    )
}