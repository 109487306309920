// src/MyChart.js

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const BarlineChart = ({Report, chartLabel}) => {
  const monthData = Report?.map(item => item.month);
  const counts = Report?.map(item => item.count);
  // Define your data
  const data = {
    labels: monthData,
    datasets: [
      {
        label: chartLabel,
        data: counts,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Define your options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div className='flex justify-center items-center'>
      <div className='w-full'>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarlineChart;
