import React, { useState } from "react";
import endpoint from "../../../../../Helpers/endpoint";
import Http from "../../../../../http";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../../../../Loader/Loader";
import CampaignChat from "./CampaignChat";
import { useUpdateChatid } from "../../../../../hooks/apihooks/Campaigns/useUpdateChatid";
import { toast } from "react-toastify";
import { MdOutlineCampaign } from "react-icons/md";
import useDebounce from "../../../../../Helpers/useDebounce";
import { RxExternalLink } from "react-icons/rx";
import DataTable from "react-data-table-component";
import { CiSearch } from "react-icons/ci";

export default function CampaignsListingLayout() {
  let { campaignuid } = useParams();
  const [chatid, setChat] = useState(null);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    status: "",
    search: "",
  });

  const debouncesearch = useDebounce(filters.search, 500);
  const { mutateAsync, isPending } = useUpdateChatid();
  // functions
  function getCampaign() {
    let endpoints = endpoint.campaign.getlinkedincampaign.replace(
      "{campaignuid}",
      campaignuid +
        `?status=${filters.status}&keyword=${debouncesearch}&page=${page}`
    );
    return Http().get(endpoints);
  }

  // queries
  const { data, isLoading } = useQuery({
    queryKey: ["LinkedinCampaign", filters.status, debouncesearch, page],
    queryFn: () => getCampaign(),
  });

  function syncInvitation() {
    getCampaign(true);
  }

  const getChat = (chatid, provider_id, campaignid) => {
    if (chatid) {
      setChat(chatid);
    } else {
      mutateAsync({ provider_id, campaignid: campaignid }).then((res) => {
        if (res?.data?.chatid) {
          setChat(res?.data?.chatid);
        } else {
          setChat(null);
          toast("Connection request is still pending");
        }
      });
    }
  };

  const CustomCheckbox = React.forwardRef((props, ref) => (
    <input
      type="checkbox"
      className="cursor-pointer"
      ref={ref}
      {...props}
      style={{ transform: "scale(1.2)", accentColor: "#f50157" }}
    />
  ));

  const columns = [
    {
      name: <p className="text-[15px]">Contact</p>,
      selector: "full_name",
      cell: (row) => (
        <div className=" flex items-center justify-start gap-3">
          <img
            src={row?.profile_pic}
            alt="profile_pic"
            className="w-[50px] h-[50px] rounded-full"
          />
          <p className="text-blue-500 cursor-pointer" to={row?.profile_link}>
            {row?.full_name}
          </p>
        </div>
      ),
    },
    {
      name: <p className="text-[15px]">Invited at</p>,
      selector: "headline",
      cell: (row) => (
        <p className="py-3 flex items-start text-gray-500 text-[12px] justify-start flex-col">
          <span>
            {moment(row?.created_at)
              .local("Asia/Kolkata")
              .format("MMM Do YYYY")}
          </span>
          <span>
            {moment(row?.created_at).local("Asia/Kolkata").format("HH:mm A")}
          </span>
        </p>
      ),
    },
    {
      name: <p className="text-[15px]">Occupation</p>,
      selector: "headline",
      cell: (row) => <p className="py-3 text-[14px]">{row?.headline}</p>,
    },

    {
      name: <p className="text-[15px]">Status</p>,
      selector: "status",
      cell: (row) => (
        <div className="text-[14px]">
          {row?.status === "pending" ? (
            <p className="flex items-center justify-center gap-2 text-red-600 font-medium bg-red-100 rounded-full px-[14px] py-1">
              Requested
            </p>
          ) : row?.status === "accepted" ? (
            <p className="flex items-center justify-center gap-2 text-orange-600 font-medium bg-orange-100 rounded-full px-[14px] py-1">
              Awaiting Reply
            </p>
          ) : row?.status === "replied" ? (
            <p className="flex items-center justify-center gap-2 text-green-600 font-medium bg-green-200 rounded-full px-[14px]  py-1">
              Responded
            </p>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: <p className="text-[15px]">Action</p>,
      selector: "chatid",
      cell: (row) => (
        <p
          onClick={() => getChat(row?.chatid, row?.urn, data?.data?.campaignid)}
          className="cursor-pointer"
        >
          Chat
        </p>
      ),
    },
  ];

  return (
    <div className="w-full min-h-[100vh] flex items-center justify-start py-10 flex-col gap-3">
      {isPending ? <Loader /> : ""}
      {isLoading ? <Loader /> : ""}
      <div className="w-[95%] flex bg-gradient-leads px-2 py-3 rounded-[8px] gap-2 items-start justify-center flex-col">
        <p className=" text-[18px] font-medium flex items-center justify-center gap-1"><MdOutlineCampaign size={26} />{data?.data?.campaigntitle}</p>
        <p className="text-[12px] text-gray-800"> AI-Driven LinkedIn Lead Search</p>
      </div>

      <div className="flex items-center justify-center flex-col gap-2 w-[95%]">
        <div className="flex items-center justify-center sm:flex-row flex-col gap-2 w-full border-y py-3 border-gray-200">
          <div className="w-6/12 flex items-center justify-center gap-2">
            <CiSearch size={22} />
            <input
              value={filters.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
              placeholder="Search by Name"
              className="w-full h-10 p-2 rounded-[4px] border-0 outline-none"
            />
          </div>

          <div className="w-6/12 flex items-center justify-end gap-2">
            <select
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value })
              }
              className="w-full sm:w-4/12 text-[14px] border h-8 px-2 rounded-[4px] border-gray-300"
            >
              <option value="">Select by status</option>
              <option value="replied">Responded</option>
              <option value="accepted">Awaiting Reply</option>
              <option value="pending">Requested</option>
            </select>
            <button
              className="whitespace-nowrap text-[14px] bg-green-600 p-4 py-1 text-white rounded-[6px]"
              type="button"
              onClick={() => syncInvitation()}
            >
              Sync Invitations
            </button>
          </div>
        </div>
        {chatid ? <CampaignChat setChat={setChat} chatid={chatid} /> : ""}
      </div>

      <div
        id="leads"
        className="w-[95%] border border-gray-200 rounded-[8px] rounded-b-none flex items-start flex-col justify-center gap-2"
      >
        <DataTable
          columns={columns}
          data={data?.data?.invitations?.data}
          progressPending={isLoading}
          onChangePage={(page) => setPage(page)}
          pagination
          filterAll={true}
          paginationServer
          //   onChangeRowsPerPage={onChangeRowsPerPage}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
          highlightOnHover
          selectableRowsHighlight
          paginationRowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          //   onSelectedRowsChange={onSelectedRowsChange}
          paginationTotalRows={data?.data?.invitations?.total}
          className="border-b"
        />
      </div>

      {/* {data?.data?.invitations?.length < 1 ? (
        <div className="w-[95%]  h-[80vh] bg-white rounded-[10px] flex  p-2 items-center justify-center flex-row">
          <div className="border border-gray-300 w-11/12 sm:w-5/12 flex items-center justify-center gap-2 p-4 rounded-[6px]">
            <MdOutlineCampaign size={100} />
            <div className="w-full">
              <p className="flex text-[18px] font-semibold items-center justify-center">
                AI Candidate Generation in Progress
              </p>
              <p className="text-center text-[14px]">
                Your AI-driven candidate generation is in progress! We're
                sourcing and analyzing top candidates to match your
                requirements—stay tuned for updates.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[95%] flex relative items-center justify-start flex-col rounded-[6px] overflow-y-scroll scrollbar-hide h-[80vh] bg-white">
         
          <div className="w-full relative border p-3 border-gray-100  flex items-center justify-start gap-4">
            <div className="w-[30%] text-[16px] font-semibold">CONTACT</div>
            <div className="w-[20%] text-[16px] font-semibold">INVITED AT</div>
            <div className="w-[30%] text-[16px] font-semibold">OCCUPATION</div>
            <div className="w-[10%] text-[16px] font-semibold">STATUS</div>
            <div className="w-[10%] text-[16px] font-semibold flex items-center justify-center">ACTION</div>
          </div>
          {data?.data?.invitations?.map((val, index, array) => (
            <div
              key={index}
              className={`w-full flex items-center justify-center p-2  border border-gray-100 gap-2 ${
                index === array.length - 1 ? "border-b" : "border-b-0"
              }`}
            >
              <div className="w-[30%] flex items-center justify-start gap-3">
                <img
                  src={val?.profile_pic}
                  alt="profile_pic"
                  className="w-[50px] h-[50px] rounded-full"
                />
                <p
                  className="text-blue-500 cursor-pointer"
                  to={val?.profile_link}
                >
                  {val?.full_name}
                </p>
              </div>
              <div className="w-[20%]">
                <p className="text-[14px]">
                  {moment(val?.created_at).format("DD-MM-YYYY hh:mm:ss A")}
                </p>
              </div>
              <div className="w-[30%]">
                <p className="text-[14px]">{val?.headline}</p>
              </div>
              <div className="w-[10%]">
                <p className="text-[14px] border border-gray-300 p-1 rounded-[4px]">
                  {val?.status === "pending" ? (
                    <span className="inline-block">
                      <i className="bg-red-500 inline-block w-2 h-2 rounded-full mr-1"></i>
                      Requested
                    </span>
                  ) : val?.status === "replied" ? (
                    <span className="inline-block">
                      <i className="bg-green-500 inline-block w-2 h-2 rounded-full mr-1"></i>
                      Replied
                    </span>
                  ) : val?.status === "accepted" ? (
                    <span className="inline-block">
                      <i className="bg-orange-500 inline-block w-2 h-2 rounded-full mr-1"></i>
                      Awaiting Reply
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="w-[10%] flex items-center justify-center">
                <p
                  onClick={() =>
                    getChat(val?.chatid, val?.urn, data?.data?.campaignid)
                  }
                  className="text-[14px] cursor-pointer"
                >
                   Chat
                </p>
              </div>
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
}
