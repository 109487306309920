function extractJSON(text) {
    try {
       
        const jsonRegex = /{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*}/g;
        const matches = text.match(jsonRegex);
        const jsonObjects = matches.map(match => JSON.parse(match));
        return jsonObjects;
    } catch (e) {
        console.error("Error extracting JSON:", e);
        return [];
    }
}

export default extractJSON;