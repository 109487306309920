import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserLayout from "../../../Layouts/Users/UserLayout";
import SourcePopup from "./SourcePopup";
import { useQuery } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import Token from "../../../Helpers/Token";
import { Spinner } from "../../Loader/Spinner";
import CandCampCard from "./CandCampCard";
import Tooltip from "../../../Helpers/Tooltip";
import { MdKeyboardBackspace } from "react-icons/md";
import { IoSearch } from "react-icons/io5";

export const CandidatSourcing = () => {
    const [open, setOpen] = useState();
   const getcampaigndata = () => {
    let URL = `${endpoint.candidate.campaignSourceCand}`;
    return Http().get(URL, {
      'Authorization' : `Bearer ${Token.getAccessToken()}`,
    })
    }
  const {data, isLoading} = useQuery({queryKey: ['get-candsource-campaign'], queryFn: getcampaigndata});
  return (
    <UserLayout>
      {isLoading ? <Spinner size="medium" /> : ""}
      <div
        className={`w-[100%] px-10 pt-10 bg-green-50 relative flex flex-wrap justify-center`}
      >
        <SourcePopup
            open={open}
            setOpen={setOpen}
            campaign={'yes'}
        />
        <div className="pb-10 w-full">
          <div className="bg-white border py-3 mb-3 w-[100%] flex justify-between items-center">
            <Link className="pl-2 pb-0" to={"/candidates"}><Tooltip message={'Back'}><MdKeyboardBackspace size={23} /></Tooltip></Link>
            <div className="flex">
              <Tooltip message={'Search Candidates'}>
                <button
                  className="text-white flex items-center bg-green-600 text-[14px] px-4 py-1 rounded-[5px] mr-3"
                  onClick={() => setOpen(true)}
                >
                 <IoSearch className="mr-1" /> Candidates
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between w-full">
          {data?.data?.map((value, index) => (
            <CandCampCard  value={value} key={index}/>
          ))}
        </div>
      </div>
    </UserLayout>
  );
};
