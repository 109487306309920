import React from "react";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import LineChart from "../../Chart/LineChart";
import DoughnutChart from "../../Chart/Doughnut";
import RadarChart from "../../Chart/RadarChart";
import BarlineChart from "../../Chart/BarlineChart";

export default function AdminReport(){
    return (
        <AdminLayouts>
            <div className="px-10 py-5 w-full bg-green-50">
                <div className="flex flex-wrap  justify-between">
                    <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                        <LineChart />
                    </div>
                    <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                        <DoughnutChart />
                    </div>
                    <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
                        <RadarChart />
                    </div>
                    <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
                       <BarlineChart />
                    </div>
                </div>
                <div className="flex flex-wrap  justify-between">
                    <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                        <LineChart  />
                    </div>
                    <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                        <DoughnutChart />
                    </div>
                    <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
                        <RadarChart />
                    </div>
                    <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
                        <BarlineChart />
                    </div>
                </div>
            </div>
        </AdminLayouts>
    );
}