import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { getHeader } from "../../Helpers/header";

const Train_Candidate = async (postData) => {
  const newEndpoints = process.env.REACT_APP_CV_REALTIME_TRAINING;
  let headers = getHeader("genai");
  const res = await axios.post(newEndpoints, postData, { headers });
  return res.data;
};

export function useTrainCV() {
  return useMutation({
    mutationFn:Train_Candidate,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
});
}
