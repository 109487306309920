import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const credentailsStore = ({data, token}) => {
  const URL = `${endpoint.credentials.apis}`;
  const headers  = {
    "Content-Type": "multipart/form-data", 
    "Authorization": `Bearer ${token}`
  }
  return Http().post(URL, data, headers)
}

const credentailsUpdateStore = ({data, apiuid, token}) => {
  const URL = `${endpoint.credentials.apis}/${apiuid}`;
  const headers  = {
    "Content-Type": "multipart/form-data", 
    "Authorization": `Bearer ${token}`
  }
  return Http().patch(URL, data, headers);
}

export const useApisCredentils = () => {
  return useMutation({
      mutationFn:credentailsStore,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

export const useApisUpdateCredentils = () => {
  return useMutation({
      mutationFn:credentailsUpdateStore,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}






