import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import endpoint from "../../../Helpers/endpoint";
import Token from "../../../Helpers/Token";
import Http from "../../../http";
import { Spinner } from "../../Loader/Spinner";
// import { useGlobalContext } from "../../../Context/ContextWrapper";
import { Link } from "react-router-dom";
import {
  useAtsConnect,
  useSetOrg,
} from "../../../hooks/apihooks/useAtsConnect";
import KnitButton from "../../../KnitMain";
import UserLayout from "../../../Layouts/Users/UserLayout";
import moment from "moment";

const AtsConnection = ({ open, setOpen }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { register: orgregister, handleSubmit: orghandleSubmit } = useForm();

  const { isPending, mutateAsync } = useAtsConnect();
  const { mutateAsync: setOrgdata, isPending: orgLoadin } = useSetOrg();

  const [atsCompany, setAtsCompany] = useState({});

  const [atspopup, setatsPopup] = useState(false);
  const [orgpopup, setorgpopup] = useState(false);
  const [lenghtofPage, setlenghtPage] = useState(12);

  const getorgdetail = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.knit.getorgdetail}`;
    return Http().get(URL, {
      Authorization: `Bearer ${Token.getAccessToken()}`,
    });
  };
  const { data: orgdata, refetch } = useQuery({
    queryKey: ["getorgdetail"],
    queryFn: getorgdetail,
    enabled: Boolean(Token.getAccessToken()),
  });

  const getIntegrationdata = () => {
    let originOrgId = orgdata?.data?.originOrgId;
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.webhook.ats_integration}/${originOrgId}`;
    return Http().get(URL, {
      Authorization: `Bearer ${Token.getAccessToken()}`,
    });
  };

  const { data: dataats, isLoading: integrateloadind } = useQuery({
    queryKey: ["integrationdata"],
    queryFn: getIntegrationdata,
    enabled: Boolean(orgdata?.data?.originOrgId),
  });

  

  const onsubmit = (data) => {
    console.log("Submit Org", data);
    setOrgdata({ data, token: Token.getAccessToken() }).then((resp) => {
      if (resp?.status === true) {
        toast.success(resp?.message);
        setorgpopup(false);
        refetch();
      }
    });
  };

  const formSubmit = (data) => {
    let newdata = { ...data, ats_name: atsCompany?.name };
    mutateAsync({ newdata, token: Token.getAccessToken() }).then((resp) => {
      if (resp?.status === true) {
        toast.success(resp?.message);
        setatsPopup(false);
        reset();
      }
    });
  };

  return (
    <UserLayout>
      {isPending || integrateloadind || orgLoadin ? (
        <Spinner size={"medium"} />
      ) : (
        ""
      )}

      <div
        className={`relative z-10 ${atspopup ? `` : `hidden tansition-all`}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <span onClick={() => setatsPopup(false)} className=" text-center absolute top-[-10px] w-[30px] h-[30px] right-[-10px] bg-[red] leading-[30px] rounded-[50%] text-[white]"> X </span>
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-[20px]">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="w-full mb-3 flex justify-center">
                      <img
                        alt="ats"
                        src={atsCompany?.shortLogo}
                        width={50}
                        className="text-center"
                      />
                    </div>
                    <p
                      className="text-base font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      <strong>Account : </strong> {atsCompany?.id?.toUpperCase()}
                    </p>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 mb-3">
                        Your <strong>{atsCompany?.id?.toUpperCase()}</strong> account is currently {atsCompany?.isActive ? 'Active Start integrating with 20+ HR tools through our unified HR API to enhance your recruitment process' : 'Inactive Please take the necessary steps to reactivate and leverage its ATS capabilities'}. 
                      </p>
                    </div>
                    <div>
                      <p><strong>Account Status : </strong> {atsCompany?.isActive ? 'Active' : 'Inactive'}</p>
                      <p><strong>Created On : </strong> {moment(atsCompany?.createdAt).format("DD-MM-YYYY")}</p>
                      <p><strong>Category Type: </strong> {atsCompany?.category}</p>
                      <p><strong>Total Sync: </strong> 230 times</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ORganization Set */}
      <div
        className={`relative z-10 ${orgpopup ? `` : `hidden tansition-all`}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <form method="post" onSubmit={orghandleSubmit(onsubmit)}>
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3
                        className="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Set your organization name {atsCompany?.name}
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          By setting your organization name, you’ll personalize
                          your integration experience and ensure all HR data and
                          processes are accurately attributed to your
                          organization. If you encounter any issues, our support
                          team is here to assist you.
                        </p>
                      </div>
                      <div className="w-full py-4">
                        <label>Organization Name</label>
                        <input
                          type="text"
                          {...orgregister("org_name", { required: true })}
                          name="org_name"
                          className="w-full border-[#e0e0e0] border px-2 py-2 text-[13px] rounded"
                          placeholder="Eg- CoffeeCrew"
                        />
                        {errors.org_name ? (
                          <p className="text-[red]">
                            Organization name is required
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setorgpopup(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal End */}
      <div
        className={`h-[100vh] w-full bg-green-50 flex items-center flex-col`}
      >
        <div className="w-11/12 max-sm:w-11/12 flex max-sm:mt-[50px] justify-center">
          <div className="bg-white w-[100%] mt-[30px] mb-[30px] p-5 rounded-[10px] border border-[#e0e0e0]">
            <div className="flex justify-between mb-3">
              <h1 className="text-[25px]">ATS And HRIS Connector</h1>
              {orgdata?.data?.originOrgName ? (
                <KnitButton data={orgdata?.data} />
              ) : (
                <Link
                  onClick={() => setorgpopup(true)}
                  className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                >
                  Set Organization
                </Link>
              )}
            </div>
            <div className="flex flex-inline flex-wrap justify-start">
              {!integrateloadind &&
                dataats?.data?.apps?.map((item, index) => {
                  if (index < lenghtofPage) {
                    return (
                      <div
                        key={index}
                        className="w-[30%] mr-[30px] cursor-pointer hover:transition-all hover:delay-120  hover:scale-110 relative text-black-300 hover:text-white border border-green-500 hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                      >
                        {/* <span>{dataoffilter && dataoffilter[0]?.terms? <FaCheckCircle className="absolute top-0 right-0 mr-3 mt-2 text-[green]" /> :""}</span> */}
                        <div
                          className="text-center flex justify-between items-center"
                          onClick={() => {
                            setAtsCompany(item);
                            setatsPopup(true);
                          }}
                        >
                          <span className="absolute top-0 right-0 py-2 px-2">
                            {item?.isActive ? (
                              <FaCheckCircle className="text-[green]" />
                            ) : (
                              <FaTimesCircle className="text-[red]" />
                            )}
                          </span>
                          <img
                            src={item?.shortLogo}
                            width={"80px"}
                            alt="logo"
                          />
                          <div className="text-right">
                            <p className="border px-2 py-[0.5px]  rounded bg-[#b8b9b9] text-white inline-block">
                              {item?.category}
                            </p>
                            <p className="">
                              {item.id.charAt(0).toUpperCase() +
                                item?.id.slice(1)}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }else{
                    return null;
                  }
                })}
            </div>
            {dataats?.data?.length > lenghtofPage ? (
              <div className="text-center py-10">
                <button onClick={() => setlenghtPage(lenghtofPage + 12)}>
                  Load More
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default AtsConnection;
