
import CryptoJS from "crypto-js";
// Example usage
const encryptionKey = 'my32digitkey12345678901234567890'; // 32-byte encryption key
const iv = 'my16digitIvKey12'; // 16-byte IV

// Encryption function
export function encryptData(data) {
    // Convert key and IV to Utf8
    const keyUtf8 = CryptoJS.enc.Utf8.parse(encryptionKey);
    const ivUtf8 = CryptoJS.enc.Utf8.parse(iv);
  
    // Encrypt the data using AES-256-CBC
    const encrypted = CryptoJS.AES.encrypt(data, keyUtf8, {
        iv: ivUtf8,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
  
    // Return the encrypted data as base64 string
    return encrypted.toString();
}
  
  // Decryption function
export function decryptData(encryptedData) {
    if(!encryptedData){
        return "";
    }
    // console.log(encryptedData, "encryptedData");
    const keyUtf8 = CryptoJS.enc.Utf8.parse(encryptionKey);
    const ivUtf8 = CryptoJS.enc.Utf8.parse(iv);
  
    // Decrypt the data
    const decrypted = CryptoJS.AES.decrypt(encryptedData, keyUtf8, {
        iv: ivUtf8,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
  
    // Convert decrypted data back to a string
    return decrypted.toString(CryptoJS.enc.Utf8);
  }