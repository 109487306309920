import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { CiSearch } from "react-icons/ci";
import Select from "react-select";
import { ExperienceLevel } from "../config";
import Creatable from "react-select/creatable";
import { WiStars } from "react-icons/wi";
import { useQuery } from "@tanstack/react-query";
import Http from "../../../../http";
import Token from "../../../../Helpers/Token";
import endpoint from "../../../../Helpers/endpoint";
import { useParams } from "react-router-dom";
import Loader from "../../../Loader/Loader";
export default function JobDetail() {
  const { jobuid } = useParams();
  
  const { register, reset, watch, control, getValues, handleSubmit } =
    useForm();

  const getjobdetail = () => {
    const URL = `${endpoint.job.jobdetail}/${jobuid}`;
    return Http().get(URL, {
      Authorization: `Bearer ${Token.getAccessToken()}`,
    });
  };

  const { data, isLoading } = useQuery({
    queryKey: ["jobdeail"],
    queryFn: getjobdetail,
  });

  const onSubmit = (data) => {};
  
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 40,
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "0px",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    placeholder: (base) => ({
      ...base,
      color: "gray",
    }),
    valueContainer: (base) => ({
      ...base,
      fontSize: "15px", // Set text size inside the select
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "15px", // Set text size for the selected option
    }),
    input: (base) => ({
      ...base,
      fontSize: "13px", // Set text size for the input field
    }),
  };

  useEffect(() => {
    if (data?.data) {
      reset(data?.data);
    }
  }, [data?.data, reset]);
  return (
    <div className="w-full  px-4 py-4  flex items-center min-h-[100vh] justify-start bg-green-50 flex-col gap-2 border shadow-sm">
      <div className="bg-white w-full px-4 py-4 rounded">
        <h3>Job Detail</h3>
        {isLoading ? <Loader /> : ""}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" w-full  pb-5 flex items-center gap-3 justify-start pt-10 flex-col"
        >
          {/* <div className="w-[95%] flex items-center justify-start">
          <p className="text-[16px] font-medium">
            Automate Job Creation with AI-Plug Solutions
          </p>
        </div> */}
          <div className="w-[95%] flex items-center justify-center gap-4 mt-5">
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <CiSearch />
              <input
                placeholder="Job Title"
                name="jobtitle"
                {...register("jobtitle")}
                className="w-full outline-none bg-transparent h-[38px] p-2 text-[15px] placeholder-gray-500"
              />
            </label>
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="location"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Select location"
                    Searchable
                    options={[{ label: "Noida", value: "Noida" }]}
                  />
                )}
              />
            </label>
          </div>
          <div className="w-[95%] flex items-center justify-center gap-4 mt-5">
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="experiencefrom"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Experience from"
                    Searchable
                    options={ExperienceLevel}
                  />
                )}
              />
            </label>
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="experienceto"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Experience to"
                    Searchable
                    options={ExperienceLevel}
                    isOptionDisabled={(option) =>
                      Number(option.value) <=
                      Number(getValues("experiencefrom.value"))
                        ? true
                        : false
                    }
                  />
                )}
              />
            </label>
          </div>
          <div className="w-[90%] px-[20px] flex items-center justify-center gap-4 mt-5">
            <label className="w-full flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="skills"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Creatable
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Skills"
                    Searchable
                    isMulti
                  />
                )}
              />
            </label>
          </div>
          <div className="w-[87%] ">
            <p className="w-full my-5 border-b  font-semibold border-gray-300 ">
              Job Description
            </p>
            <div
              className="text-[15px]"
              dangerouslySetInnerHTML={{ __html: data?.data?.description }}
            />
          </div>
          <div className="w-[90%] flex items-center justify-end px-4">
            {watch("jobtitle") &&
            watch("skills") &&
            watch("experienceto") &&
            watch("experiencefrom") ? (
              <div className="flex items-center justify-center gap-2">
                <button
                  type="submit"
                  className="flex items-center justify-center text-[14px] aibutton px-4 py-1 rounded-full text-white hover:scale-90 transition-all"
                >
                  <WiStars size={20} />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
