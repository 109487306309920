import { FaDownload, FaStar, FaTimes, FaRegFilePdf } from "react-icons/fa";
import { MdArrowForwardIos,MdArrowBackIosNew, MdOutlineContactPhone } from "react-icons/md";
import { MdOutlineCampaign } from "react-icons/md";
import Tooltip from "../../../Helpers/Tooltip";
import { RiUserStarLine } from "react-icons/ri";
export default function Detailpopup({ openDetail, setOpenDetail, data, nextSlide, sliderID, prevSlide,countslide }) {
  const stars = Array(data?.overall_rating).fill(<FaStar className="text-[orange]" />);
  return (
    <div
      className={`fixed top-0 right-0 w-[40%] shadow-lg h-[100vh] overflow-auto bg-[white] z-10 transform  transition-transform duration-500 ease-in-out  ${openDetail ? ' translate-x-0 ' : 'translate-x-full'}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center relative">
          <div className="relative transform text-left shadow-xl transition-all sm:w-full ">
            <div className="w-full break-words">
              <div className="sm:flex flex-col rounded sm:items-start w-full">
                <div className="commingsoon1 w-full sticky top-0">
                  <div className=" bg-[#00000063] text-white py-4 px-4">
                    <button type="button" className="absolute z-50 text-white left-[10px] top-[8px] rounded-full h-[30px] w-[30px]" onClick={() => setOpenDetail(false)}><FaTimes /></button>
                    <div className="flex w-full flex-wrap">
                      <div className="w-[25%]">
                        {data?.profile_pic ? 
                          <img className="border-2 mt-5 rounded-[50%] w-[100px] h-[100px]" src={data?.profile_pic} alt="" /> :
                          <div className="border-2  mt-5 rounded-[50%] w-[100px] h-[100px] text-[30px] bg-green-600 font-bold text-center leading-[100px]">{data?.first_name?.substr(0,1)}</div> }
                      </div>
                      <div className="flex flex-wrap w-[75%]">
                          <div className="w-[75%]">
                            {data?.overall_rating? <div className="flex items-center  mt-6 mb-1">
                              {stars}
                            </div> : ""}
                            <div className="w-full pb-1 font-bold order-first">{data?.first_name} {data?.last_name}</div>
                              {
                                data?.contactdata?.contact?.map((row, index) => {
                                  return <div className="w-full pb-1 font-bold">{row?.type.toUpperCase()} : {row?.value}</div>
                                })
                              }
                              <div>
                                {
                                  data?.worklist_id?.map((row, index) => {
                                    return <span className="bg-gray-300 text-[10px] mr-1 px-[3px] py-[2px] rounded text-[black]">{row?.label} x</span>
                                  })
                                }
                              </div>
                          </div> 
                            

                        <div className="w-[25%] flex justify-end">
                          <MdArrowBackIosNew size={10} onClick={() => {prevSlide()}} className="mr-2 hover:bg-green-600 cursor-pointer w-[28px] h-[28px] p-1 border rounded" />
                          <MdArrowForwardIos size={15} onClick={() => {nextSlide()}} className="cursor-pointer hover:bg-green-600 w-[28px] h-[28px] p-1 border rounded"  />
                        </div>
                        <div className="flex w-full items-end">
                          <div className="w-[50%]"></div>
                          <div className="w-[50%] flex justify-end">
                            <Tooltip message={'Fetch Rating'}>
                              <button  className="px-1 py-1 hover:bg-green-600  border mr-2 rounded">
                                <RiUserStarLine size={18} />
                              </button>
                            </Tooltip>
                            <Tooltip message={'Fetch Contact'}>
                              <button  className="px-1 py-1 hover:bg-green-600 mr-2  border rounded">
                                <MdOutlineContactPhone size={18} />
                              </button>
                            </Tooltip>
                            {/* <Tooltip message={'Add to campaign Contact'}>
                              <button  className="px-1 py-1 hover:bg-green-600 mr-2  border rounded">
                                <MdOutlineCampaign size={18} />
                              </button>
                            </Tooltip> */}
                            <Tooltip message={'Download pdf profile file'}>
                              <button  className="px-1 py-1 hover:bg-green-600   border rounded">
                                <FaRegFilePdf size={18} />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <div className="bg-green-200 w-full py-2 px-2 ">Rating</div>
                <div className="w-full px-4 py-3">
                  {data?.overall_rating? <>
                  <div>
                      <p><strong>About :</strong>Satya Keerthi Singamsetty, assessing their suitability for a "Project Manager – Professional Services" role. The evaluation highlights that while Satya has extensive experience in data engineering and related technologies, they lack specific project management skills and experience, which are critical for this role.</p>
                      <p><strong>Project Management:</strong> Rated 1 out of 5 stars – No explicit experience or skills in project management are mentioned.</p>
                      <p><strong>ERP Implementation:</strong> Rated 1 out of 5 stars – No mention of experience in ERP implementation.</p>
                      <p><strong>MS Project:</strong> Rated 1 out of 5 stars – No mention of experience using MS Project.</p>
                      <p><strong>Visio:</strong> Rated 1 out of 5 stars – No mention of experience using Visio.</p>
                      <p><strong>Client Interaction:</strong> Rated 2 out of 5 stars – There is likely some experience in client interaction within a professional services context, but it is not explicitly detailed.</p>
                      <p><strong>Communication Skills:</strong> Rated 4 out of 5 stars – Given Satya’s technical background, they likely possess good communication skills, though this is not explicitly confirmed.</p>
                  </div>
                  </>:""}
                </div>
                <div className="bg-green-200 w-full py-2 px-2 ">Title</div>
                <div className="w-full px-4 py-3">{data?.headline}</div>
                <div className="bg-green-200 w-full py-2 px-2 ">Skills</div>
                <div className="w-full px-4 py-2">{data?.skills}</div>
                <div className="bg-green-200 w-full py-2 px-2 ">Education</div>
                <div className="w-full px-4 py-2">
                  {
                    data?.education?.map((row, index) => {
                      return <div>
                          <div className="py-2 flex">
                            <div className="w-[60px]"><img src={row?.logo ? row?.logo : '/images/school.png' } className="w-[50px]" alt="logo"/></div>
                            <div className="">
                              <p>{row?.title}</p>
                              <p>{row?.subtitle}</p>
                              <p>{row?.caption}</p>
                            </div>
                          </div>
                        </div>
                    })
                  }
                </div>
                <div className="bg-green-200 w-full py-2 px-2 ">
                  Experience
                </div>
                <div className="w-full px-4 py-2">
                  {
                    data?.experience?.map((row, index) => {
                      return <div>
                        <div className="py-2 flex">
                          <div className="w-[60px]"><img src={row?.logo ? row?.logo : '/images/school.png' }  alt="logo" className="w-[50px]"/></div>
                          <div className="">
                            <p>{row?.title}</p>
                            <p>{row?.subtitle}</p>
                            <p>{row?.caption}</p>
                          </div>
                        </div>
                        
                        </div>
                    })
                  }
                </div>
                <div className="bg-green-200 w-full py-2 px-2 ">
                  Projects
                </div>
                <div className="w-full px-4 py-2">
                  {
                    data?.projects?.map((row, index) => {
                      return <div>
                          <div className="py-2 flex">
                            <div className="w-[60px]"><img src={row?.logo ? row?.logo : '/images/school.png' } className="w-[50px]" alt=""/></div>
                            <div className="">
                              <p>{row?.title}</p>
                              <p>{row?.subtitle}</p>
                              <p>{row?.caption}</p>
                            </div>
                          </div>
                        </div>
                    })
                  }
                </div>
                <div className="bg-green-200 w-full py-2 px-2 ">
                Certificates
                </div>
                <div className="w-full px-4 py-2">
                  {
                    data?.certificates?.map((row, index) => {
                      return <div>
                        <div className="py-2 flex">
                          <div className="w-[60px]"><img src={row?.logo ? row?.logo : '/images/school.png' } className="w-[50px]" alt=""/></div>
                          <div className="">
                            <p>{row?.title}</p>
                            <p>{row?.subtitle}</p>
                            <p>{row?.caption}</p>
                          </div>
                        </div>
                        </div>
                    })
                  }
                </div>
                
                <div className="bg-green-200 w-full py-2 px-2 ">Summary</div>
                <div className="w-full px-4 py-2">{data?.about}</div>
                <div className="bg-green-200 w-full py-2 px-2 ">Language</div>
                <div className="w-full px-4 py-2">{data?.language}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
