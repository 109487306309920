import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const updateprofile = ({ form, profileId, token }) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.profile}/${profileId}`;
  const headers = {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${token}`
  }
    return Http().post(URL, form, headers);
}

const logout = ({ token }) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.logout.logout}`;
  const headers = {
      "Authorization": `Bearer ${token}`
    }
  return Http().post(URL, null, headers);
}



const udpatePassword = ({ data, token }) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.changePassword}`;
  const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
  }
  return Http().post(URL, data, headers);
}

const sendlinkpass = (postdata) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.sendlinkpass}`;
  return Http().post(URL, postdata);
}

export const useProfile = () => {
  return useMutation({
    mutationFn: updateprofile,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
}

export const useChangePassword = () => {
  return useMutation({
    mutationFn: udpatePassword,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
}

export const useSendLinkPassword = () => {
  return useMutation({
    mutationFn: sendlinkpass,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
}

export const useLogout = () => {
  return useMutation({
    mutationFn: logout,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
}


