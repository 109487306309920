// src/MyChart.js

import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import moment from 'moment/moment';

// Register the required components
ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const LineChart = ({Report, chartType}) => {
  const dateValues = Report?.map(item => item.date);
  const counts = Report?.map(item => item.count);
  const data = {
    labels: dateValues,
    datasets: [
      {
        label: chartType,
        data: counts,
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: { // This line specifies the x-axis as category
        type: 'category',
      },
      y: {
        beginAtZero: true,
        Colors : "white"
      },
    },
  };

  return (
    <div className='w-full'>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
