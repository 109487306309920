import React, { useState } from "react"
import { useForm } from "react-hook-form";
import { Spinner } from "../../Loader/Spinner";
import { Link } from "react-router-dom";
import UserLayout from "../../../Layouts/Users/UserLayout";
import CandidateReport from "./CandidateReport";
import { FaBook, FaBriefcase, FaChartPie, FaUser } from "react-icons/fa";
import JobReport from "./JobsReport";
import AtsandHris from "./AtsandHris";
import Logs from "./Logs";

export const UserReport = ({open, setOpen}) => {
      const {
        register: orgregister,
        handleSubmit: orghandleSubmit,
        formState: { errors : orgerror },
      } = useForm();

    const [tabReport, setTabReports] = useState('Candidates');

    return (
        <UserLayout>
        {/* {orgLoadin ? <Spinner size={'medium'} /> : ""} */}
        <div className={`w-full px-10 py-10 bg-green-50 flex items-center flex-col`}>
            <div className="border-b border-gray-200 dark:border-gray-700 w-full">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    <li className="me-2" onClick={() => setTabReports('Candidates')}>
                        <Link className={`${tabReport==='Candidates'? 'text-blue-600 border-blue-600' : ''} inline-flex items-center   border-b-2  justify-center p-4  rounded-t-lg dark:text-blue-500 dark:border-blue-500 group`}>
                            <FaUser size={20} /> &nbsp; Candidates
                        </Link>
                    </li>
                    <li className="me-2" onClick={() => setTabReports('Jobs')}>
                        <Link  className={`${tabReport==='Jobs'? 'active text-blue-600 border-blue-600 ' : ''} inline-flex items-center border-b-2 justify-center p-4 rounded-t-lg dark:text-blue-500 dark:border-blue-500 group`}>
                            <FaBriefcase size={20} /> &nbsp; Jobs
                        </Link>
                    </li>
                    <li className="me-2" onClick={() => setTabReports('Ats&Hris')}>
                        <Link className={`${tabReport==='Ats&Hris'? 'text-blue-600 border-blue-600' : ''} inline-flex items-center   border-b-2  justify-center p-4  rounded-t-lg dark:text-blue-500 dark:border-blue-500 group`}>
                            <FaChartPie size={20} /> &nbsp; ATS and HRIS
                        </Link>
                    </li>
                    <li className="me-2" onClick={() => setTabReports('Logs')}>
                        <Link className={`${tabReport==='Logs'? 'active text-blue-600 border-blue-600 ' : ''} inline-flex items-center   border-b-2  justify-center p-4  rounded-t-lg dark:text-blue-500 dark:border-blue-500 group`} aria-current="page">
                            <svg className="w-4 h-4 me-2 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                            </svg>Logs
                        </Link>
                    </li>
                </ul>
                <div className="bg-white px-3 w-[100%] overflow-x-auto py-3">
                    {
                    tabReport==='Candidates' ?  <CandidateReport />: 
                    tabReport==='Jobs' ? <JobReport />:
                    tabReport==='Ats&Hris' ? <AtsandHris />:
                    tabReport==='Logs' ? <Logs />: ""
                        }
                </div>
            </div>
        </div>
        </UserLayout>
    );
}