import React, { useState } from "react";
import { HiMiniUsers } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import { BsFilterSquare } from "react-icons/bs";
import DataTable from "react-data-table-component";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { RxExternalLink } from "react-icons/rx";
import { Link } from "react-router-dom";
import useDebounce from "../../../Helpers/useDebounce";

export default function LeadsLayout() {
  const [page, setPage] = useState(1);
  const [state, setState] = useState({
    search: "",
    sortby: "",
  });

  const debouncesearch = useDebounce(state.search, 500);

  function getLeads() {
    let endpoints =
      endpoint.campaign.getleads +
      `?page=${page}&keyword=${debouncesearch}&status=${state.sortby}`;
    return Http().get(endpoints);
  }

  const { data: Leads, isLoading } = useQuery({
    queryKey: ["Leads", page, debouncesearch, state.sortby],
    queryFn: getLeads,
  });

  const columns = [
    {
      name: <p className="text-[15px]">Candidate Name</p>,
      selector: "full_name",
      cell: (row) => (
        <div className="flex items-center justify-center gap-2 text-[14px]">
          <img
            src={row?.profile_pic}
            alt="profile_pic"
            className="w-8 h-8 rounded-full "
          />
          {row?.full_name}
        </div>
      ),
    },
    {
      name: <p className="text-[15px]">Campaign Name</p>,
      selector: "headline",
      cell: (row) => (
        <Link
          target="_blank"
          to={`/campaigns-external/${row?.campaignuid}`}
          className="py-3 hover:text-blue-600 cursor-pointer text-[14px] flex items-center justify-center gap-1"
        >
          {row?.campaigntitle}{" "}
          <RxExternalLink className="text-[12px] text-blue-600" />
        </Link>
      ),
    },
    {
      name: <p className="text-[15px]">Headline</p>,
      selector: "headline",
      cell: (row) => <p className="py-3 text-[14px]">{row?.headline}</p>,
    },

    {
      name: <p className="text-[15px]">Status</p>,
      selector: "status",
      cell: (row) => (
        <div className="text-[14px]">
          {row?.status === "pending" ? (
            <p className="flex items-center justify-center gap-2 text-red-600 font-medium bg-red-100 rounded-full px-[14px] py-1">
              Requested
            </p>
          ) : row?.status === "accepted" ? (
            <p className="flex items-center justify-center gap-2 text-orange-600 font-medium bg-orange-100 rounded-full px-[14px] py-1">
              Awaiting Reply
            </p>
          ) : row?.status === "replied" ? (
            <p className="flex items-center justify-center gap-2 text-green-600 font-medium bg-green-200 rounded-full px-[14px]  py-1">
              Responded
            </p>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: <p className="text-[15px]">Initiated at</p>,
      selector: "created_at",
      cell: (row) => (
        <p className="py-3 flex items-start text-gray-500 text-[12px] justify-start flex-col">
          <span>
            {moment(row?.created_at)
              .local("Asia/Kolkata")
              .format("MMM Do YYYY")}
          </span>
          <span>
            {moment(row?.created_at).local("Asia/Kolkata").format("HH:mm A")}
          </span>
        </p>
      ),
    },
  ];
  //   .tz('Asia/Kolkata')
  const CustomCheckbox = React.forwardRef((props, ref) => (
    <input
      type="checkbox"
      className="cursor-pointer"
      ref={ref}
      {...props}
      style={{ transform: "scale(1.2)", accentColor: "#f50157" }}
    />
  ));

  return (
    <div className="w-full flex items-center flex-col justify-center gap-2 py-8">
      <div className="w-full  flex items-center   justify-center">
        <div className="w-[95%] bg-gradient-leads flex py-4 px-2 rounded-[8px] items-start flex-col justify-center gap-2">
          <p className="flex items-center justify-center gap-2 text-[18px]">
            <HiMiniUsers size={25} /> Leads
          </p>
          <p className="text-[12px] text-gray-800">
            All leads generated by Ai plug.
          </p>
        </div>
      </div>

      <div className="w-[95%] flex items-center justify-between gap-2 mt-2 border-y pt-3 border-gray-200">
        <div className="w-6/12 flex items-center justify-center gap-2">
          <CiSearch size={22} />
          <input
            className="w-full px-2 h-10 outline-none placeholder:text-[14px]"
            placeholder="Search by name or email..."
            value={state.search}
            onChange={(e) => setState({ ...state, search: e.target.value })}
          />
        </div>
        <div className="w-6/12 flex items-center justify-end gap-2">
          <select
            onChange={(e) => setState({ ...state, sortby: e.target.value })}
            className="block w-[300px] text-[14px] h-8 px-3 border border-gray-200 rounded-md"
          >
            <option value="">Select by status</option>
            <option value="replied">Responded</option>
            <option value="accepted">Awaiting Reply</option>
            <option value="pending">Requested</option>
          </select>
          {/* <BsFilterSquare size={22} /> */}
        </div>
      </div>
      {/* <div className="w-[95%] border border-gray-200 p-2 rounded-[5px] flex items-start flex-col justify-center gap-2">

      </div> */}
      <div
        id="leads"
        className="w-[95%] border border-gray-200 rounded-[8px] rounded-b-none flex items-start flex-col justify-center gap-2"
      >
        <DataTable
          columns={columns}
          data={Leads?.data?.data}
          progressPending={isLoading}
          onChangePage={(page) => setPage(page)}
          pagination
          filterAll={true}
          paginationServer
          //   onChangeRowsPerPage={onChangeRowsPerPage}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
          highlightOnHover
          selectableRowsHighlight
          paginationRowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          //   onSelectedRowsChange={onSelectedRowsChange}
          paginationTotalRows={Leads?.data?.total}
          className="border-b"
        />
      </div>
    </div>
  );
}
