import React from "react";

const RatingSystem = ({ Ratper, className='test' , plugpoints=0}) => {
  let Rating = Number(Ratper) > 10 ? Number(Ratper) / 10 : Number(Ratper);
  const RatingCount = (100 - (Rating * 100) / (Rating > 5 ? 10 : 5));

  return (
    <div  className={`newbody ${className ? className : "m-0"} group relative cursor-pointer`}>
      <div className={`${plugpoints ? 'w-[180px]' : 'w-[80px]'}  absolute text-center group-hover:visible invisible top-6 -z-10 group-hover:z-10 left-11  text-white group-hover:opacity-[1] transition-all duration-300 group-hover:top-[42px] opacity-0 bg-gray-600 py-1`}>
        {/* <FontAwesomeIcon
          className={`absolute text-[20px] top-[-11px] text-admin-col ${plugpoints ? 'right-20' : 'right-2' }`}
          icon={faCaretUp}
        /> */}
        <span className="text-[14px]">{plugpoints ? `Plugpoints = ${Rating} / 5 match` : Rating}</span>  
      </div>
      <div className="stars">
        <svg width="40" height="40" viewBox="0 0 136.000000 135.000000">
          <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)">
            <path d="M610 1289 c-154 -19 -269 -76 -380 -188 -83 -83 -137 -178 -165 -286 -21 -78 -21 -233 0 -310 36 -141 147 -305 206 -305 19 0 34 8 44 24 19 29 12 47 -42 111 -70 82 -113 205 -113 325 0 169 76 318 215 420 207 153 497 130 681 -54 210 -211 202 -552 -20 -753 -84 -76 -171 -116 -286 -129 -109 -12 -180 31 -180 111 0 31 43 163 57 175 4 4 28 5 53 1 65 -10 137 11 176 51 44 45 129 207 112 212 -7 2 -41 11 -76 20 -105 27 -220 89 -305 163 -20 18 -40 33 -45 33 -4 0 -29 -44 -56 -97 -40 -78 -49 -106 -48 -144 1 -60 23 -112 62 -148 l32 -28 -21 -42 c-34 -66 -54 -159 -48 -224 10 -111 87 -182 209 -194 258 -24 517 152 615 418 25 67 28 86 28 209 0 121 -3 143 -26 207 -35 94 -106 199 -179 266 -57 51 -167 113 -240 135 -30 10 -188 34 -193 31 -1 -1 -31 -5 -67 -10z" />
            <path d="M674 999 c-19 -39 -34 -72 -32 -73 9 -7 71 -36 73 -34 15 24 65 130 65 139 0 6 -8 18 -17 25 -36 27 -52 16 -89 -57z" />
            <path d="M896 895 c-20 -36 -36 -69 -36 -73 0 -5 16 -16 34 -25 l35 -16 19 32 c10 18 27 49 38 70 l19 39 -24 19 c-13 10 -29 19 -36 19 -7 0 -29 -29 -49 -65z" />
          </g>
        </svg>
        <svg width="40" height="40" viewBox="0 0 136.000000 135.000000">
          <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)">
            <path d="M610 1289 c-154 -19 -269 -76 -380 -188 -83 -83 -137 -178 -165 -286 -21 -78 -21 -233 0 -310 36 -141 147 -305 206 -305 19 0 34 8 44 24 19 29 12 47 -42 111 -70 82 -113 205 -113 325 0 169 76 318 215 420 207 153 497 130 681 -54 210 -211 202 -552 -20 -753 -84 -76 -171 -116 -286 -129 -109 -12 -180 31 -180 111 0 31 43 163 57 175 4 4 28 5 53 1 65 -10 137 11 176 51 44 45 129 207 112 212 -7 2 -41 11 -76 20 -105 27 -220 89 -305 163 -20 18 -40 33 -45 33 -4 0 -29 -44 -56 -97 -40 -78 -49 -106 -48 -144 1 -60 23 -112 62 -148 l32 -28 -21 -42 c-34 -66 -54 -159 -48 -224 10 -111 87 -182 209 -194 258 -24 517 152 615 418 25 67 28 86 28 209 0 121 -3 143 -26 207 -35 94 -106 199 -179 266 -57 51 -167 113 -240 135 -30 10 -188 34 -193 31 -1 -1 -31 -5 -67 -10z" />
            <path d="M674 999 c-19 -39 -34 -72 -32 -73 9 -7 71 -36 73 -34 15 24 65 130 65 139 0 6 -8 18 -17 25 -36 27 -52 16 -89 -57z" />
            <path d="M896 895 c-20 -36 -36 -69 -36 -73 0 -5 16 -16 34 -25 l35 -16 19 32 c10 18 27 49 38 70 l19 39 -24 19 c-13 10 -29 19 -36 19 -7 0 -29 -29 -49 -65z" />
          </g>
        </svg>
        <svg width="40" height="40" viewBox="0 0 136.000000 135.000000">
          <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)">
            <path d="M610 1289 c-154 -19 -269 -76 -380 -188 -83 -83 -137 -178 -165 -286 -21 -78 -21 -233 0 -310 36 -141 147 -305 206 -305 19 0 34 8 44 24 19 29 12 47 -42 111 -70 82 -113 205 -113 325 0 169 76 318 215 420 207 153 497 130 681 -54 210 -211 202 -552 -20 -753 -84 -76 -171 -116 -286 -129 -109 -12 -180 31 -180 111 0 31 43 163 57 175 4 4 28 5 53 1 65 -10 137 11 176 51 44 45 129 207 112 212 -7 2 -41 11 -76 20 -105 27 -220 89 -305 163 -20 18 -40 33 -45 33 -4 0 -29 -44 -56 -97 -40 -78 -49 -106 -48 -144 1 -60 23 -112 62 -148 l32 -28 -21 -42 c-34 -66 -54 -159 -48 -224 10 -111 87 -182 209 -194 258 -24 517 152 615 418 25 67 28 86 28 209 0 121 -3 143 -26 207 -35 94 -106 199 -179 266 -57 51 -167 113 -240 135 -30 10 -188 34 -193 31 -1 -1 -31 -5 -67 -10z" />
            <path d="M674 999 c-19 -39 -34 -72 -32 -73 9 -7 71 -36 73 -34 15 24 65 130 65 139 0 6 -8 18 -17 25 -36 27 -52 16 -89 -57z" />
            <path d="M896 895 c-20 -36 -36 -69 -36 -73 0 -5 16 -16 34 -25 l35 -16 19 32 c10 18 27 49 38 70 l19 39 -24 19 c-13 10 -29 19 -36 19 -7 0 -29 -29 -49 -65z" />
          </g>
        </svg>
        <svg width="40" height="40" viewBox="0 0 136.000000 135.000000">
          <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)">
            <path d="M610 1289 c-154 -19 -269 -76 -380 -188 -83 -83 -137 -178 -165 -286 -21 -78 -21 -233 0 -310 36 -141 147 -305 206 -305 19 0 34 8 44 24 19 29 12 47 -42 111 -70 82 -113 205 -113 325 0 169 76 318 215 420 207 153 497 130 681 -54 210 -211 202 -552 -20 -753 -84 -76 -171 -116 -286 -129 -109 -12 -180 31 -180 111 0 31 43 163 57 175 4 4 28 5 53 1 65 -10 137 11 176 51 44 45 129 207 112 212 -7 2 -41 11 -76 20 -105 27 -220 89 -305 163 -20 18 -40 33 -45 33 -4 0 -29 -44 -56 -97 -40 -78 -49 -106 -48 -144 1 -60 23 -112 62 -148 l32 -28 -21 -42 c-34 -66 -54 -159 -48 -224 10 -111 87 -182 209 -194 258 -24 517 152 615 418 25 67 28 86 28 209 0 121 -3 143 -26 207 -35 94 -106 199 -179 266 -57 51 -167 113 -240 135 -30 10 -188 34 -193 31 -1 -1 -31 -5 -67 -10z" />
            <path d="M674 999 c-19 -39 -34 -72 -32 -73 9 -7 71 -36 73 -34 15 24 65 130 65 139 0 6 -8 18 -17 25 -36 27 -52 16 -89 -57z" />
            <path d="M896 895 c-20 -36 -36 -69 -36 -73 0 -5 16 -16 34 -25 l35 -16 19 32 c10 18 27 49 38 70 l19 39 -24 19 c-13 10 -29 19 -36 19 -7 0 -29 -29 -49 -65z" />
          </g>
        </svg>
        <svg width="40" height="40" viewBox="0 0 136.000000 135.000000">
          <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)">
            <path d="M610 1289 c-154 -19 -269 -76 -380 -188 -83 -83 -137 -178 -165 -286 -21 -78 -21 -233 0 -310 36 -141 147 -305 206 -305 19 0 34 8 44 24 19 29 12 47 -42 111 -70 82 -113 205 -113 325 0 169 76 318 215 420 207 153 497 130 681 -54 210 -211 202 -552 -20 -753 -84 -76 -171 -116 -286 -129 -109 -12 -180 31 -180 111 0 31 43 163 57 175 4 4 28 5 53 1 65 -10 137 11 176 51 44 45 129 207 112 212 -7 2 -41 11 -76 20 -105 27 -220 89 -305 163 -20 18 -40 33 -45 33 -4 0 -29 -44 -56 -97 -40 -78 -49 -106 -48 -144 1 -60 23 -112 62 -148 l32 -28 -21 -42 c-34 -66 -54 -159 -48 -224 10 -111 87 -182 209 -194 258 -24 517 152 615 418 25 67 28 86 28 209 0 121 -3 143 -26 207 -35 94 -106 199 -179 266 -57 51 -167 113 -240 135 -30 10 -188 34 -193 31 -1 -1 -31 -5 -67 -10z" />
            <path d="M674 999 c-19 -39 -34 -72 -32 -73 9 -7 71 -36 73 -34 15 24 65 130 65 139 0 6 -8 18 -17 25 -36 27 -52 16 -89 -57z" />
            <path d="M896 895 c-20 -36 -36 -69 -36 -73 0 -5 16 -16 34 -25 l35 -16 19 32 c10 18 27 49 38 70 l19 39 -24 19 c-13 10 -29 19 -36 19 -7 0 -29 -29 -49 -65z" />
          </g>
        </svg>
        <div className="cover" style={{ width: RatingCount + "%" }}></div>
      </div>
    </div>
  );
};

export default RatingSystem;
