import React from "react";
import LineChart from "../../Chart/LineChart";
import DoughnutChart from "../../Chart/Doughnut";
import RadarChart from "../../Chart/RadarChart";
import BarlineChart from "../../Chart/BarlineChart";
import { useQuery } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import Token from "../../../Helpers/Token";
export default function CandidateReport(){
    const  getReportCandWeek = () => {
        const URL = `${endpoint.report.candidate}?typeof=Week`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const  getReportCandYear = () => {
        const URL = `${endpoint.report.candidate}?typeof=Year`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const  mulipleSource = () => {
        const URL = `${endpoint.report.candidate}?typeof=mulipleSource`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const {data:candReportWeek} = useQuery({queryKey: ['getcandreportweek'], queryFn: getReportCandWeek});
    const {data:candReportYear} = useQuery({queryKey: ['getcandreportyear'], queryFn: getReportCandYear});
    const {data:candReportmultipleSource} = useQuery({queryKey: ['getcandreportSource'], queryFn: mulipleSource});
    
    return (
        <>
        <div className="flex flex-wrap  justify-between">
            <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                <h1 className="text-center">Candidate Report This Week</h1>
                <LineChart Report={candReportWeek} chartType="Candidate"/>
            </div>
            <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                <h1 className="text-center">Total Candidate Source Count </h1>
                <DoughnutChart Report={candReportmultipleSource} datatype={"cvsource"}  />
            </div>
            <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
                <h1 className="text-center">Candidate Report Montly</h1>
                <BarlineChart Report={candReportYear} chartLabel="Candidate" />
            </div>
        </div>
        </>
    )
}