export const ExperienceLevel = [
    { value: "1", label: "1 Year" },
    { value: "2", label: "2 Years" },
    { value: "3", label: "3 Years" },
    { value: "4", label: "4 Years" },
    { value: "5", label: "5 Years" },
    { value: "6", label: "6 Years" },
    { value: "7", label: "7 Years" },
    { value: "8", label: "8 Years" },
    { value: "9", label: "9 Years" },
    { value: "10", label: "10 Years" },
    { value: "11", label: "11 Years" },
    { value: "12", label: "12 Years" },
    { value: "13", label: "13 Years" },
    { value: "14", label: "14 Years" },
    { value: "15", label: "15 Years" },
    { value: "16", label: "16 Years" },
    { value: "17", label: "17 Years" },
    { value: "18", label: "18 Years" },
    { value: "19", label: "19 Years" },
    { value: "20", label: "20 Years" },
    { value: "21", label: "21 Years" },
    { value: "22", label: "22 Years" },
    { value: "23", label: "23 Years" },
    { value: "24", label: "24 Years" },
    { value: "25", label: "25 Years" },
    { value: "26", label: "26 Years" },
    { value: "27", label: "27 Years" },
    { value: "28", label: "28 Years" },
    { value: "29", label: "29 Years" },
    { value: "30", label: "30 Years" },
    { value: "31", label: "31 Years" },
    { value: "32", label: "32 Years" },
    { value: "33", label: "33 Years" },
    { value: "34", label: "34 Years" },
  ];