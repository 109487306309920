import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSendLinkPassword } from "../../../hooks/apihooks/useProfile";

export default function ForgotPassword() {
    const {
      register,
    //   watch,
    //   reset,
      handleSubmit,
      formState: { errors },
  } = useForm();
  
    const {mutateAsync, isPending} = useSendLinkPassword();

    const onSubmit = (data) => {
        mutateAsync(data).then((resp) => {
            if(resp.status===true){
            toast.success(resp?.message);
            }
            if(resp?.status===false){
                toast.error(resp?.message);
            }
        });
    }
  return (
    <>
      <section className='bg-green-100 h-[100vh]'>
        <div className='m-[auto] max-sm:px-3 relative'>
          <div className='flex flex-wrap max-sm:flex-wrap-reverse items-center'>
            <div className='w-[50%] px-10 max-sm:w-full'>
              <div className='py-5 absolute top-0 left-[3%] max-sm:ml-5'><Link to={'/'} className='font-bold'>{process.env.REACT_APP_APP_NAME}</Link></div>
              <h1 className='text-[40px] font-extrabold'>The Hire talent Engagement Platform</h1>
              <img src='/images/loginpage.png' alt="loginpage" />
              <p>Accelerate hiring, strengthen your brand, and exceed recruiting targets - all while delivering a personalized candidate experience.</p>
            </div>
            <div className='w-[50%] bg-[white] max-sm:w-full h-[100vh]'>
              <div className='px-10 max-sm:p-0 flex flex-wrap items-center h-[100vh]'>
                <div className='px-5 py-5 max-sm:w-full w-[70%] m-[auto]'>
                  <div className='w-full'>
                    <h2 className='text-[25px] py-5 font-semibold'>Hi, welcome to {process.env.REACT_APP_APP_NAME}!</h2>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-wrap justify-between mb-3'>
                      <div className='w-[100%] mb-5'>
                        <label className='py-2 block'>Email ID<sup className='text-[red]'>*</sup></label>
                        <input type='text' name="email" {...register("email", {required : true})} className='w-full px-2 py-2 border  rounded' placeholder='Email ID' />
                        {errors.email && <span className='text-[red]'>Email-Id is required</span>}
                      </div>
                    </div>
                    <div className='flex justify-between flex-wrap mb-3'>
                      <div className='w-[100%]'>
                        <button type='text' className='w-full px-2 py-2 bg-green-700 text-white  rounded'>{isPending ? 'Loading...' : "Send"}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
