import React, { useState } from "react";
import SideBar from "./SideBar";

export default function AdminLayouts({ children }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="w-full flex items-start justify-center relative">
      <SideBar />
        <div
          className={`${
            open ? "w-full" : "w-[80%]"
          } flex items-center justify-center`}
        >
        {typeof children === 'function' ? children({ open, setOpen }) : children}
        </div>
    </div>
  );
}
