import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { IoSendSharp } from "react-icons/io5";
import { SlMenu } from "react-icons/sl";
import { useLocation, useParams } from "react-router-dom";
import extractJSON from "../../../Helpers/extractJSON";
import { getHeader } from "../../../Helpers/header";
import ProcessText from "../../../Helpers/ProcessText";
import Schedule from "../../../Popups/Schedule";
import Loader from "../../Loader/Loader";
import { useCreateCampaign } from "../../../hooks/apihooks/Campaigns/useCreateCampaign";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import { useQuery } from "@tanstack/react-query";
import { useUpdateCampaign } from "../../../hooks/apihooks/Campaigns/useUpdateCampaign";
import { toast } from "react-toastify";
import CreateCampaignJob from "../../../Popups/CreateCampaignJob";

export default function Campaigns({ open, setOpen }) {
  // params
  const { campaignuid } = useParams();
  let location = useLocation();

  // states
  const [state, setState] = useState({
    title : '',
    job : "",
  })
  const [streams, setStreams] = useState("");
  const [campaignJob, setCampaignJob] = useState(false);
  const [interview, setinterview] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [selectedCand, setSelectedCand] = useState();
  // hooks
  const { handleSubmit, register, setValue } = useForm();
  const { mutateAsync: updateCampaign, isPending } = useUpdateCampaign();
  const { mutateAsync } = useCreateCampaign();

  // functions
  function getCampaign() {
    let endpoints = endpoint.campaign.single.replace(
      "{campaignuid}",
      campaignuid
    );
    return Http().get(endpoints);
  }

  // query
  const { data: campaign } = useQuery({
    queryKey: ["Campaign"],
    queryFn: getCampaign,
  });

  // handlers

  const createCampaign = (event) => {
    event.preventDefault();
    if (!campaign?.data?.jobid) {
      setCampaignJob(true);
      return false
    }
   
    const formData = new FormData(event.target);
    const Candidateids = formData.getAll("candidateid");

    if (Candidateids.length === 0) {
      alert("Please select at least one candidate");
      return false;
    }

    let PostData = {
      update: 1,
      candidateid: Candidateids,
      campaignid: campaign?.data?.campaignid,
      interviewstatus: false,
      location: "",
      meeting_link: "",
      slots: "",
      interviewtype: "",
      screening: false,
      wanotification: false,
      emailnotification: true,
      interviewermail: "",
      interviewername: "",
      campaignuid,
    };

    updateCampaign(PostData).then((res) => {
      console.log(res);
    });
  };

  function toggleAllCheckboxes() {
    const checkboxes = document.querySelectorAll('input[name="candidateid"]');
    let count = 0;
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        count++;
      }
      checkbox.addEventListener("change", () => {
        count += checkbox.checked ? 1 : -1;
        count = Math.max(0, count);
        setSelectedCand(count);
      });
    });
    setSelectedCand(count);
    const mastercheckbox = document.getElementById("mastercheckbox");
    mastercheckbox.addEventListener("change", () => {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = mastercheckbox.checked;
      });
      count = mastercheckbox.checked ? checkboxes.length : 0;
      setSelectedCand(count);
    });
  }

  async function streaming(data) {
    setStreams("");

    let payLoad = {
      prompt: data.query,
      prompt_chat_count: 1,
      user_id: "SYSTEM",
      agency_id: "F7u4YBzv9E8LrdjX",
      employer_id: "",
      admin_id: "",
    };

    try {
      setisLoading(true);
      let header = getHeader("genai");
      const response = await fetch(process.env.REACT_APP_STREAMING_API, {
        method: "POST",
        headers: header,
        body: JSON.stringify(payLoad),
      });

      if (!response.ok) {
        setisLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setisLoading(false);
      const reader = response.body.getReader();
      let eventStream = "";
      let metadata = false;
      let leftData = "";
      let rightData = "";
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          let jsonResponse = eventStream.split("#FINAL_RESPONSE#")[1];
          let response = extractJSON(jsonResponse ? jsonResponse : eventStream);
          if (response) {
            let json = response[0] || {};
            if (json?.code !== 200) {
              setStreams("");
              toast.warn(json?.message);
            }
          }
          break;
        } else {
          eventStream = new TextDecoder().decode(value);
          let lines = eventStream;
          if (lines?.includes("#FINAL_RESPONSE#") || metadata) {
            metadata = true;
            const metaKEY = lines;
            const finalResponseIndex = metaKEY.indexOf("#FINAL_RESPONSE#");

            if (lines?.includes("#FINAL_RESPONSE#")) {
              if (finalResponseIndex !== -1) {
                leftData = metaKEY.substring(0, finalResponseIndex);
              }
              // eslint-disable-next-line
              setStreams((prevStreams) => prevStreams + (leftData || ""));
            }

            if (finalResponseIndex !== -1) {
              rightData =
                metaKEY.substring(
                  finalResponseIndex + `#FINAL_RESPONSE#`.length
                ) || lines;
            }
          } else {
            // setStreams(streams, lines);
            setStreams((prevStreams) => prevStreams + lines);
          }
        }
      }

      let json = extractJSON(rightData);
      let candidates = json.length > 0 ? json[0]?.data : {};
      updateCampaign({ chat_logs: candidates, campaignuid });
      setSelectedCand(candidates?.file_metadata?.length || 0);
      return true;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  // data formating

  function getSummary(summary) {
    const TextFromDatabase = summary;
    return TextFromDatabase;
  }

  let yourTextFromDatabase = useMemo(
    () => getSummary(streams || campaign?.data?.chat_logs?.summary || ""),
    [streams, campaign?.data?.chat_logs?.summary]
  );

  const processedText = ProcessText(yourTextFromDatabase);

  // let processedText = ProcessText(campaign?.data?.chat_logs?.summary);
  let resultslength = campaign?.data?.chat_logs?.file_metadata?.length
    ? campaign?.data?.chat_logs?.file_metadata?.length
    : 0;

  // component lifecycle

  useEffect(() => {
    let prompt = location.search.split("&")[1]?.split("prompt=")[1];
    let query = location.search.split("&")[0]?.includes("query=true");
    if (query && prompt) {
      setValue("query", prompt ? atob(prompt) : "");
    }

    if (campaign?.data?.chat_logs) {
      let chat_logs = campaign?.data?.chat_logs;
      setSelectedCand(chat_logs?.file_metadata?.length || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, campaign?.data?.chat_logs]);

  return (
    <div className="h-[100vh] w-full sm:w-[80%] max-w-[100%] flex-1 pb-5 bg-green-50 flex items-center gap-3 justify-end flex-col relative ">
      <div className="w-full fixed top-0 block sm:hidden bg-green-100">
        <div className="w-full flex items-center justify-between px-6 min-h-[80px]">
          <p className="font-robo text-[20px] text-green-900">{process.env.REACT_APP_APP_NAME}</p>
          {open ? "" : <SlMenu onClick={() => setOpen((v) => !v)} size={20} />}
        </div>
      </div>
      {isPending ? <Loader /> : ""}

      <div className="w-11/12 flex items-start flex-col justify-center border-b border-gray-100 pb-1">
        <p className="font-semibold text-[20px]">
          Step 1/<span className="font-normal">2</span> : Search Query
        </p>
        <p className="font-semibold text-[16px]">
          {campaign?.data?.campaigntitle}
        </p>
      </div>
      
      <Schedule open={interview} setOpen={setinterview} />
      {isLoading ? <Loader /> : ""}

      <div className="w-11/12 flex items-center justify-center gap-2 flex-col">
        <form
          onSubmit={createCampaign}
          className="w-full flex sm:items-center justify-center flex-col gap-2"
        >
          <div
            dangerouslySetInnerHTML={processedText}
            className="w-11/12 h-[70vh] border-b pb-2 border-gray-100  overflow-y-scroll flex-col whitespace-pre-line"
          />
          <div className="w-full flex sm:items-center justify-between sm:flex-row flex-col gap-2">
            {resultslength > 0 ? (
              <>
                <div className="flex items-center justify-center gap-2">
                  <label className="flex items-center justify-center gap-1">
                    <input
                      type="checkbox"
                      id="mastercheckbox"
                      value="1"
                      defaultChecked
                      onChange={toggleAllCheckboxes}
                      // disabled={(resultslength === selectedCand) && selectedCand === 0 ? false : true}
                      className="w-4 h-4 relative  accent-[#00a63f]"
                    />{" "}
                    <p className="whitespace-nowrap text-gray">Select All | </p>
                  </label>
                  <p className="text-[14px]">
                    We found {resultslength} matching profile(s){" "}
                  </p>
                </div>{" "}
                <CreateCampaignJob data={campaign?.data} open={campaignJob} setOpen={setCampaignJob} />
                <div className="flex items-center justify-between gap-3">
                  <button
                    type="button"
                    onClick={() => setinterview(true)}
                    disabled={isLoading}
                    className="text-[14px] border border-green-600 text-green-500 px-4 py-1 rounded-md bg-white hover:scale-95 transition-all"
                  >
                    Intrerview ({selectedCand})
                  </button>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="text-[14px]  bg-green-600 text-white px-4 py-1 rounded-md hover:scale-95 transition-all "
                  >
                    Begin your Campaign ({selectedCand})
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </form>
        <form onSubmit={handleSubmit(streaming)} className="w-full relative ">
          <textarea
            className="border border-gray-300 shadow-lg pt-2 w-full rounded-[10px] px-5 text-[15px] outline-green-700 "
            name="query"
            {...register("query")}
            placeholder="Start typing your query here..."
            // onInput={handleResize}
            rows={4}
            disabled={isLoading}
          />
          <button type="submit" className="absolute right-3 bottom-5">
            <IoSendSharp size={25} color="#16a34a" />
          </button>
        </form>
      </div>
    </div>
  );
}
