import * as localStorage from './Storage';

const ACCESS_TOKEN = 'anonymous_id';

const token = () => {
  function _setToken(token) {
    localStorage.set(ACCESS_TOKEN, token);   
    // cookies.set(ACCESS_TOKEN, tokenObj?.token) 
  }

  function _getAccessToken() {
    return localStorage.get(ACCESS_TOKEN);
    //  return cookies.get(ACCESS_TOKEN);
  }

  function _clearToken() {
    localStorage.remove(ACCESS_TOKEN);
    // cookies.remove(ACCESS_TOKEN);
  }

  return {
    setToken: _setToken,
    getAccessToken: _getAccessToken,    
    clearToken: _clearToken,
  };
};

export default token();