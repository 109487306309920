import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import { RxCaretRight } from "react-icons/rx";

export default function JobCard({ data }) {

  const {
    jobuid,
    jobtitle,
    location,
    experiencefrom,
    experienceto,
    created_at,
  } = data || {};

  return (
    <div className="w-full p-4 bg-white  flex items-center justify-center flex-col gap-2 border border-gray-200 rounded-[5px] shadow-sm">
      <div className="w-full flex items-center justify-between">
        <Link to={`/jobdetail/${jobuid}`} className="w-[70%] text-[16px] font-semibold">
          {jobtitle}
        </Link>
        <p className="w-[15%] text-[15px]">
          <span className="font-semibold">Location</span> : {location}
        </p>
        <p className="w-[15%] text-[15px]">
          <span className="font-semibold">Experiecne</span> : {experiencefrom}{" "}
          to {experienceto} years
        </p>
      </div>
      <div className="w-full flex items-center justify-between">
      <p className="text-[14px]">
          <span className="font-semibold ">Posted on</span> :{" "}
          {moment(created_at).format("DD-MMM-YYYY")}
        </p>
        <Link to={`/jobdetail/${jobuid}`} className="text-[15px] text-green-600 flex items-center justify-center">
          View deatils  <RxCaretRight className="text-[24px]" />

        </Link>
        
      </div>
    </div>
  );
}
