import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Role from '../../../Helpers/Role';
import { useGoogleLogin } from '@react-oauth/google';
import token from '../../../Helpers/Token';
import { useGoogleSingupLogin, useLogin, useTwoAuthLogin } from '../../../hooks/apihooks/useSignup';
import { Spinner } from '../../Loader/Spinner';
import { set } from '../../../Helpers/Storage';
import LinkedInOAuth from '../../Auth/LinkedInOAuth';
import Http from '../../../http';
import { useQuery } from '@tanstack/react-query';
import Token from '../../../Helpers/Token';
export default function Signin() {

  const [otpverify, setotpverify] = useState(false);
  const [ user, setUser ] = useState(null);
  let numberOfDigits = 6
  const [otp, setOtp] = useState(["","","","","",""]);
  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);
  const {mutateAsync : googleLogin, isPending: googlepending} = useGoogleSingupLogin();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const {mutateAsync: login , isPending} = useLogin();
  const {isPending: ispendingtowauth, mutateAsync : twoAuth} = useTwoAuthLogin();

  const signIn = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  const onSubmit = (data) => {
    login(data).then((resp) => {
      if(resp?.status===false && resp?.message){
        if(resp?.data==='optverfication'){
          toast.warning(resp?.message)
          setotpverify(true)
          return;
        }
        toast.error(resp?.message)
      }else if(resp?.status===false && resp?.error){
        for (const [field, messages] of Object.entries(resp?.error)) {
          messages.forEach(message => {
              toast.error(`${field}: ${message}`);
          });
        }
      }

      if(resp?.status===true){
        console.log("resp", resp);
        set("userAuth", resp?.data);
        token.setToken(resp?.data?.jwt_token)
        Role.setRole(resp?.data?.role);
        if(resp?.data?.role==='Admin'){
          navigate('/admin/dashboard');
        }else{
          navigate('/plug');
        }
        toast.success(resp?.data?.message);
      }
      
    })
  }

  const onSubmitAuthVerify = (data) => {
    console.log("User Data twoAuth", {...data, otp});
    let newdata = {...data, otp : otp.join('')};
    twoAuth(newdata).then((resp) => {
      if(resp?.status===true){
        console.log("resp", resp);
        set("userAuth", resp?.data);
        token.setToken(resp?.data?.jwt_token)
        Role.setRole(resp?.data?.role);
        if(resp?.data?.role==='Admin'){
          navigate('/admin/dashboard');
        }else{
          navigate('/plug');
        }
      }
      if(resp?.status===false){
        toast.error(resp?.message);
      }
    });
  }

  useEffect(() => {
    if(!token.getAccessToken()) {
      navigate(`/signin`);
    } else if (token.getAccessToken() && Role.getAccessRole() === 'Admin') {
      navigate(`/admin/dashboard`);
    } else if (token.getAccessToken() && Role.getAccessRole() === 'User') {
      navigate(`/plug`);
    } else {
      navigate(`/signin`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token,Role]);

  const getacesstokeGoogle = () => {
    const URL  = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`;
    return Http().get(URL, {
      'Authorization' : `Bearer ${user.access_token}`,
      'Accept' : 'application/json'
    });
  }

  const {data: googleData, isLoading}  = useQuery({queryKey : ['getacesstoke'], queryFn : getacesstokeGoogle, enabled : Boolean(user?.access_token)});

  useEffect(() => {
    if(googleData){
      let newdata = {signtype : 'google', ...googleData}
      console.log("gogole data", newdata);
      googleLogin(newdata).then((response) => {
          if(response?.status===true){
            set("userAuth", response?.data);
            Token.setToken(response?.token)
            Role.setRole(response?.data?.role);
            if(response?.data?.role==='Admin'){
              navigate('/admin/dashboard');
            }else{
              navigate('/plug');
            }
          }
          if (response?.status===false) {
            toast.error(response?.message);
          }
      })
    }
  }, [googleData])
  

  function onPaste(event) {
    const pasted = event.clipboardData.getData("text/plain")
    setOtp(pasted.split("").slice(0, otp.length))
  }

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if(value && index < numberOfDigits-1){
      otpBoxReference.current[index+1].focus()
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if(e.key === "Backspace" && !e.target.value && index > 0){
      otpBoxReference.current[index - 1].focus()
    }
    if(e.key === "Enter" && e.target.value && index < numberOfDigits-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  return (
    <>
      {/* <WebHeader /> */}
      {isPending || googlepending? <Spinner size='medium' /> : ""}
      <section className='bg-green-100 h-[100vh]'>
        <div className='m-[auto] max-sm:px-3 relative'>
          <div className='flex flex-wrap max-sm:flex-wrap-reverse items-center'>
            <div className='w-[50%] px-10 max-sm:w-full'>
              <div className='py-5 absolute top-0 left-[3%] max-sm:ml-5'><Link to={'/'} className='font-bold'>{process.env.REACT_APP_APP_NAME}</Link></div>
              <h1 className='text-[40px] font-extrabold'>The Hire talent Engagement Platform</h1>
              <img src='/images/loginpage.png' alt=''/>
              <p>Accelerate hiring, strengthen your brand, and exceed recruiting targets - all while delivering a personalized candidate experience.</p>
            </div>
            <div className='w-[50%] bg-[white] max-sm:w-full h-[100vh]'>
              <div className='px-10 max-sm:p-0 flex flex-wrap items-center h-[100vh]'>
                <div className='px-5 py-5 max-sm:w-full w-[70%] m-[auto]'>
                  <div className='w-full'>
                    <h2 className='text-[25px] py-5 font-semibold'>Hi, welcome to {process.env.REACT_APP_APP_NAME}!</h2>
                  </div>
                  {otpverify===false ? 
                  (<>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-wrap justify-between mb-3'>
                      <div className='w-[100%] mb-5'>
                        <label className='py-2 block'>Username<sup className='text-[red]'>*</sup></label>
                        <input type='text' name="email" {...register("email", {required : true})} className='w-full px-2 py-2 border  rounded' placeholder='Username' />
                        {errors.email && <span className='text-[red]'>Email-Id is required</span>}
                      </div>
                      <div className='w-[100%]'>
                        <label className='py-2 block'>Password<sup className='text-[red]'>*</sup></label>
                        <input type='password' className='w-full px-2 py-2 border  rounded' {...register("password", {required : true})} placeholder='Password' />
                        {errors.password && <span className='text-[red]'>Password is required</span>}
                      </div>
                    </div>
                    <div className='flex justify-between flex-wrap mb-3'>
                      <div className='w-full py-3'>Can't log in? <Link to={'/forgot-password'} className='text-green-700'>Forgot password</Link></div>
                      <div className='w-[100%]'>
                        <button type='text' disabled={isPending?true:false} className='w-full px-2 py-2 bg-green-700 text-white  rounded'>{isPending ? <Spinner />: "Login"}</button>
                      </div>
                    </div>
                  </form>
                  <div className='py-5 relative'>
                    <hr />
                    <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white py-2 w-[40px] h-[40px] text-center middle'>OR</span>
                  </div>
                  <div className='flex flex-wrap justify-between'>
                      <button onClick={() => signIn()} className='w-[49%] max-sm:mb-3 max-sm:w-full border-2 py-3 rounded-[8px] flex justify-center items-center'>
                        <div className='w-[20px] mr-3'>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                         </div>
                        <div className='text-[13px] max-sm:text-[16px]'>Continue with Google</div>
                      </button>
                      <LinkedInOAuth />
                      <div className='w-full'>
                      <p className='py-2 text-center'>New to {process.env.REACT_APP_APP_NAME}?
                        <Link to="/signup" className='text-green-700'> Create an account</Link></p>
                      </div>
                  </div>
                  </>) : 
                  (<form onSubmit={handleSubmit(onSubmitAuthVerify)}>
                    <div className='flex flex-wrap justify-between mb-3'>
                      <div className='w-[100%] mb-5'>
                        <label className='py-2 block'>Enter OTP<sup className='text-[red]'>*</sup></label>
                        <div className='flex w-full justify-between'>
                            {otp.map((digit, index)=>(
                              <input key={index} value={digit} maxLength={1}  
                              onChange={(e)=> handleChange(e.target.value, index)}
                              onPaste={onPaste}
                              onKeyUp={(e)=> handleBackspaceAndEnter(e, index)}
                              ref={(reference) => (otpBoxReference.current[index] = reference)}
                              className={`border w-[50px] p-3 rounded-md`}
                              />
                            ))}
                        </div>
                        <p className={`text-lg text-white mt-4 ${otpError ? 'text-[red]' : ''}`}>{otpError}</p>
                      </div>
                      <div className='w-[100%]'>
                        <button type='submit' disabled={ispendingtowauth?true:false} className='w-full px-2 py-2 bg-green-700 text-white  rounded'>{ispendingtowauth ? <Spinner />: "Login"}</button>
                      </div>
                    </div>
                  </form>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
