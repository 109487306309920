import React, { useState } from 'react';

const Tooltip = ({ children, message }) => {
    const [visible, setVisible] = useState(false);
    const [timer, setTimer] = useState(null);
    const showTooltip = () => {
      if (timer) {
        clearTimeout(timer); 
      }
      setTimer(setTimeout(() => {
        setVisible(true);
      }, 800));
    };

    const hideTooltip = () => {
      if (timer) {
        clearTimeout(timer);
      }
      setVisible(false);
    };

    const toggleTooltip = () => {
      setVisible(false);
    };

    return (
        <div className="tooltip-container" onClick={toggleTooltip} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            {children}
            {visible && (
                <div className="tooltip">
                    {message}
                </div>
            )}
        </div>
    );
};

export default Tooltip;

