import React, { useContext, useEffect, useRef, useState } from "react";
import ContextProvider from "../../Context/ContextProvider";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

export default function Header() {
  const [hamberger, setHamberger] = useState(false);
  const wrapperRef = useRef(null);
  const { currency, setCurrency } = useContext(ContextProvider);
  const [handleCurrency, setHandleCurrency] = useState(false);
  let session = {};
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setHamberger(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return (
    <header className="shadow-[0px_0px_6px_0px_#00000073] fixed top-0 w-full z-[10] bg-[#ffffff] text-[black]">
      <nav
        className="container max-sm:px-3 py-6 m-[auto] flex justify-between items-center"
        ref={wrapperRef}
      >
        <div>
          <Link to="/" className="font-bold">
            <img src="/images/logo/logo.png" width="200px"/>
          </Link>
        </div>
        <div
          className={`${
            hamberger
              ? "absolute top-[75px] left-0 transition-transform lg:hidden"
              : "max-sm:hidden"
          }`}
        >
          <div
            className={`${
              hamberger
                ? "flex flex-wrap bg-[white] border border-b transition-transform"
                : ""
            }`}
          >
            <Link
              to={`/about`}
              className="px-3 hover:text-[#9C27B0] max-sm:w-full max-sm:py-2 max-sm:border max-sm:border-b"
            >
              About
            </Link>
            <Link
              to={`/solution`}
              className="px-3 hover:text-[#9C27B0] max-sm:w-full max-sm:py-2 max-sm:border max-sm:border-b"
            >
              Solution
            </Link>
            <Link
              to={`/about`}
              className="px-3 hover:text-[#9C27B0] max-sm:w-full max-sm:py-2 max-sm:border max-sm:border-b"
            >
              Resources
            </Link>
            <Link
              to={`/about`}
              className="px-3 hover:text-[#9C27B0] max-sm:w-full max-sm:py-2 max-sm:border max-sm:border-b"
            >
              Marketplace
            </Link>
            <Link
              to={`/contact-us`}
              className="px-3 hover:text-[#9C27B0] max-sm:w-full max-sm:py-2"
            >
              Contact Us
            </Link>
          </div>
          <div className="lg:hidden bg-white w-full flex-wrap justify-center  text-center items-center flex px-3 py-3">
            <div className="w-full block py-1">
              <Link
                to={`/signin`}
                className="border border-1 py-2 px-3 block w-[100%] rounded-full bg-green-100 text-black  hover:bg-green-100 ease-in-out duration-300"
              >
                Sign In
              </Link>
            </div>
            <div className="w-full py-1">
              <Link
                to={`/demo-request`}
                className="border border-1 py-2 px-3 block w-[100%] rounded-full bg-green-100 text-black  hover:bg-green-100 ease-in-out duration-300"
              >
                Book a Demo
              </Link>
            </div>
          </div>
        </div>
        <div className="max-sm:hidden flex justify-between items-center">
          <div>
            {session?.user ? (
              <Link to={"/signin"} className="mr-3">
                Welcome 👋 {session?.user?.name}{" "}
              </Link>
            ) : (
              <Link
                to={`/signin`}
                className="border border-1 py-2 px-3 rounded-full text-black  bg-white hover:bg-green-100 ease-in-out duration-300 max-sm:hidden mr-2"
              >
                Sign In
              </Link>
            )}
          </div>
          <div className="mr-2">
            <Link
              to={`/demo-request`}
              className="border border-1 py-2 px-3 rounded-full text-black  bg-white hover:bg-green-100 ease-in-out duration-300 max-sm:hidden"
            >
              Book a Demo
            </Link>
          </div>
          <div className="relative">
            <div
              className="py-2 inline-flex items-center cursor-pointer border rounded-full px-2"
              onClick={() => setHandleCurrency(!handleCurrency)}
            >
              <p to="">
                {currency?.code} {currency?.codeSym}{" "}
              </p>{" "}
              <p>
                {handleCurrency ? (
                  <MdArrowDropDown fontSize={25} />
                ) : (
                  <MdArrowDropUp fontSize={25} />
                )}
              </p>
            </div>
            <div
              className={`bg-[#e0e0e0] absolute border rounded border-[gray] w-full ${
                handleCurrency === false ? "hidden" : ""
              }`}
            >
              <div
                className="flex py-2 px-2 cursor-pointer hover:bg-[white] transition-all"
                onClick={() => {
                  setCurrency({ code: "USD", codeSym: "$" });
                  setHandleCurrency(false);
                }}
              >
                <span className="w-[50%]">USD</span>{" "}
                <span className="w-[50%]">$</span>
              </div>
              <div
                className="flex py-2 px-2 cursor-pointer hover:bg-[white] transition-all"
                onClick={() => {
                  setCurrency({ code: "CAD", codeSym: "C$" });
                  setHandleCurrency(false);
                }}
              >
                <span className="w-[50%]">CAD</span>{" "}
                <span className="w-[50%]">C$</span>
              </div>
              <div
                className="flex py-2 px-2 cursor-pointer hover:bg-[white] transition-all"
                onClick={() => {
                  setCurrency({ code: "INR", codeSym: "₹" });
                  setHandleCurrency(false);
                }}
              >
                <span className="w-[50%]">INR</span>{" "}
                <span className="w-[50%]">₹</span>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden">
          <GiHamburgerMenu
            className="text-[25px]"
            onClick={() => setHamberger(!hamberger)}
          />
        </div>
      </nav>
    </header>
  );
}
