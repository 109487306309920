import { useMutation } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";

const create = (postData) => {
  const URL = endpoint.user.connectLinkedin;
  return Http().post(URL, postData);
};

export const useLinkLinkedin = () => {
  return useMutation({
    mutationFn: create,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
};
