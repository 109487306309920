import React, { useState, useEffect } from "react";

const DocumentUploadLoader = () => {
  const [progress, setProgress] = useState(0);  // Initialize progress to 0
  const [uploading, setUploading] = useState(true);  // Start uploading on render
  const delay = 100;  // Interval in ms for progress increments

  // Progress effect to simulate the upload process
  useEffect(() => {
    let interval;

    if (uploading && progress < 100) {
      interval = setInterval(() => {
        setProgress((prevProgress) => Math.min(prevProgress + Math.random() * 5, 100));
      }, delay);
    }

    if (progress >= 100) {
      setUploading(false);  // Stop uploading once progress reaches 100%
    }

    return () => clearInterval(interval);  // Cleanup on component unmount
  }, [uploading, progress]);  // Dependencies to re-trigger effect

  return (
    <div className="max-w-md mx-auto p-6">
      <div className="w-full bg-gray-200 rounded-full h-6 mt-6 overflow-hidden">
        <div
          className="bg-gradient-to-r from-blue-400 via-purple-500 to-green-500 h-full transition-all duration-300"
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      <p className="text-center mt-4 text-gray-700">
        {uploading
          ? `Generating Results... ${Math.round(progress)}%`
          : "Leads Generated!"}
      </p>

      {uploading && (
        <div className="flex items-center justify-center mt-4">
          <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      )}
    </div>
  );
};

export default DocumentUploadLoader;
