import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const demoRequest = async (postdata) => {
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.requestdemo}`;
  return Http().post(URL, postdata);
};

export function useRequestDemo() {
    return useMutation({
        mutationFn: demoRequest,
        onSuccess: (data, variables, context) => {
         
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}
