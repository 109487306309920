const endpoint = {
    user : {
        register : '/manage-user-service/v1/register',
        googlesinguplogin: '/manage-user-service/v1/google-signup-login',
        verification : '/manage-user-service/v1/verifyaccount',
        signin : '/manage-user-service/v1/signin',
        profile : '/manage-user-service/v1/profile',
        user : '/manage-user-service/v1/user',
        changePassword : '/manage-user-service/v1/change-password',
        sendlinkpass : '/manage-user-service/v1/send-link-password',
        checkresetpasstoken : '/manage-user-service/v1/check-resetpassword-token',
        resetPassword : '/manage-user-service/v1/resetpassword',
        requestdemo : '/manage-user-service/v1/requestdemo',
        atsconnection : '/manage-user-service/v1/ats-connect',
        atsconnectionlist : '/manage-user-service/v1/ats-connect-list',
        towauthsignin : '/manage-user-service/v1/twoauthverification',
        header : '/manage-user-service/v1/header',
        linkedinAccount : '/manage-user-service/v1/get-linkedin-account',
        connectLinkedin : '/manage-user-service/v1/connect-linkedin-account'
    },
    team : {
       team : "/manage-user-service/v1/team",
    },
    product : {
        product : "/manage-user-service/v1/product",
    },
    plan : {
        plan : "/manage-user-service/v1/plan",
        plandetail : "/manage-user-service/v1/plandetail",
    },
    candidate : {
        candidate : '/manage-user-service/v1/candidate',
        getcandsource : '/manage-user-service/v1/get-source-candidate',
        campaignSourceCand : '/manage-user-service/v1/get-candsource-campaign',
    },
    job : {
        create : '/manage-user-service/v1/jobs',
        listing : '/manage-user-service/v1/jobs',
        jobdetail : '/manage-user-service/v1/jobdetail'
    },
    webhook : {
        ats_integration : '/manage-user-service/v1/ats-integration',
    },
    knit : {
        getorgdetail : '/manage-user-service/v1/get-organization',
        setorgdetail : '/manage-user-service/v1/set-organization',
        integrationlogsats : '/manage-user-service/v1/getallintegrationlogs',
        integrationlist : '/manage-user-service/v1/getallintegration',
    },
    sendmail : {
        sendmail : '/manage-user-service/v1/sendmail',
    },
    logout : {
        logout : '/manage-user-service/v1/logout',
    },
    campaign : {
        create : '/manage-user-service/v1/campaigns',
        single : '/manage-user-service/v1/campaigns/{campaignuid}',
        update : '/manage-user-service/v1/update-campaigns/{campaignid}',
        getall : '/manage-user-service/v1/campaigns',
        getlinkedincampaign :  '/manage-user-service/v1/get-linkedin-campaign/{campaignuid}',
        getchat : '/manage-user-service/v1/get-chat/{chatid}',
        updatechatid : '/manage-user-service/v1/update-chatid',
        runlinkedincampaign : '/manage-user-service/v1/runCampaign',
        downloadattachment : '/manage-user-service/v1/download-attachment',
        getleads :  '/manage-user-service/v1/get-all-leads'
    },
    libraries : {
        cities : '/manage-user-service/v1/cities',
        counties : '/manage-user-service/v1/countries',
        states : '/manage-user-service/v1/states',
    },
    report : {
        candidate : '/manage-user-service/v1/candidate-report',
        jobrep : '/manage-user-service/v1/job-report',
        counties : '/manage-user-service/v1/countries',
        states : '/manage-user-service/v1/states',
    },
    scrapping : {
        profile : '/manage-user-service/v1/candidate-report',
        jobrep : '/manage-user-service/v1/job-report',
        counties : '/manage-user-service/v1/countries',
        states : '/manage-user-service/v1/states',
    },
    googleSearch : {
        searchlink : '/manage-user-service/v1/profile-link'
    },
    subscribe : {
        subscribe : '/manage-comms-service/v1/subscribe'
    },
    parseContactDetail : {
        contactdetail : '/manage-user-service/v1/contact-detail'
    },
    parseJob:{
        links : '/manage-user-service/v1/parsejoblink',
        documentupload : '/manage-user-service/v1/upload-job',
        copypast : '/manage-user-service/v1/parse-job-copy-past',
    },
    notification:{
        count : '/manage-user-service/v1/notification-count',
        list : '/manage-user-service/v1/notification-list',
    },
    credentials : {
        apis : '/manage-user-service/v1/apis-credentails'
    },
    worklistaction : {
        worklist : '/manage-user-service/v1/worklist-create',
        applyWorklist : '/manage-user-service/v1/worklist-apply'
    }
}

export default endpoint;