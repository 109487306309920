import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SlMenu } from "react-icons/sl";
import { toast } from "react-toastify";
import { useTeamAdd } from "../../../../hooks/apihooks/useTeam";
import { Spinner } from "../../../Loader/Spinner";
import { Link, useParams } from "react-router-dom";
import UserLayout from "../../../../Layouts/Users/UserLayout";
import Token from "../../../../Helpers/Token";
import Http from "../../../../http";
import endpoint from "../../../../Helpers/endpoint";
import { useQuery } from "@tanstack/react-query";

const AddTeam = ({ title = "", action = null }) => {
  const { panelid } = useParams();
  const { mutateAsync, isPending } = useTeamAdd();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const getsinglepaneluser = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.team.team}/${panelid}`;
    return Http().get(URL, {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token.getAccessToken()}`,
    });
  };
  const { data: editdata } = useQuery({
    queryKey: ["getuserpanel"],
    queryFn: getsinglepaneluser,
    enabled: Boolean(Token.getAccessToken()),
  });

  const onSubmit = (data) => {
    mutateAsync({ data, token: Token.getAccessToken() }).then((resp) => {
      if (resp?.status === true) {
        toast.success(resp?.message);
        reset();
      } else if (resp?.status === false && resp?.error) {
        for (const [field, messages] of Object.entries(resp?.error)) {
          messages.forEach((message) => {
            toast.error(`${field}: ${message}`);
          });
        }
      }
    });
  };

  const onSubmitEdit = (data) => {
    console.log(data, "Edit submit data");
    mutateAsync({ data, token: Token.getAccessToken() }).then((resp) => {
      if (resp?.status === true) {
        toast.success(resp?.message);
        reset();
      } else if (resp?.status === false && resp?.error) {
        for (const [field, messages] of Object.entries(resp?.error)) {
          messages.forEach((message) => {
            toast.error(`${field}: ${message}`);
          });
        }
      }
    });
  };

  useEffect(() => {
    if (editdata?.data) {
      let newdata = {
        fname: editdata?.data?.fname,
        email: editdata?.data?.email,
        lname: editdata?.data?.lname,
        mobile: editdata?.data?.mobile,
      };
      reset(newdata);
    }
  }, [editdata, reset]);

  return (
    <UserLayout>
      {({ open, setOpen }) => (
        <>
          {isPending ? <Spinner size="medium" /> : ""}
          <div className="h-[100vh] w-full  bg-green-50 flex items-center flex-col">
            <div className="w-full fixed top-0 block sm:hidden">
              <div className="w-full flex items-center justify-between px-6 min-h-[80px]">
                <p className="font-robo text-[20px] text-green-900">{process.env.REACT_APP_APP_NAME}</p>
                {open ? (
                  ""
                ) : (
                  <SlMenu onClick={() => setOpen((v) => !v)} size={20} />
                )}
              </div>
            </div>
            <div className="w-11/12 max-sm:w-11/12 h-[auto] flex justify-center max-sm:mt-[50px]">
              <div className="bg-white w-[100%] mt-[30px] mb-[30px] rounded-[10px] px-3 py-5">
                <div className="flex justify-between items-center">
                  <h3>{title}</h3>
                  <Link
                    to={"/team"}
                    className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800"
                  >
                    <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                      Member List
                    </span>
                  </Link>
                </div>
                <div className="py-5">
                  <div className="relative overflow-x-auto py-4">
                    <form
                      onSubmit={handleSubmit(
                        editdata ? onSubmitEdit : onSubmit
                      )}
                      className="w-[80%] mx-auto"
                    >
                      <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 w-full mb-5 group">
                          <input
                            type="text"
                            {...register("fname", { required: true })}
                            name="fname"
                            id="fname"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="fname"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            <sup>*</sup>First name
                          </label>
                          {errors?.fname ? (
                            <p className="text-[red]">First name is required</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                          <input
                            type="text"
                            {...register("lname", { required: true })}
                            name="lname"
                            id="lname"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="lname"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            <sup>*</sup>Last name
                          </label>
                          {errors?.lname ? (
                            <p className="text-[red]">Last name is required</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative flex z-0 w-full mb-5 group">
                          <div className="flex w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                            <select className="m-[10px]">
                              <option>+91</option>
                              <option>+89</option>
                            </select>
                            <div className="w-full">
                              <input
                                type="tel"
                                {...register("mobile", { required: true })}
                                name="mobile"
                                id="mobile"
                                className="block w-full text-sm py-1.5 focus:outline-none focus:ring-0"
                                placeholder=" "
                              />
                            </div>
                          </div>
                          <label
                            htmlFor="mobile"
                            className="p-0 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            <sup>*</sup>Mobile number (Optional)
                          </label>
                          {errors?.mobile ? (
                            <p className="text-[red]">Mobile is required</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                          <input
                            type="email"
                            {...register("email", { required: true })}
                            name="email"
                            id="email"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="email"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            <sup>*</sup>Email address
                          </label>
                          {errors?.email ? (
                            <p className="text-[red]">Email is required</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="relative z-0 w-full mb-5 group">
                        <textarea
                          type="text"
                          {...register("comment", { required: true })}
                          name="comment"
                          id="comment"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="Comment"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          <sup>*</sup>Comment
                        </label>
                        {errors?.lname ? (
                          <p className="text-[red]">Last name is required</p>
                        ) : (
                          ""
                        )}
                      </div>
                      {editdata ? (
                        <button
                          type="submit"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Submit
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </UserLayout>
  );
};

export default AddTeam;
