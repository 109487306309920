import { useQuery } from "@tanstack/react-query";
import React, {useEffect, useState } from "react";
import {MdArrowBackIosNew, MdArrowForwardIos, MdContactPhone,MdLabelOutline,MdOutlineContactPhone,MdRefresh } from "react-icons/md";
import {FaStar, FaTimes, FaFileExcel, FaRegFileExcel, FaEye}  from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import {RiUserStarLine } from "react-icons/ri";
import { PiCoinDuotone } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import endpoint from "../../../Helpers/endpoint";
import Token from "../../../Helpers/Token";
import { useContactDetail } from "../../../hooks/apihooks/useContactDetailParse";
import Http from "../../../http";
import UserLayout from "../../../Layouts/Users/UserLayout";
import { Spinner } from "../../Loader/Spinner";
import Detailpopup from "./Detailpopup";
import SampleSource from "./Sourcing/SampleSource";
import useDebounce from "../../../Helpers/useDebounce";
import { FaList } from "react-icons/fa";
import { BsStars } from "react-icons/bs";
import NormalizeData from "./NormalizeData";
import AlertConfirm from "../../Alert/AlertComfirm";
import CreateToList from "./CreateToList";
import { MdDriveFileMoveOutline, MdKeyboardBackspace } from "react-icons/md";
import { useWorklistApply } from "../../../hooks/apihooks/useWorklist";
import Tooltip from "../../../Helpers/Tooltip";
import { CSVLink } from "react-csv";
export const CandList = () => {
  const {campainuid} = useParams();
  const [activeState, setActiveState] = useState(0);
  const [mousehover, setOnmouseHover] = useState(false);
  const [onactivePage, setOnActivePage] = useState(false);
  const [pagination, setPagination] = useState({
    page : 1,
    perPage : 10
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null); // to hold the promise resolution function
  const [message, setMessage] = useState({title : "", shortDesc : ""});
  const { mutateAsync, isPending } = useContactDetail();
  const {mutateAsync: applyToWorkMut, isPending : MutPending} = useWorklistApply();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [openNoramal,  setOpenNormal] = useState(false);
  const [createTolist,  setCreateTolist] = useState(false);
  const [activeaddtoList,  setactiveaddtoList] = useState(false);
  const [showhideworklist,  setworklistshowhide] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [datadetail, setdataOpen] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectworklist, setselectWorklistOps] = useState([]);
  const [worklistFilter, setworklistFilter] = useState([]);
  const debsearch = useDebounce(search, 500);
  // Function to show the confirmation dialog and hold execution
  const customConfirm = (title, shortDesc) => {
    setMessage({title, shortDesc});
    setIsDialogOpen(true);
    return new Promise((resolve, reject) => {
      setResolvePromise(() => resolve); // Store the resolve function to be called later
    });
  }

  // Handle when user clicks "Yes"
  const handleConfirm = () => {
    setIsDialogOpen(false);
    if (resolvePromise) {
      resolvePromise(true); // Resolve the promise with true (user confirmed)
    }
  };

  // Handle when user clicks "No"
  const handleCancel = () => {
    setIsDialogOpen(false);
    if (resolvePromise) {
      resolvePromise(false); // Resolve the promise with false (user canceled)
    }
  };

  const getlistofsource = () => {
      const URL = `${endpoint.candidate.getcandsource}?sourcecard=${campainuid}&page=${pagination.page}&perPage=${pagination.perPage}&search=${debsearch}&worklist=${worklistFilter.join(',')}`;
      return Http().get(URL, {
        Authorization: `Bearer ${Token.getAccessToken()}`,
      });
  };

  const {data: profiledata, isLoading: loadingprofile} = useQuery({queryKey:['getcandsourcelist', debsearch, pagination, campainuid, worklistFilter], queryFn: getlistofsource});

  const getContactDetail = async(profileLink) => {
    const result = await customConfirm("Get Contact Detail", "Are you sure you want to run to fetch the contact detail pls keep patience?");
    if(result){
      const postData = profiledata?.data?.filter((data) => profileLink.includes(data.id)).map((data) => data.profile_link);
      mutateAsync({postdata : {profileLink : postData}, token: Token.getAccessToken()}).then((resp) => {
        if (resp.status === true) {
          const message = `
                      <strong>Please Hold On!</strong><br />
                      Your request is being processed. This may take a few moments.<br />
                      Thank you for your patience!
                  `;
          toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />);
        }else if(resp.status === false){
          toast.error(resp?.error);
        }
      });
    }
  };

  const getRatingDetail = async(profileLink) => {
    const result = await customConfirm("Get Rating Detail", "Are you sure you want to fetch the rating details for this job? Please keep patience while the information is being retrieved.");
    if(result){
      const message = `
                  <strong>Please Hold On!</strong><br />
                  Your request rating  is being processed. This may take a few moments.<br />
                  Thank you for your patience!
              `;
      toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />);
    }
  };

  const handleCheckboxChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOptions((prevSelected) => {
      if (prevSelected.includes(value)) {
        // If already selected, remove it
        return prevSelected.filter((id) => id !== value);
      } else {
        // If not selected, add it
        return [...prevSelected, value];
      }
    });
  };

  const handleSelectAllChange = (event) => {

    if (event.target.checked) {
      // Select all
      setSelectedOptions(profiledata?.data?.map((option) => option.id));
    } else {
      // Deselect all
      setSelectedOptions([]);
    }
  };
  const allSelected = selectedOptions?.length;

  const getallworklist = () => {
    const URL = `${endpoint.worklistaction.worklist}`;
      return Http().get(URL, {
        Authorization: `Bearer ${Token.getAccessToken()}`,
      });
  }
  // user worklist
  const {data : worklist} = useQuery({queryKey: ['getallworklist'], queryFn:getallworklist});

  console.log("Worklist form DB:", worklist);

  const checkboxhandleWorklist = (event) => {
    const value =  parseInt(event?.target?.value);
    setselectWorklistOps((prevSelected) => {
      if (prevSelected.includes(value)) {
        // If already selected, remove it
        return prevSelected.filter((id) => id !== value);
      } else {
        // If not selected, add it
        return [...prevSelected, value];
      }
    });
  } 

  const handleWorlistFilter = (worklistid) => {
    const value =  parseInt(worklistid);
    setworklistFilter((prevSelected) => {
      if (prevSelected.includes(value)) {
        // If already selected, remove it
        return prevSelected.filter((id) => id !== value);
      } else {
        // If not selected, add it
        return [...prevSelected, value];
      }
    });
  }

  const applytoWorklist = () => {
    let payload = {
      selectworklist,
      selectedOptions
    }
    applyToWorkMut({payload, token : Token.getAccessToken()}).then((resp) => {
      if(resp?.status===true){
        toast.success(resp?.data);
        setselectWorklistOps([]);
        setSelectedOptions([]);
        setactiveaddtoList(false);
      }
    });
  }

  let sliderID = profiledata?.data?.map((data) => data?.id);


  const handleOpenPopupDetail = (item) => {
    const index = sliderID.indexOf(item?.id);
    setActiveState(index);
    setOpenDetail(true);
    setdataOpen(item);
  }

  const nextSlide = () => {
    if(sliderID.length > activeState+1){
      setActiveState(activeState+1);
      let sliderid = sliderID[activeState+1];
      let items = profiledata?.data?.filter((data) => data?.id===sliderid);
      setdataOpen(items[0]);
    }
  }

  const prevSlide = () => {
    if(0 <= activeState-1){
      setActiveState(activeState-1);
      let sliderid = sliderID[activeState-1];
      let items = profiledata?.data?.filter((data) => data?.id===sliderid);
      setdataOpen(items[0]);
    }
  }

  const selecworlistFun = (data) => {
    if(selectedOptions.includes(data?.id)){
      return data?.worklist_id?.map((data) => data?.id).join(',');
    }
  }

  let allListData = profiledata?.data?.map(selecworlistFun);


  useEffect(() => {
    
    if(selectedOptions?.length > 0){
      const numbers = allListData?.filter(item => item !== null)?.map(item => item?.split(','))?.flat(); 
      const uniqueNumbers = [...new Set(numbers?.map(Number))];
      if(uniqueNumbers.length>0){
        let filteredArray = uniqueNumbers.filter(value => !isNaN(value) && value!==0);
        console.log(filteredArray, "check Filter");
        setselectWorklistOps(filteredArray);
      }
    }
    // eslint-disable-next-line
  }, [selectedOptions])

  console.log("Select Checkbox", selectworklist);
  return (
    <UserLayout>
      {isPending || MutPending? <Spinner size="medium" /> : ""}
      <Detailpopup
        openDetail={openDetail}
        setOpenDetail={setOpenDetail}
        data={datadetail}
        nextSlide={nextSlide}
        countslide={activeState}
        sliderID={sliderID}
        prevSlide={prevSlide}
        campaign={'yes'}
      />
      <CreateToList
        createTolist={createTolist}
        sourcedataid={selectedOptions}
        setCreateTolist={setCreateTolist}
      />
      <AlertConfirm title={message.title}  shortDesc={message.shortDesc} isDialogOpen={isDialogOpen} onConfirm={handleConfirm}
          onCancel={handleCancel} />
      <div
        className={`w-[100%] px-10 pt-10 bg-green-50 relative flex items-center`}
      >
        <div className="border-b border-gray-200 dark:border-gray-700 pb-10 w-full">
          <div className="border rounded-t-[10px]">
            <div className="bg-white rounded-t-[10px] py-3 border-b w-[100%] flex items-center justify-between">
              <Link className="pl-2" to={"/source-public-candidate"}><Tooltip message={'Back'}><MdKeyboardBackspace size={23} /></Tooltip></Link>
              <div className="flex items-center">
                <Tooltip message="Search candidate more">
                  <button
                    className="text-white flex items-center bg-green-600 text-[14px] px-4 py-1 rounded-[5px] mr-3"
                    onClick={() => setOpen(true)}
                  >
                    <IoSearch className="mr-1" /> Candidates
                  </button>
                </Tooltip>
                
                <Tooltip message="Download CSV">
                  <CSVLink data={profiledata?.data ? profiledata?.data : ""} onMouseEnter={() => setOnmouseHover(true)} onMouseLeave={() => setOnmouseHover(false)}  className="block mr-4" > 
                    {mousehover? <FaFileExcel className="text-green-600" size={22} /> : <FaRegFileExcel className="" size={22} />}
                  </CSVLink>
                  </Tooltip>
                <Tooltip message="Normalization data using chat GPT">
                <button
                  className="text-[14px] aibutton-animation text-[black] bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm text-center  flex px-4 py-1 items-center mr-3"
                  onClick={() => setOpenNormal(true)}
                >
                  <BsStars /> &nbsp; Normalize
                </button>
                </Tooltip>
                <div className="px-2 mr-2"><input type="search" onChange={(e) => setSearch(e.target.value)} placeholder="search " className="border outline-none py-1 px-2 rounded" /> </div>
              </div>
            </div>
            <div className="bg-white px-2 w-[100%] flex items-center justify-between">
              <div className="flex items-center">
                <div class="flex items-center h-5">
                  <input
                    type="checkbox"
                    checked={
                      allSelected === profiledata?.data?.length ? true : false
                    }
                    class="w-4 h-4 text-blue-600 bg-green-100 mr-6 border-green-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-green-600"
                    onChange={handleSelectAllChange}
                  />
                </div>
                {allSelected > 0 ? (
                  <>
                    <Tooltip message="Fetch Contact" top>
                    <button
                        className="text-[14px] mr-4 py-1 w-[40px] h-[40px]  flex justify-center items-center rounded-full hover:bg-gray-200"
                        onClick={() => getContactDetail(selectedOptions)}
                      >
                        <MdOutlineContactPhone className="text-center" size={20} />
                    </button>
                    </Tooltip>
                    
                    <div className="relative">
                      <Tooltip message="Add to worklist">
                        <button className="text-[14px] mr-4 py-1 w-[40px] h-[40px]  flex justify-center items-center rounded-full hover:bg-gray-200"  onClick={()=>setactiveaddtoList(!activeaddtoList)}>
                          <MdLabelOutline size={25} />
                        </button>
                     </Tooltip>
                      <div className={`absolute ${!activeaddtoList?'hidden':''}  border z-[9] w-[200px] bg-gray-50 shadow rounded`}>
                          <ul className=""> 
                            {worklist?.data?.map((vale) => {
                              return <li className="hover:bg-gray-300 px-3 py-1 cursor-pointer"><input type="checkbox" checked={selectworklist.includes(vale?.id)}  name="worklist" onChange={(e) => checkboxhandleWorklist(e)} value={vale?.id} /> <span> {vale?.label}</span></li>  
                            })}
                          </ul>  
                            {console.log("worklist", worklist, selectworklist.length)}
                          <div className="border-t">
                            {selectworklist.length >0 ? 
                              <button className="w-full pl-7 py-1 text-left hover:bg-gray-300 text-blue-500 border-b" onClick={() => applytoWorklist()}>Apply</button>
                            :
                            <>
                              <button onClick={() => setCreateTolist(true)} className="w-full pl-7 py-1 text-left hover:bg-gray-300 text-blue-500 border-b">Create</button> 
                              <button onClick={() => setCreateTolist(true)} className="w-full pl-7 py-1 text-left hover:bg-gray-300 text-blue-500 ">Manage</button> 
                            </>
                            }
                          </div>
                      </div>  
                    </div>
                    <Tooltip message="Fetch Rating">
                    <button
                      className="text-[14px] mr-4 py-1 w-[40px] h-[40px]  flex justify-center items-center rounded-full hover:bg-gray-200"
                      onClick={() => getRatingDetail(selectedOptions)}
                      >
                      <RiUserStarLine size={18} />
                    </button>
                    </Tooltip>
                    <Tooltip message="Move worklist">
                    <button
                      className="text-[14px] mr-4 py-1 w-[40px] h-[40px]  flex justify-center items-center rounded-full hover:bg-gray-200"
                      >
                      <MdDriveFileMoveOutline size={20} />
                    </button>
                    </Tooltip>
                    <span className="border-l w-1 h-5"></span>
                  </>
                ) : (
                  ""
                )}
                <Tooltip message="Worklist">
                <span
                  className="flex relative text-whitetext-[14px]  items-center mx-3 py-1 rounded-[5px]" onClick={() => setworklistshowhide(!showhideworklist)}
                  >
                  <button className="text-[14px] mr-4 py-1 w-[40px] h-[40px]  flex justify-center items-center rounded-full hover:bg-gray-200"><FaList /> </button>
                  
                </span>
                </Tooltip>
                {
                  showhideworklist ? <div className="flex items-center">
                    {worklist?.data?.map((vale) => {
                      return <span className={`${worklistFilter?.includes(vale?.id)? 'bg-green-600 text-white ' :''} font-semibold border text-[13px] cursor-pointer active:bg-green-600 mr-2 rounded px-1 py-1 bg-[#e0e0e0] flex`} onClick={()=>handleWorlistFilter(vale?.id)}> {vale?.label} { worklistFilter?.includes(vale?.id)? <FaTimes className="ml-1"/> : ""}</span>
                    })}
                    <Tooltip message="Refresh worklist">
                    <span className="cursor-pointer" onClick={() => setworklistFilter([])}><MdRefresh  size={20}/></span>
                    </Tooltip>
                  </div> :""
                }
              </div>
              <div className="flex items-center relative">
              <Tooltip message="Set Pagination">
                <button className="hover:bg-gray-200 px-2 rounded mr-2" onClick={() => setOnActivePage(!onactivePage)}>
                  <span>{pagination?.page +`-`+ pagination?.perPage} of {profiledata?.total_items}</span>
                </button>
                {
                  onactivePage ? 
                  <div className={`absolute ${!onactivePage?'hidden':''} right-0  border z-[9] w-[250px] bg-gray-50 shadow rounded`}>
                    <div className="px-3 py-1 border-b text-[16px]">Show More candidates</div>
                    <div className="px-3 py-1">Show upto</div>
                    <ul className=""> 
                      <li className={`hover:bg-gray-300 px-3 py-1 cursor-pointer ${pagination.perPage===5?'bg-gray-300':''}`} onClick={() => setPagination(prev => ({...prev, perPage: 5, page : 1}))}>5 items</li>  
                      <li className={`hover:bg-gray-300 px-3 py-1 cursor-pointer ${pagination.perPage===10?'bg-gray-300':''}`} onClick={() => setPagination(prev => ({...prev, perPage: 10, page : 1}))}>10 items</li>  
                      <li className={`hover:bg-gray-300 px-3 py-1 cursor-pointer ${pagination.perPage===20?'bg-gray-300':''}`} onClick={() => setPagination(prev => ({...prev, perPage: 20, page : 1}))}>20 items</li>  
                      <li className={`hover:bg-gray-300 px-3 py-1 cursor-pointer ${pagination.perPage===25?'bg-gray-300':''}`} onClick={() => setPagination(prev => ({...prev, perPage: 25, page : 1}))}>25 items</li>
                      <li className={`hover:bg-gray-300 px-3 py-1 cursor-pointer ${pagination.perPage===50?'bg-gray-300':''}`} onClick={() => setPagination(prev => ({...prev, perPage: 50, page : 1}))}>50 items</li>
                    </ul>  
                  </div>  : ""
                }
                
              </Tooltip>
              {console.log("pagination", pagination)}
              <button className="flex">
                  <MdArrowBackIosNew  size={22} onClick={() => setPagination(prev => ({
                    ...prev,
                    page: 1 <= pagination.page-1 ? pagination.page - 1 : 1,
                  }))} className={`${pagination.page === 1? 'text-gray-300':'text-[black]'} mr-2  cursor-pointer p-1 rounded`} />
                                <MdArrowForwardIos disabled={pagination.page === profiledata?.total_pages} size={22} onClick={() => setPagination(prev => ({
                    ...prev,
                    page: profiledata?.total_pages >= pagination.page+1 ? pagination.page + 1 : profiledata?.total_pages,
                  }))} className={`${pagination.page === profiledata?.total_pages ? 'text-gray-300':'text-[black]'} cursor-pointer p-1 rounded`}  />
              </button>
              </div>
            </div>
          </div>
          <div className="bg-white overflow-scroll h-[80vh] w-[100%] flex justify-between">
            <table className="text-left text-nowrap  min-w-full border border-green-300">
              <thead className="bg-white border-b sticky top-0">
                <tr className="bg-green-200 text-gray-600 uppercase text-sm">
                  <th className="px-3 border-b">
                    {/* <input
                      type="checkbox"
                      checked={
                        allSelected === profiledata?.data?.length ? true : false
                      }
                      class="w-4 h-4 text-blue-600 bg-green-100 border-green-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-green-600"
                      onChange={handleSelectAllChange}
                    /> */}
                  </th>
                  <th className="py-3 px-4 border-b">Profile</th>
                  <th className="py-3 px-4 border-b">Name</th>
                  <th className="py-3 px-4 border-b">skills</th>
                  <th className="py-3 px-4 border-b text-wrap">Headline</th>
                  <th className="py-3 px-4 border-b">Contact</th>
                  <th className="py-3 px-4 border-b">City</th>
                  <th className="py-3 px-4 border-b">Action</th>
                </tr>
              </thead>
              <tbody className="mt-[80px]">
                {profiledata?.data?.map((item, index) => {
                  const stars = item?.overall_rating? Array(item?.overall_rating).fill(<FaStar className="text-[orange]" />) : "";
                  return (
                    <tr
                      key={index}
                      className={`${selectedOptions.includes(item.id)? 'bg-green-100':''} hover:bg-green-100 transition-colors duration-200  border-b hover:shadow-lg cursor-pointer`}
                    >
                      <td className="px-3">
                        <div class="flex items-center h-5">
                          <input
                            id="helper-checkbox"
                            type="checkbox" 
                            value={item.id}
                            checked={selectedOptions.includes(item.id)}
                            onChange={handleCheckboxChange}
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                      </td>
                      <td className="py-3 px-4 border-b">
                        <Link
                          className="text-blue-600"
                          rel="noopener noreferrer"
                        >
                          <div className="flex items-center">
                            {item?.profile_pic?  <img
                              onClick={() => handleOpenPopupDetail(item)}
                              className="shadow-lg rounded-full cursor-pointer w-[50px] h-[50px]"
                              src={item?.profile_pic}
                              alt=" "
                            /> : <div  onClick={() => handleOpenPopupDetail(item)} className="shadow-lg rounded-full cursor-pointer w-[50px] h-[50px] text-center leading-[50px] font-bold bg-green-600 text-white"><span>{item?.first_name?.substring(0,1)}</span></div>}
                           
                            <div className="flex flex-wrap  w-[100px]">
                              <div className="flex ml-2 mb-[5px]">
                                {stars}
                              </div>
                            <div className="w-full mb-[-30px] flex items-center ml-2" >
                              {item?.worklist_id?.map((value) => {
                                return <span className="text-[10px] bg-gray-200 text-[black] rounded mx-[2px] px-[3px] py-[2px] flex items-center justify-between"><span className="mr-[3px]">{value?.label}</span> <FaTimes /></span>
                              })}
                            </div>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="py-3 px-4 border-b">{item?.first_name.length > 20 
                          ? item?.first_name.substring(0, 20) + "..."
                          : item?.first_name} {item?.last_name.length > 20
                            ? item?.last_name.substring(0, 20) + "..."
                            : item?.last_name}</td>
                      <td className="py-3 px-4 border-b">
                        {item?.skills?.length > 40
                          ? item?.skills?.substring(0, 40) + "..."
                          : item?.skills}
                      </td>
                      <td className="py-3 px-4 border-b">
                        {item?.headline}
                      </td>
                      <td className="py-3 px-4 border-b">{JSON.stringify(item?.contactdata?.length)}</td>
                      <td className="py-3 px-4 border-b">{item?.city},{item?.country}</td>
                      <td className="py-3 px-4 border-b">
                        <span className="px-1 py-1 border inline-block rounded hover:bg-gray-200 hover:text-blue-600" onClick={() => handleOpenPopupDetail(item)}><FaEye /></span>
                      </td>
                    </tr>
                  );
                })}
                {loadingprofile? <tr><td colSpan={10} className="font-semibold text-[20px] text-center">Loading...</td></tr>:''}
              </tbody>
            </table>
          </div>
          <SampleSource open={open} setOpen={setOpen} data={profiledata?.campaign} campaign={'no'}/>
          <NormalizeData
            openNoramal={openNoramal}
            setOpenNormal={setOpenNormal}
          />
        </div>
      </div>
    </UserLayout>
  );
};
