import React, { useState } from "react";
import { useRunLinkedinCampaign } from "../../../../hooks/apihooks/Campaigns/useRunLinkedinCampaign";
import Loader from "../../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Campaign() {
  const [value, setValue] = useState(10);
  const [campaigntitle, setCampaignTitle] = useState("");
  let navigate = useNavigate();
  const [message, setMessage] = useState(
    `Hi`
  );
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const placeholderhandler = (e) => {
    setMessage((prevMessage) => prevMessage + "" + e);
  };

  const onplaceholderChange = (e) => {
    setMessage(e.target.value);
  };

  const { mutateAsync, isPending } = useRunLinkedinCampaign();

  const onSubmit = () => {
    let postData = {
      campaigntitle: campaigntitle,
      message: message,
      account_id: "yjrXq_zSRWC5KDhq-gD4fQ",
    };
    mutateAsync(postData).then((res) => {
      console.log(res);
      if (res?.status) {
        // navigate(`/campaigns-external/${res?.campaign?.campaignuid}`)
        navigate("/campaigns-list");
      } else {
        toast.warn("Something went wrong");
      }
    });
  };

  return (
    <div className="w-full bg-green-100 min-h-[100vh]  py-10 flex items-center justify-start flex-col gap-3">
      <div className="flex items-center justify-between w-11/12">
        <p className="text-[18px] font-semibold">Create Campaign </p>
      </div>
      {isPending ? <Loader /> : ""}

      <div className="flex gap-4 items-center justify-center flex-col w-11/12 px-3 py-6 bg-white border border-gray-300 rounded-md">
        <div className="w-full flex items-center justify-between gap-1">
          <p className="font-semibold">
            {" "}
            Set Campaign parameters to run your campaign
          </p>
          <button
            className="px-4 py-1 bg-green-600 text-white rounded-md"
            type="button"
            onClick={onSubmit}
          >
            Save & Continue
          </button>
        </div>
        <div className="w-full p-3 border rounded-[5px] border-gray-300 flex items-center justify-between gap-1">
          <label className="w-full flex items-start justify-center flex-col gap-2">
            <p className="text-[14px] font-medium">Campaign Name:</p>
            <input
              placeholder="Senior Java Developer"
              value={campaigntitle}
              onChange={(e) => setCampaignTitle(e.target.value)}
              className="w-full h-10 p-2 rounded-[4px] border border-gray-300"
            />
            <p className="ml-auto text-[12px]">Characters 10/100</p>
          </label>
        </div>
        <div className="w-full p-3 border rounded-[5px] border-gray-300 flex items-start justify-between gap-4">
          <div className="w-6/12">
            <p className="text-[14px] font-medium">Campaign Limits</p>
            <p className="text-[10px]">
              Specify the daily limit for this campaign. These limits will be
              applied to reach out to your leads.
            </p>
          </div>
          <div className="w-6/12 flex items-center justify-center flex-col gap-2 ">
            <div className="w-full flex items-start justify-center flex-col gap-1">
              <p className="text-[14px] font-medium">
                Set the number of new people to be connected by this campaign
                daily:
              </p>
              <div className="flex flex-col items-center space-y-4 w-full mt-2">
                <div className="flex items-center space-x-2 w-full">
                  <span className="text-[12px]">0</span>
                  <div className="relative w-full">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={value}
                      onChange={handleChange}
                      className="w-full h-2 appearance-none rounded-full cursor-pointer"
                      style={{
                        background: `linear-gradient(to right, #3b82f6 ${value}%, #d1d5db ${value}%)`,
                      }}
                    />
                    <div
                      className="absolute  -top-1 left-0 transform -translate-x-1/2 -translate-y-1/2 text-black  text-[10px]"
                      style={{
                        left: `${value}%`,
                      }}
                    >
                      {value}
                    </div>
                  </div>
                  <span className="text-[12px]">100</span>
                </div>
              </div>
            </div>
            <div className="w-full flex items-start justify-center flex-col gap-1">
              <p className="text-[14px] font-medium">
                Set the number of follow up messages to be sent from this
                campaign daily:
              </p>
              <div className="flex flex-col items-center space-y-4 w-full mt-2">
                <div className="flex items-center space-x-2 w-full">
                  <span className="text-[12px]">0</span>
                  <div className="relative w-full">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={value}
                      onChange={handleChange}
                      className="w-full h-2 appearance-none rounded-full cursor-pointer"
                      style={{
                        background: `linear-gradient(to right, #3b82f6 ${value}%, #d1d5db ${value}%)`,
                      }}
                    />
                    <div
                      className="absolute  -top-1 left-0 transform -translate-x-1/2 -translate-y-1/2 text-black  text-[10px]"
                      style={{
                        left: `${value}%`,
                      }}
                    >
                      {value}
                    </div>
                  </div>
                  <span className="text-[12px]">100</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full p-3 border rounded-[5px] border-gray-300 flex items-start justify-between gap-4">
          <div className="w-6/12 flex items-start justify-center flex-col">
            <p className="text-[14px] font-medium">Schedule campaign</p>
            <p className="text-[12px]">
              You can schedule when campaign will be active. just set date and
              time. Once the campaign is set to active,messages will start begin
              sent as soon as the associated LinkedIn profile has entered its
              active hours
            </p>
          </div>
          <div className="w-3/12 flex items-start justify-center flex-col gap-1 ml-3">
            <p className="text-[14px] font-medium">Select date and time</p>
            <input type="datetime-local" className="text-[12px] border-none" />
          </div>
          <div className="w-3/12 flex items-center justify-end gap-2">
            <p className="text-[14px] font-medium">Start immediately</p>
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleToggle}
                  className="sr-only"
                />
                <div
                  className={`block w-10 h-5 rounded-full ${
                    isChecked ? "bg-green-600" : "bg-gray-300"
                  }`}
                ></div>
                <div
                  className={`dot absolute left-1 top-0.5 bg-white w-4 h-4 rounded-full transition ${
                    isChecked ? "transform translate-x-[18px]" : ""
                  }`}
                ></div>
              </div>
            </label>
          </div>
        </div>
        <div className="w-full p-3 border rounded-[5px] border-gray-300 flex items-start justify-between gap-4">
          <div className="w-6/12 flex items-start justify-center flex-col ">
            <p className="text-[14px] font-medium">Invitation message</p>
            <p className="text-[12px]">
              Create and modify the messages sent throughout the different steps
              of your campaign. Upon making changes, all campaign activity will
              be paused. Note that messages left with empty bodies will simply
              be ignored. The only exception to that is leaving the first
              message of a connector campaign blank. This will lead to a
              connection request being requested without a connector message
            </p>
          </div>
          <div className="w-6/12 flex items-start justify-center flex-col gap-2">
            <p className="text-[14px] font-medium">Body</p>
            <textarea
              rows={5}
              className="w-full border text-[14px] border-gray-300 rounded-[5px] p-3"
              value={message}
              onChange={onplaceholderChange}
            />
            <div className="w-full items-center justify-start gap-1 flex-row flex-wrap flex">
              <p
                onClick={() => placeholderhandler(`{first_name}`)}
                className="px-3 py-1 text-[12px] cursor-pointer rounded-full bg-gray-300"
              >{`{first_name}`}</p>
              <p
                onClick={() => placeholderhandler(`{last_name}`)}
                className="px-3 py-1 text-[12px] cursor-pointer rounded-full bg-gray-300"
              >{`{last_name}`}</p>
              <p
                onClick={() => placeholderhandler(`{company_name}`)}
                className="px-3 py-1 text-[12px] cursor-pointer rounded-full bg-gray-300"
              >{`{company_name}`}</p>
              <p
                onClick={() => placeholderhandler(`{job_title}`)}
                className="px-3 py-1 text-[12px] cursor-pointer rounded-full bg-gray-300"
              >{`{job_title}`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
