import React from "react";
import UserLayout from "../../../Layouts/Users/UserLayout";
import AccountsLayout from "./AccountsLayout";

export default function Accounts() {
  return (
    <UserLayout>
      {({ open, setOpen, header }) => (
        <AccountsLayout open={open} setOpen={setOpen} header={header} />
      )}
    </UserLayout>
  );
}
