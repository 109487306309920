import { MdCloudUpload } from "react-icons/md";
export default function ImportCandidate({ open, setOpen, setManualCand }) {
  return (
    <div
      className={`relative z-10 ${open ? `` : `hidden tansition-all`}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[600px]">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
              <div className="sm:flex sm:items-start w-full">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                  <h3
                    className="text-[18px] font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Import Candidates
                  </h3>
                  <div className="mt-2">
                    <p className="text-[12px] text-gray-500">
                      Upload a CSV/PDF/DOC to import candidates data in you
                      candidate bank
                    </p>
                  </div>
                  <div className="flex items-center justify-center w-full mt-3">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 hover:border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-300 dark:bg-gray-200 hover:bg-gray-100  "
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <MdCloudUpload size={50} className="text-gray-500" />
                        <p className="mb-2 text-sm  dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          PDF, CSV or DOC (MAX 5 MB)
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                  <div className="w-full text-center mt-5 mb-3 text-[14px] flex items-center justify-center flex-row">
                    <p className="pt-[1px] bg-gray-400 w-full"></p>
                    <p>OR</p>
                    <p className="pt-[1px] bg-gray-400 w-full"></p>
                  </div>
                  <div className="w-full flex items-start justify-center flex-col gap-2">
                    <p className="text-[13px] font-medium">Upload from URL</p>
                    <input
                      placeholder="https://database.example.com/candidates"
                      className="w-full text-[12px] border border-gray-300 rounded-md p-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => setOpen(false)}
              >
                Discard
              </button>
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-green-700 mr-3 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
              >
                Import
              </button>
              <button
                type="button"
                onClick={() => setManualCand(true)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Manual
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
