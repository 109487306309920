
import React from "react";
import UserLayout from "../../../Layouts/Users/UserLayout";

const SubscriptionDetail = () => {
  const subscription = {
    plan: "Unlimited Candidate Source from LinkedIn & Conversations",
    renewalDate: "2024-12-01",
    price: 149.99,
    benefits: [
      "Unlimited LinkedIn Candidate Sourcing",
      "Direct InMail Access to LinkedIn Candidates",
      "Enhanced Search Filters for LinkedIn",
      "Automated Candidate Outreach and Follow-ups",
      "Unlimited Candidate Conversations via Messaging",
      "Real-time Notifications for Candidate Activity",
      "Priority Support and Dedicated Account Manager",
      "Access to Advanced Analytics and Reports",
    ],
    user: {
      name: "Jane Smith",
      email: "jane.smith@example.com",
    },
  };

  return (
    <UserLayout>
       <div className="w-[95%] min-h-screen py-10">
        <div className="w-full mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Header Section */}
          <div className="p-6 border-b border-gray-200">
            <h1 className="text-2xl font-semibold text-gray-800">Subscription Details</h1>
          </div>

          <div className="p-6">
            {/* User Info */}
            <div className="mb-6">
              <h2 className="text-xl font-semibold text-gray-700">User Information</h2>
              <p className="text-gray-500">Name: {subscription.user.name}</p>
              <p className="text-gray-500">Email: {subscription.user.email}</p>
            </div>

            {/* Plan Info */}
            <div className="mb-6">
              <h2 className="text-xl font-semibold text-gray-700">Subscription Plan</h2>
              <div className="mt-2">
                <p className="text-gray-500">Plan: {subscription.plan}</p>
                <p className="text-gray-500">Renewal Date: {subscription.renewalDate}</p>
                <p className="text-gray-500">Price: ${subscription.price.toFixed(2)} / month</p>
              </div>
            </div>

            {/* Benefits */}
            <div className="mb-6">
              <h2 className="text-xl font-semibold text-gray-700">Plan Benefits</h2>
              <ul className="mt-2 space-y-2 text-gray-500">
                {subscription.benefits.map((benefit, index) => (
                  <li key={index} className="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-green-500 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-between items-center mt-8">
              <button className="bg-red-200 text-red-700 px-4 py-2 rounded-md border border-red-300 hover:bg-red-300">
                Cancel Subscription
              </button>
              <button className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700">
                Update Payment Info
              </button>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default SubscriptionDetail;
