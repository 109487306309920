const NotificationSound = (logo, datanew) => {
    const audio = new Audio('https://dev.hiringplug.com/notificacion.mp3');
     //  audio.play();
     audio.oncanplaythrough = (event) => {
       var playedPromise = audio.play();
       if (playedPromise) {
           playedPromise.catch((e) => {
               console.log(e)
               if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                   console.log(e.name);
               }
           }).then(() => {

           });
       }
     }
     var notification = new Notification(datanew?.data?.subject, {
         icon: logo,
         body: datanew?.data?.message
     });
     notification.onclick = function() {
       window.location.assign(process.env.REACT_APP_BASE_URL + '/notifications');
     };
};

export default NotificationSound;