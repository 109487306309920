import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const atsconnection = ({ data, token }) => {
  let headers = {
      Authorization: `Bearer ${token}`
  };
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.atsconnection}`;
  return Http().post(URL, data, headers);
};

const setOrgdetail = ({ data, token }) => {
  let headers = {
      Authorization: `Bearer ${token}`,
  };
  const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.knit.setorgdetail}`;
  return Http().post(URL, data, headers);
};



export const useAtsConnect = () => {
  return useMutation({
    mutationFn: atsconnection,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
};

export const useSetOrg = () => {
  return useMutation({
    mutationFn: setOrgdetail,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
};
