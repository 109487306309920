import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaCamera, FaPlus, FaTrash } from "react-icons/fa";
import Select, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { Spinner } from "../Components/Loader/Spinner";
import Token from "../Helpers/Token";
import useDebounce from "../Helpers/useDebounce";
import { customStyles, ErrorStyles } from "../Helpers/Utils";
import { useCandidate } from "../hooks/apihooks/useCandidate";
import CountryCodes, {
  CountryOptions,
  currencyCode,
} from "../hooks/CountryCodes";
import Http from "../http";

export default function AddManualCandidate({ manualCand, setManualCand }) {
  const {
    register,
    reset,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // const [image, setImage] = useState(null);  never used giving warning
  const [images, setImages] = useState([]);
  const { mutateAsync: addCandidate, isPending } = useCandidate();
  const [file, setFile] = useState(null);
  const [imageSelected, setImageSelected] = useState([]);
  const [AddInput, setAddInput] = useState("");

  const [enabled, setEnabled] = useState({
    department: false,
    city: false,
    skills: false,
  });

  const [countrySearch, setCountrySearch] = useState("");
  const debounceSkillsSearch = useDebounce(AddInput, 500);

  const [Experience, setExperience] = useState([
    {
      employer: "",
      jobprofile: "",
      city: { value: 0, label: "Location" },
      jobperiod: "",
    },
  ]);
  const getskills = (skill) => {
    let url = `http://hiringplug.local/manage-helpdesk-service/v1/skills?keyword=${skill}`;
    return Http().get(url);
  };

  const getCity = (city) => {
    let url = `http://hiringplug.local/manage-helpdesk-service/v1/city?keyword=${city}`;
    return Http().get(url);
  };

  const { data: SkillsData, isLoading: SkillsLoading } = useQuery({
    queryKey: ["getallskilss", debounceSkillsSearch],
    queryFn: () => getskills(debounceSkillsSearch),
  });
  const { data: dataArr, isLoading } = useQuery({
    queryKey: ["getallcity", countrySearch],
    queryFn: () => getCity(countrySearch),
    enabled: Boolean(enabled.city),
  });

  const SkillsOptions = SkillsData?.data.map((skill) => ({
    value: skill.skillid,
    label: skill.skill,
  }));

  const CurrencyOptions = currencyCode?.map((lists) => ({
    value: lists.currencyid,
    label: `${lists.code}-${lists.symbol}`,
  }));

  const handleAdditionalSkillsInput = (e) => {
    setAddInput(e);
  };

  const LocationOptions = dataArr?.data.map((city) => ({
    value: city.cityid,
    label: city.city,
  }));

  const onSubmit = (postdata) => {
    let data = { ...postdata, resume: file, experience: Experience };
    data.status = data.status === 1 ? "active" : "deactive";
    delete data.active;
    addCandidate({ data, token: Token.getAccessToken() }).then((resp) => {
      if (resp?.status === true) {
        setManualCand(false);
        reset();
        toast.success(resp?.message);
      }
    });
  };

  const handleOnImageClick = () => {
    document.getElementById("image-input-box").click();
  };

  const handleImageChange = useCallback(
    (e) => {
      // setImage(e.target.files[0]);

      let fileList = Array.from(e.target.files);
      // File Reader for Each file and and update state arrays
      fileList.forEach((files, i) => {
        let reader = new FileReader();

        reader.onloadend = () => {
          setImages((prevImages) => [...prevImages, reader.result]);
        };

        reader.readAsDataURL(files);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images, file]
  );

  const handleAddExperience = () => {
    setExperience([
      ...Experience,
      {
        employer: "",
        jobprofile: "",
        city: { value: 0, label: "Location" },
        jobperiod: "",
      },
    ]);
  };

  const handleExLocation = (e, action, index) => {
    const { value, label } = e;
    const { name } = action;
    const list = [...Experience];
    list[index][name] = { value: value, label: label };
    setExperience(list);
  };

  const AMAZONS3 =
    "https://hp-in-web-prod.s3.ap-south-1.amazonaws.com/public/my-assets";
  const FormAssets = [
    { head: "Age diversity", img_src: `${AMAZONS3}/form-assets/div-age.jpeg` },
    {
      head: "Career break",
      img_src: `${AMAZONS3}/form-assets/div-career-break.jpeg`,
    },
    {
      head: "Cultural diversity",
      img_src: `${AMAZONS3}/form-assets/div-cultural.jpeg`,
    },
    {
      head: "LGBTQI diversity",
      img_src: `${AMAZONS3}/form-assets/div-LGBTQI.jpeg`,
    },
    {
      head: "Differently-abled",
      img_src: `${AMAZONS3}/form-assets/div-diffable.jpeg`,
    },
    {
      head: "Gender diversity",
      img_src: `${AMAZONS3}/form-assets/div-gender.jpeg`,
    },
    {
      head: "International",
      img_src: `${AMAZONS3}/form-assets/div-international.jpeg`,
    },
    {
      head: "Religious diversity",
      img_src: `${AMAZONS3}/form-assets/div-religion.jpeg`,
    },
  ];

  const Genders = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "LGBTQI", label: "LGBTQI" },
    { value: "Undisclosed", label: "Undisclosed / Prefer not to say" },
  ];

  function GET_All_DEPARTMENT() {
    const newEndpoints =
      "http://hiringplug.local/manage-helpdesk-service/v1/department";
    return Http().get(newEndpoints);
  }

  const { data, isLoading: isFetchingDepartment } = useQuery({
    queryKey: ["Departments"],
    queryFn: () => GET_All_DEPARTMENT(),
    enabled: Boolean(enabled.department),
  });
  const DepartmentOptions = data?.data?.map((list) => ({
    value: list.departmentid,
    label: list.title,
  }));

  const handleRemoveExperience = (index) => {
    const List = [...Experience];
    List.splice(index, 1);
    setExperience(List);
  };

  const handleImageSelect = (head) => {
    if (imageSelected?.includes(head)) {
      let list = [...imageSelected];
      let val = list?.findIndex((v) => v === head);
      list?.splice(val, 1);
      setImageSelected(list);
    } else {
      setImageSelected([...imageSelected, head]);
    }
  };

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...Experience];
    list[index][name] = value;
    setExperience(list);
  };

  const NoticePeriod = [
    { value: "Immediate", label: "Immediate" },
    { value: "Serving_Notice", label: "Serving Notice period" },
    { value: "15", label: "15 days" },
    { value: "30", label: "30 days" },
    { value: "45", label: "45 days" },
    { value: "60", label: "60 days" },
    { value: "75", label: "75 days" },
    { value: "90", label: "90 days" },
    { value: "Other", label: "Other - specify days" },
  ];

  const CVSource = [
    { value: "Naukri - Job portal", label: "Naukri - Job portal" },
    { value: "Linkedin", label: "Linkedin" },
    { value: "Indeed - Job portal", label: "Indeed - Job portal" },
    { value: "Monster - Job portal", label: "Monster - Job portal" },
    { value: "Shine - Job portal", label: "Shine - Job portal" },
    { value: "Timesjobs - Job portal", label: "Timesjobs - Job portal" },
    { value: "IIMjobs - Job portal", label: "IIM jobs - Job portal" },
    { value: "Reference", label: "Reference" },
    { value: "Internal Database", label: "Internal Database" },
    { value: "Others", label: "Others (Please specify)" },
  ];

  const handleOnChange = useCallback((e) => {
    setFile(e.target.files[0]);
  },[]);

  return (
    <>
      {isPending ? <Spinner size="medium" /> : ""}
      <div
        className={`relative z-10 ${manualCand ? `` : `hidden tansition-all`}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-scroll rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-[100%] sm:max-h-[100%]">
              <div className="relative bg-[white]">
                <img
                  src={`/images/profileimage.png`}
                  className="w-full p-1 h-[250px] object-cover"
                  alt=""
                />
                <div className="flex p-4 font-primary items-center flex-col my-10 justify-center">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-[95%] gap-10 flex-col md:flex-row flex  my-10 items-start justify-center"
                  >
                    <div className="md:w-11/12 w-full gap-3 flex items-center flex-col justify-center">
                      <div className="w-full flex items-start justify-center gap-4 md:gap-8 flex-col">
                        <div className="absolute my-auto top-[100px] left-[50px] ">
                          <div className="w-[200px] flex-col flex items-center  top-0 justify-center relative bg-[#fcfcfc] border-[#e0e0e0]  border h-[200px] rounded-[4px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
                            <FaCamera
                              className="absolute cursor-pointer right-[5px] bottom-[30px] text-[25px] text-[gray] z-[9]"
                              onClick={() => handleOnImageClick()}
                            />
                            <p className="relative text-center top-[45%]">
                              {" "}
                              Profile Image
                            </p>
                            <input
                              type="file"
                              name="image"
                              id="image-input-box"
                              onChange={handleImageChange}
                              className="w-[200px] h-[200px] opacity-0"
                              accept=".png,.jpg,.jpeg,.webp,.svg"
                            />
                            {images?.map((images, i, row) => {
                              if (i + 1 === row.length) {
                                return (
                                  <div
                                    className="w-[200px] rounded-full absolute flex items-center justify-center h-[200px]"
                                    key={i}
                                  >
                                    <img
                                      type="url"
                                      src={images}
                                      alt="a"
                                      onClick={() => handleOnImageClick()}
                                      className="w-[200px] h-[200px] object-cover rounded-full"
                                    />
                                  </div>
                                );
                              } else {
                                return <p></p>;
                              }
                            })}
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-center flex-col  gap-2">
                          <label className="w-full flex items-start justify-center flex-col">
                            <span className="text-[14px]">
                              First Name <i className="text-red">*</i>
                            </span>
                            <input
                              type="text"
                              name="firstname"
                              {...register("firstname", { required: true })}
                              placeholder="Name"
                              className={`w-full px-3 text-sm h-10 border rounded-[4px] ${
                                errors.firstname
                                  ? "border-red"
                                  : "border-border-col "
                              }`}
                            />
                          </label>

                          <div className="w-full flex items-center justify-center gap-5 my-2 flex-col md:flex-row">
                            <input
                              type="text"
                              name="middlename"
                              {...register("middlename")}
                              placeholder="Middle Name"
                              className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                            />
                            <input
                              type="text"
                              name="lastname"
                              {...register("lastname")}
                              placeholder="Last Name"
                              className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                            />
                          </div>
                          {/*  */}
                          <div className="w-full flex items-center justify-center gap-5 mb-2 flex-col md:flex-row">
                            <label className="w-full relative flex items-start justify-center flex-col">
                              <span className="text-[14px]">
                                Email address <i className="text-red">*</i>
                              </span>
                              <input
                                type="email"
                                name="email"
                                {...register("email", {
                                  required: "Email is required !",
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "invalid email address",
                                  },
                                })}
                                placeholder="john@example.com"
                                required
                                className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                              />
                              {errors.email && (
                                <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                                  {errors.email.message}
                                </span>
                              )}
                            </label>

                            <label className="w-full relative flex items-start justify-center flex-col">
                              <span className="text-[14px]">
                                Date of birth <i className="text-red">*</i>
                              </span>
                              <input
                                type="date"
                                name="dateofbirth"
                                {...register("dateofbirth")}
                                placeholder="Date of Birth"
                                className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex items-center justify-center gap-3 flex-col">
                        <div className="w-full  flex items-center justify-center gap-5 flex-col md:flex-row">
                          <label className="w-full flex items-start  justify-center flex-col">
                            <span className="text-[14px]">
                              Gender <i className="text-red">*</i>
                            </span>
                            <Controller
                              name="gender"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={Genders}
                                  className=" w-full font-primary text-black text-sm h-10 rounded-sm"
                                  styles={
                                    errors.gender &&
                                    errors.gender.type === "required"
                                      ? ErrorStyles
                                      : customStyles
                                  }
                                  cacheOptions
                                  placeholder="Select Gender"
                                  Searchable
                                />
                              )}
                            />
                          </label>
                          <label className="w-full flex items-start  justify-center flex-col">
                            <span className="text-[14px]">
                              Domain <i className="text-red">*</i>
                            </span>
                            <Controller
                              name="domain"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  onFocus={() =>
                                    setEnabled((prev) => {
                                      return {
                                        department: true,
                                        city: prev.city,
                                        skills: prev.skills,
                                      };
                                    })
                                  }
                                  isLoading={isFetchingDepartment}
                                  options={DepartmentOptions}
                                  id="departmentField"
                                  placeholder="Select Domain"
                                  className={` w-full font-primary text-sm h-10 rounded-sm`}
                                  styles={
                                    errors.domain &&
                                    errors.domain.type === "required"
                                      ? ErrorStyles
                                      : customStyles
                                  }
                                />
                              )}
                            />
                          </label>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="w-full  flex items-center justify-center gap-3 flex-col md:flex-row">
                          <label className="w-full md:w-6/12 flex items-start justify-center flex-col mt-1">
                            <span className="text-[14px]">
                              Country <i className="text-red">*</i>
                            </span>
                            <Controller
                              name="countryid"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  onFocus={() =>
                                    setEnabled((prev) => {
                                      return {
                                        department: prev.department,
                                        city: true,
                                        skills: prev.skills,
                                      };
                                    })
                                  }
                                  Searchable
                                  isLoading={isLoading}
                                  className=" w-full  font-primary text-sm h-10 rounded-sm"
                                  options={CountryOptions}
                                  placeholder="--Select City--"
                                  styles={
                                    errors.countryid &&
                                    errors.countryid.type === "required"
                                      ? ErrorStyles
                                      : customStyles
                                  }
                                />
                              )}
                            />
                          </label>
                          <label className="w-full md:w-6/12 flex items-start justify-center flex-col mt-1">
                            <span className="text-[14px]">
                              Location <i className="text-red">*</i>
                            </span>
                            <Controller
                              name="location"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  onFocus={() =>
                                    setEnabled((prev) => {
                                      return {
                                        department: prev.department,
                                        city: true,
                                        skills: prev.skills,
                                      };
                                    })
                                  }
                                  Searchable
                                  onInputChange={(e) => setCountrySearch(e)}
                                  id="locationField"
                                  isLoading={isLoading}
                                  className=" w-full  font-primary text-sm h-10 rounded-sm"
                                  options={LocationOptions}
                                  placeholder="--Select City--"
                                  styles={
                                    errors.location &&
                                    errors.location.type === "required"
                                      ? ErrorStyles
                                      : customStyles
                                  }
                                />
                              )}
                            />
                          </label>

                          {/* <div className="md:w-6/12">
                          <PhoneInputForm
                            Controller={Controller}
                            control={control}
                            watch={watch}
                            name="phone"
                            register={register}
                            required={true}
                            error={error}
                            setValue={setValue}
                          />
                          </div> */}
                        </div>
                        {/*  */}
                        <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row mt-1">
                          <label className="w-full md:w-6/12 flex items-start justify-center flex-col mt-1">
                            <span className="text-[14px]">
                              Phone Code <i className="text-red">*</i>
                            </span>
                            <select
                              name="countrycode"
                              {...register("countrycode")}
                              className="w-full  px-3 text-sm h-10 border border-border-col rounded-[4px] bg-white"
                            >
                              <CountryCodes />
                            </select>
                          </label>
                          <label className="w-full md:w-6/12 relative flex items-start justify-center flex-col">
                            <span className="text-[14px]">
                              Contact No. <i className="text-red">*</i>
                            </span>
                            <input
                              type="text"
                              name="phone"
                              {...register("phone", {
                                required: "Phone number is required !",
                                minLength: {
                                  value: 10,
                                  message:
                                    "Phone number must be at least 10 numbers !",
                                },
                                maxLength: {
                                  value: 15,
                                  message: "This input exceed maxLength.",
                                },
                                pattern: {
                                  value: /^[0-9 +-]+$/,
                                  message: "Please enter a number",
                                },
                              })}
                              placeholder="98765XXXXX"
                              className={`w-full px-3 text-sm h-10 border  rounded-[4px] ${
                                errors.phone
                                  ? "border-red"
                                  : "border-border-col "
                              }`}
                            />
                            {errors.phone ? (
                              <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                                {errors.phone.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </label>
                          {/* <PhoneInputForm
                            Controller={Controller}
                            control={control}
                            watch={watch}
                            name="alternatecontact"
                            register={register}
                            error={error}
                            setValue={setValue}
                          /> */}
                        </div>
                        <div className="w-full flex items-center justify-start gap-3 flex-col md:flex-row mt-1">
                          <label className="w-full md:w-6/12 relative flex items-start justify-center flex-col">
                            <span className="text-[14px]">
                              Alternate Contact No.{" "}
                            </span>
                            <input
                              name="alternatecontact"
                              type="text"
                              {...register("alternatecontact", {
                                minLength: {
                                  value: 10,
                                  message:
                                    "Phone number must be at least 10 numbers !",
                                },
                                maxLength: {
                                  value: 15,
                                  message: "This input exceed maxLength.",
                                },
                                pattern: {
                                  value: /^[0-9 +-]+$/,
                                  message: "Please enter a number",
                                },
                              })}
                              placeholder="98765XXXXX"
                              className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                            />
                            {errors.alternatecontact ? (
                              <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                                {errors.alternatecontact.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                        {/*  */}
                        <label className="w-full flex flex-col items-start">
                          <span className="text-[14px]">
                            Qualification <i className="text-red">*</i>
                          </span>
                          <textarea
                            type="text"
                            rows="4"
                            name="qualification"
                            {...register("qualification", { required: true })}
                            placeholder="Mention the Qualifications/ Education/Academics"
                            className="w-full h-full p-3 text-sm  border border-border-col rounded-[4px] "
                          />
                        </label>
                        {/*  */}
                        <label className="w-full flex flex-col items-start">
                          <span className="text-[14px]">
                            Add Skills <i className="text-red">*</i>
                          </span>
                          <Controller
                            name="skills"
                            control={control}
                            rules={{ required: true, minLength: 3 }}
                            render={({ field }) => (
                              <CreatableSelect
                                {...field}
                                isLoading={SkillsLoading}
                                onFocus={() =>
                                  setEnabled((prev) => {
                                    return {
                                      department: prev.department,
                                      city: prev.city,
                                      skills: true,
                                    };
                                  })
                                }
                                options={SkillsOptions}
                                onInputChange={handleAdditionalSkillsInput}
                                className="outline-none table  w-full font-primary text-sm h-10 rounded-sm"
                                styles={
                                  errors.skills &&
                                  errors.skills.type === "required"
                                    ? ErrorStyles
                                    : customStyles
                                }
                                Searchable
                                id="mandatoryskills"
                                isMulti
                                placeholder="Search... Skills"
                              />
                            )}
                          />
                        </label>
                        {/*  */}
                        <div className="w-full flex items-end justify-center gap-3 flex-col md:flex-row">
                          <label className="w-full gap-5 flex flex-col items-start">
                            <span className="text-[14px]">
                              Experience <i className="text-red">*</i>
                            </span>
                            <label className="w-full flex flex-row  justify-startn gap-4 items-center">
                              <input
                                type="checkbox"
                                name="fresher"
                                value="1"
                                {...register("fresher")}
                                placeholder="Fresher"
                              />{" "}
                              Fresher
                            </label>
                          </label>
                          <label className="w-full gap-5 flex flex-col items-start relative">
                            <input
                              type="text"
                              name="totalexperience"
                              {...register("totalexperience", {
                                required: true,
                                pattern: {
                                  value: /^\d+(\.\d+)?$/,
                                  message:
                                    "Please enter experience in number like 12, 13, 10.8 etc.",
                                },
                              })}
                              placeholder="Experience in year"
                              className={`w-full px-3 text-sm h-10 border rounded-[4px] ${
                                errors.totalexperience
                                  ? "border-red"
                                  : "border-border-col"
                              }`}
                            />
                            {errors.totalexperience &&
                            errors.totalexperience.message ? (
                              <span className="absolute text-[12px] top-[38px] text-red">
                                {errors.totalexperience.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                        {Experience?.map((data, index) => (
                          <div
                            key={index + index}
                            className="w-full flex items-center justify-center flex-col gap-4"
                          >
                            <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row">
                              <input
                                type="text"
                                onChange={(e) => onChange(e, index)}
                                value={data.employer}
                                name="employer"
                                placeholder="Employer"
                                className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                              />
                              <input
                                type="text"
                                onChange={(e) => onChange(e, index)}
                                value={data.jobprofile}
                                name="jobprofile"
                                placeholder="Job Profile"
                                className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                              />
                            </div>
                            {/*  */}
                            {/*  */}
                            <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row">
                              {/* <Controller
                              name={data.location}
                              control={control}
                              render={({ field , value }) => ( */}
                              <Select
                                // {...field}
                                Searchable
                                name="city"
                                value={data.city}
                                onInputChange={(e) => setCountrySearch(e)}
                                isLoading={isLoading}
                                onChange={(e, action) =>
                                  handleExLocation(e, action, index)
                                }
                                onFocus={() =>
                                  setEnabled((prev) => {
                                    return {
                                      department: prev.department,
                                      city: true,
                                      skills: prev.skills,
                                    };
                                  })
                                }
                                className="md:w-6/12 w-full font-primary text-sm h-10 rounded-sm"
                                options={LocationOptions}
                                placeholder="--Select City--"
                              />
                              {/* )}
                            /> */}
                              <input
                                type="text"
                                value={data.jobperiod}
                                name="jobperiod"
                                placeholder="Job Period"
                                onChange={(e) => onChange(e, index)}
                                className="w-full md:w-6/12 px-3 text-sm h-10 border border-border-col rounded-[4px] "
                              />
                            </div>
                            <div
                              className={`${
                                index === 0 ? "justify-end" : "justify-between"
                              } w-full gap-3 flex items-center `}
                            >
                              {Experience?.length - 1 === index &&
                                index !== 0 && (
                                  <p
                                    onClick={() =>
                                      handleRemoveExperience(index)
                                    }
                                    className="flex text-[red] items-center text-red cursor-pointer"
                                  >
                                    <FaTrash /> Delete
                                  </p>
                                )}
                              {/* {getValues("fresher") === "1" &&}   */}
                              {Experience?.length - 1 === index && (
                                <p
                                  onClick={handleAddExperience}
                                  className={`flex text-[green] items-center text-green cursor-pointer`}
                                >
                                  <FaPlus /> Add Another
                                </p>
                              )}
                            </div>
                          </div>
                        ))}

                        {/*  */}
                        <div className="w-full my-10 flex items-center justify-center gap-3 flex-col md:flex-row">
                          <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                            <span className="text-[14px]">
                              Currency<i className="text-red">*</i>
                            </span>
                            <Controller
                              name="currencyid"
                              control={control}
                              rules={{ required: false }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  filterOption={createFilter({
                                    ignoreAccents: false,
                                  })}
                                  options={CurrencyOptions}
                                  className=" w-full font-primary text-sm h-10 rounded-sm"
                                  styles={customStyles}
                                  Searchable
                                  // defaultValue={{
                                  //   value: "INR-\u20b9",
                                  //   label: "INR-\u20b9",
                                  // }}
                                  placeholder="Search... Currency"
                                />
                              )}
                            />
                          </label>
                          <label className="md:w-6/12 relative w-full flex items-start justify-center flex-col">
                            <span className="text-[14px]">
                              Current Salary <i className="text-red">*</i>
                            </span>
                            <input
                              type="text"
                              name="currentsalary"
                              {...register("currentsalary", {
                                required: "currentsalary is required !",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Please enter a number",
                                },
                                validate:
                                  Number(watch("fresher")) !== 1
                                    ? {
                                        positiveNumber: (value) =>
                                          parseFloat(value) > 10000 ||
                                          "Current Salary is must be greater than 10000",
                                      }
                                    : false,
                              })}
                              placeholder="Current Salary"
                              className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                            />
                            {errors.currentsalary ? (
                              <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                                {errors.currentsalary.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </label>
                          <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                            <span className="text-[14px]">Salary Tenure</span>
                            <select
                              name="salary_tenure"
                              {...register("salary_tenure")}
                              className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] bg-white"
                            >
                              <option value="1">Yearly</option>
                              <option value="2">Monthly</option>
                              <option value="3">Hourly</option>
                            </select>
                          </label>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="w-full my- flex items-center justify-center gap-3 flex-col md:flex-row">
                          <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                            <span className="text-[14px]">
                              Currency<i className="text-red">*</i>
                            </span>
                            <Controller
                              name="currencyid2"
                              control={control}
                              rules={{ required: false }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  filterOption={createFilter({
                                    ignoreAccents: false,
                                  })}
                                  options={CurrencyOptions}
                                  className=" w-full font-primary text-sm h-10 rounded-sm"
                                  styles={customStyles}
                                  Searchable
                                  // defaultValue={{
                                  //   value: "INR-\u20b9",
                                  //   label: "INR-\u20b9",
                                  // }}
                                  placeholder="Search... Currency"
                                />
                              )}
                            />
                          </label>
                          <label className="md:w-6/12 w-full relative flex items-start justify-center flex-col">
                            <span className="text-[14px]">
                              Expected Salary <i className="text-red">*</i>
                            </span>
                            <input
                              type="text"
                              name="expectedsalary"
                              {...register("expectedsalary", {
                                required: "expectedsalary is required !",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Please enter a number",
                                },
                                validate: {
                                  positiveNumber: (value) =>
                                    parseFloat(value) > 100000 ||
                                    "Expected Salary is must be greater than 100000",
                                },
                              })}
                              placeholder="Expected Salary"
                              className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                            />
                            {errors.expectedsalary ? (
                              <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                                {errors.expectedsalary.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </label>
                          <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                            <span className="text-[14px]">Salary Tenure</span>
                            <select
                              name="salary_tenure"
                              {...register("salary_tenure")}
                              className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] bg-white"
                            >
                              <option value="1">Yearly</option>
                              <option value="2">Monthly</option>
                              <option value="3">Hourly</option>
                            </select>
                          </label>
                        </div>
                        {/*  */}
                        <div className="w-full flex items-center justify-start gap-3 flex-col md:flex-row">
                          <label className=" md:w-6/12 w-full flex items-start justify-center flex-col">
                            <span className="text-[14px]">
                              {" "}
                              Notice Period <i className="text-red">*</i>
                            </span>
                            <Controller
                              name="noticeperiod"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  Searchable
                                  id="noticeperiod"
                                  isLoading={isLoading}
                                  className=" w-full font-primary text-sm h-10 rounded-sm"
                                  options={NoticePeriod}
                                  placeholder="--Select Notice--"
                                  styles={
                                    errors.noticeperiod &&
                                    errors.noticeperiod.type === "required"
                                      ? ErrorStyles
                                      : customStyles
                                  }
                                />
                              )}
                            />
                          </label>
                          {watch("noticeperiod")?.value ===
                            "Serving_Notice" && (
                            <label className=" md:w-6/12 w-full flex relative items-start justify-center flex-col">
                              <p className="opacity-0">null</p>
                              <input
                                name="noticeenddate"
                                {...register("noticeenddate", {
                                  required: "Required",
                                })}
                                type="date"
                                placeholder="Enter Notice Period in days"
                                className="w-full rounded-[5px] px-2 border border-border-col h-10"
                              />
                              {errors.noticeenddate ? (
                                <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                                  {errors.noticeenddate.message}
                                </span>
                              ) : (
                                ""
                              )}
                            </label>
                          )}
                          {watch("noticeperiod")?.value === "Immediate" && (
                            <label className=" md:w-6/12 w-full flex relative items-center justify-start flex-row">
                              {/* <span className={` text-red mr-1 text-[25px]`}>*</span> */}
                              <input
                                name="offerinhand"
                                {...register("offerinhand")}
                                value="1"
                                type="checkbox"
                                className={"mr-1"}
                              />
                              <p
                                className={`${
                                  errors.offerinhand && "text-red"
                                }`}
                              >
                                {" "}
                                Offer In Hand
                              </p>
                              {errors.offerinhand ? (
                                <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                                  {errors.offerinhand.message}
                                </span>
                              ) : (
                                ""
                              )}
                            </label>
                          )}
                          {watch("noticeperiod")?.value === "Other" && (
                            <label className=" md:w-6/12 w-full flex relative items-start justify-center flex-col">
                              <p className="opacity-0">null</p>
                              <input
                                name="othernoticeperiod"
                                {...register("othernoticeperiod", {
                                  required: "Required",
                                })}
                                type="text"
                                placeholder="Enter Notice Period in days"
                                className="w-full rounded-[5px] px-2 border border-border-col h-10"
                              />
                              {errors.noticeperioddays ? (
                                <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                                  {errors.noticeperioddays.message}
                                </span>
                              ) : (
                                ""
                              )}
                            </label>
                          )}
                        </div>
                        {/*  */}
                        {/*  */}
                        <label className="w-full flex flex-col items-start">
                          <span className="text-[14px]">Agency's Comment</span>
                          <textarea
                            type="text"
                            rows="1"
                            name="agencycomment"
                            {...register("agencycomment")}
                            placeholder="Mention the Qualifications/ Education/Academics"
                            className="w-full p-3 text-sm  border border-border-col rounded-[4px] "
                          />
                        </label>
                        {/*  */}
                        {/*  */}
                        <div className="w-full flex items-center justify-start gap-3 flex-col md:flex-row">
                          <label className=" md:w-6/12 w-full flex items-start justify-center flex-col">
                            <span className="text-[14px]">
                              Source of CV <i className="text-red">*</i>
                            </span>

                            <Controller
                              name="cvsource"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  Searchable
                                  id="cvsource"
                                  className=" w-full font-primary text-sm h-10 rounded-sm"
                                  options={CVSource}
                                  placeholder="--CV Source--"
                                  styles={
                                    errors.cvsource &&
                                    errors.cvsource.type === "required"
                                      ? ErrorStyles
                                      : customStyles
                                  }
                                />
                              )}
                            />
                          </label>
                        </div>
                        {/*  */}
                        {/* {Assets.map(())} */}
                        <div className="w-full flex my-4 items-start justify-center flex-col">
                          <p className="text-[14px] my-3">
                            Please identify the candidate's Diversity parameter
                          </p>
                          <div className="w-full xl:w-9/12 flex items-center justify-between flex-wrap mr-auto gap-3 lg:ml-6">
                            {FormAssets?.map(({ img_src, head }, index) => (
                              <div
                                onClick={() => handleImageSelect(head)}
                                className=" flex items-center justify-center flex-col gap-y-4"
                                key={index}
                              >
                                <p className="font-normal font-primary text-[10px]">
                                  {head}
                                </p>
                                <div
                                  className={` ${
                                    imageSelected?.includes(head)
                                      ? " border-border-col "
                                      : "border-white"
                                  } w-15  p-3 transition-all duration-300 border relative`}
                                >
                                  {imageSelected?.includes(head) ? (
                                    <div className=" transition-all duration-300 w-5 h-5 bg-green text-white -top-1 -left-1 rounded-full absolute flex items-center justify-center">
                                      &#10003;
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <img
                                    src={img_src}
                                    alt={head}
                                    className={` ${
                                      imageSelected?.includes(head)
                                        ? "   border-border-col image_box_shadow "
                                        : "border-white"
                                    }  z-[1] w-10 border cursor-pointer transition-all duration-300`}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        {/*  */}
                        <label className="w-full flex flex-col items-start">
                          <span className="text-[14px]">
                            Please describe about candidate's Diversity
                            parameter
                          </span>
                          <textarea
                            type="text"
                            rows="1"
                            name="diversitycomment"
                            {...register("diversitycomment")}
                            placeholder="Mention specific comments regarding the Diversity of this candidate, if any."
                            className="w-full p-3 text-sm  border border-border-col rounded-[4px] "
                          />
                        </label>
                        {/*  */}
                        <div className="w-full">
                          <h3 className="text-left text-[14px]">CV Document</h3>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <div className="w-full flex-col  flex gap-5 mb-5 items-start justify-start ">
                            {file?.name ? (
                              <div
                                style={{ wordBreak: "break-all" }}
                                className="text-xs flex items-end justify-center"
                              >
                                <p className="flex text-[16px] text-base-col items-center justify-center gap-2">
                                  {file?.name}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="flex gap-4 items-center justify-center">
                              <label className="w-[50px] h-[53px] bg-white rounded-[4px] border cursor-pointer border-border-col">
                                <img
                                  src={`${AMAZONS3}/images/upload.png`}
                                  alt=""
                                />
                                <input
                                  type="file"
                                  name="resume"
                                  onChange={(e) => handleOnChange(e)}
                                  className=" w-[100px] relative bottom-10 opacity-0"
                                  accept=".pdf,.doc,.docx"
                                />
                              </label>{" "}
                              <span className="text-[14px]">Upload Resume</span>
                            </div>

                            {/* <div className="w-full" id="AddFile">
                              <h3 className="text-left font-medium">Addition Documents</h3>
                            </div>
                            
                            <div className="w-full flex items-start">
                              <div className={`flex items-center px-3 bg-white  rounded cursor-pointer mr-5 w-[30%] ${errors.attachmenttext2? "border-[0.5px] border-red" : attachmentfile?.attachment1 ?  "border-[0.5px] border-green":"border border-blue"} `}>
                              {attachmentfile?.attachment1?.name? 
                              <FaUpload  size={30} className="text-[20px] px-2" onClick={() => handleClicks("attachment1")} />: 
                              attachmentfile?.attachment1 ? <a href={attachmentfile?.attachment1}><FaDownload size={30} className="text-[20px] text-green px-2" /></a>: 
                                <FaUpload size={30} className="text-[20px] px-2"  onClick={() => handleClicks("attachment1")}/> }
                                
                                <input
                                  type="file"
                                  name="attachment1"
                                  {...register("attachment1")}
                                  id="attachment1"
                                  onChange={(e) => handlechangeAttachmentFile(e)}
                                  accept=".pdf,.doc,.docx"
                                  className=" w-[2px] relative opacity-0"
                                />
                                <div className="text-[14px] w-full">
                                  {attachmentfile?.attachment1? <input
                                    type="text"
                                    name="attachmenttext1"
                                    {...register("attachmenttext1", {required : true})}
                                    placeholder="Enter Document Name"
                                    className="w-full h-10 text-[14px] outline-none rounded-[4px]"
                                  /> : <div className="text-[14px] py-2 block" onClick={() => handleClicks("attachment1")}>Select Document</div>}
                                </div>
                              </div>
                              {attachments.length < 5 ? (
                                <p
                                onClick={() => handleAddDocument(`attachment${attachments.length+1}`)}
                                className="text-green cursor-pointer text-[14px] border-[0.5px] inline px-3 py-2 rounded hover:bg-green hover:text-white  border-green"
                              >
                                +Add Document
                              </p>
                              ) : (<p
                                // onClick={() => handleAddDocument('attachment'+attachments+1)}
                                className="text-green cursor-pointer text-[14px] border-[0.5px] inline px-3 py-2 rounded hover:bg-green hover:text-white  border-green"
                              >
                                +Add Document
                              </p>)  }
                            </div> */}

                            {/* attachent2 */}
                            {/* {attachments.includes(2) && (
                              <div className="w-full flex items-center ">
                                <div className={`flex items-center px-3 bg-white rounded cursor-pointer mr-5 w-[30%] ${errors.attachmenttext2 ? "border-[0.5px] border-red" :  attachmentfile?.attachment2 ?  "border-[0.5px] border-green":"border border-blue"} `}>
                                  {attachmentfile?.attachment2?.name? <FaUpload className="px-2" size={25} onClick={() => handleClicks("attachment2")} />: attachmentfile?.attachment2 ? <a href={attachmentfile?.attachment2}><FaDownload size={25} className="text-green px-2" /></a>: <FaUpload className="px-2" size={25}  onClick={() => handleClicks("attachment2")}/> }
                                  <input
                                    type="file"
                                    name="attachment2"
                                    {...register("attachment2")}
                                    id="attachment2"
                                    onChange={(e) => handlechangeAttachmentFile(e)}
                                    accept=".pdf,.doc,.docx"
                                    className=" w-[2px] relative opacity-0"
                                  />
                                  <div className="w-full">
                                  {attachmentfile?.attachment2? <input
                                    type="text"
                                    name="attachmenttext2"
                                    {...register("attachmenttext2", {required : true})}
                                    placeholder="Enter Document Name"
                                    className="w-full h-10 text-[14px] outline-none rounded-[4px]"
                                  /> : <div className="text-[14px] py-2 block" onClick={() => handleClicks("attachment2")}>Select Document</div>}
                                  </div>
                                </div>
                                <div className="w-[20%]">
                                      <FaTrash className="text-red text-[20px] cursor-pointer"  onClick={() => DeleteAttachment(2)} />
                                </div>
                              </div>
                            )} */}

                            {/* attachment3 */}
                            {/* {attachments.includes(3) && (
                              <div className="w-full flex items-center">
                              <div className={`flex items-center px-3 bg-white rounded cursor-pointer mr-5 w-[30%] ${errors.attachmenttext3 ? "border-[0.5px] border-red":attachmentfile?.attachment3 ?  "border-[0.5px] border-green":"border border-blue"} `}>

                              {attachmentfile?.attachment3?.name? <FontAwesomeIcon icon={faUpload} className="text-[20px] px-2" onClick={() => handleClicks("attachment3")} />: attachmentfile?.attachment3 ? <a href={attachmentfile?.attachment3}><FontAwesomeIcon icon={faDownload} className="text-[20px text-green px-2" /></a>: <FontAwesomeIcon icon={faUpload} className="text-[20px] px-2"  onClick={() => handleClicks("attachment3")}/> }

                                <input
                                  type="file"
                                  name="attachment3"
                                  {...register("attachment3")}
                                  id="attachment3"
                                  onChange={(e) => handlechangeAttachmentFile(e)}
                                  accept=".pdf,.doc,.docx"
                                  className=" w-[2px] relative opacity-0"
                                />
                                <div className="w-full">
                                {attachmentfile?.attachment3? <input
                                    type="text"
                                    name="attachmenttext3"
                                    {...register("attachmenttext3", {required : true})}
                                    placeholder="Enter Document Name"
                                    className="w-full h-10 text-[14px] outline-none rounded-[4px]"
                                  /> : <div className="text-[14px] py-2 block" onClick={() => handleClicks("attachment3")}>Select Document</div>}
                                </div>
                              </div>
                              <div className="w-[20%]">
                                <FaTrash className="text-red text-[20px] cursor-pointer"  onClick={() => DeleteAttachment(3)} />
                              </div>
                            </div>
                            )} */}

                            {/* attachment4 */}
                            {/* {attachments.includes(4) && (
                              <div className="w-full flex items-center">
                                <div className={`flex items-center px-3 bg-white rounded cursor-pointer mr-5 w-[30%] ${errors.attachmenttext4 ? "border-[0.5px] border-red": attachmentfile?.attachment4 ?  "border-[0.5px] border-green":"border border-blue"} `}>

                                {attachmentfile?.attachment4?.name? <FaUpload className="text-[20px] px-2" onClick={() => handleClicks("attachment4")} />: attachmentfile?.attachment4 ? <a href={attachmentfile?.attachment4}><FontAwesomeIcon icon={faDownload} className="text-[20px] text-green px-2" /></a>: <FontAwesomeIcon icon={faUpload} className="text-[20px] px-2"  onClick={() => handleClicks("attachment4")}/> }

                                  <input
                                    type="file"
                                    name="attachment4"
                                    {...register("attachment4")}
                                    id="attachment4"
                                    onChange={(e) => handlechangeAttachmentFile(e)}
                                    accept=".pdf,.doc,.docx"
                                    className=" w-[2px] relative opacity-0"
                                  />
                                  <div className="text-[14px] w-full">
                                    {attachmentfile?.attachment3? <input
                                      type="text"
                                      name="attachmenttext4"
                                      {...register("attachmenttext4", {required : true})}
                                      placeholder="Enter Document Name"
                                      className="w-full h-10 text-[14px] outline-none rounded-[4px]"
                                    /> : <div className="text-[14px] py-2 block" onClick={() => handleClicks("attachment4")}>Select Document</div>}
                                  </div>
                                </div>
                                <div className="w-[20%]">
                                  <FaTrash className="text-red text-[20px] cursor-pointer"  onClick={() => DeleteAttachment(4)} />
                                </div>
                              </div>
                            )} */}

                            {/* attachment5 */}
                            {/* {attachments.includes(5) && (
                              <div className="w-full flex items-center">
                              <div className={`flex items-center px-3 bg-white rounded cursor-pointer mr-5 w-[30%] ${errors.attachmenttext5 ? "border-[0.5px] border-red":attachmentfile?.attachment5 ? "border-[0.5px] border-green":"border border-blue"} `}>
                                
                              {attachmentfile?.attachment5?.name? <FaUpload className="text-[20px] px-2" onClick={() => handleClicks("attachment5")} />: attachmentfile?.attachment5 ? <a href={attachmentfile?.attachment5}><FontAwesomeIcon icon={faDownload} className="text-[20px] text-green px-2" /></a>: <FontAwesomeIcon icon={faUpload} className="text-[20px] px-2"  onClick={() => handleClicks("attachment5")}/> }

                                <input
                                  type="file"
                                  name="attachment5"
                                  {...register("attachment5")}
                                  id="attachment5"
                                  onChange={(e) => handlechangeAttachmentFile(e)}
                                  accept=".pdf,.doc,.docx"
                                  className=" w-[2px] relative opacity-0"
                                />
                                <div className="text-[14px] w-full">
                                    {attachmentfile?.attachment3? <input
                                      type="text"
                                      name="attachmenttext5"
                                      {...register("attachmenttext5", {required : true})}
                                      placeholder="Enter Document Name"
                                      className="w-full h-10 text-[14px] outline-none rounded-[4px]"
                                    /> : <div className="text-[14px] py-2 block" onClick={() => handleClicks("attachment5")}>Select Document</div>}
                                </div>
                              </div>
                              <div className="w-[20%]">
                                <FaTrash className="text-red text-[20px] cursor-pointer"  onClick={() => DeleteAttachment(5)} />
                              </div>
                            </div>
                            )} */}

                            {/* attachment */}

                            <label>
                              <input
                                type="checkbox"
                                {...register("status")}
                                name="status"
                                className="mr-2"
                                value="1"
                              />
                              Active Candidate
                            </label>
                            {/* <label>
                              <input
                                type="checkbox"
                                value="true"
                                name="aimatchavailability"
                                {...register("aimatchavailability")}
                                className="mr-2"
                              />
                              Available for Intelli-Match
                            </label> */}
                          </div>
                        </div>
                        {/*  */}

                        <div className="flex items-center w-full justify-center gap-3">
                          <button
                            type="button"
                            onClick={() => setManualCand(false)}
                            className="px-3 py-1 bg-white border cursor-pointer border-green text-green rounded-full"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="bg-[green] text-white px-3 rounded-full py-1"
                          >
                            {" "}
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
