import * as localStorage from './Storage';

const USER_ROLE = 'anonymous_roleid';

const Role = () => {
  function _setRole(Role) {
    localStorage.set(USER_ROLE, Role);    
  }

  function _getAccessRole() {
    return localStorage.get(USER_ROLE);
  }

  function _clearRole() {
    localStorage.remove(USER_ROLE);
  }

  return {
    setRole: _setRole,
    getAccessRole: _getAccessRole,    
    clearRole: _clearRole,
  };
};

export default Role();