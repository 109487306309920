import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const parseJobData = ({data, token}) => {
    const URL = `${endpoint.parseJob.links}`
    const headers  = {
        "Authorization": `Bearer ${token}` 
    }
    return Http().post(URL, data, headers);
}


const copypastjob = ({JdDescription, token}) => {
  const URL = `${endpoint.parseJob.copypast}`
  const headers  = {
      "Authorization": `Bearer ${token}` 
  }
  return Http().post(URL, {JdDescription}, headers);
}

const documentupload = ({data, token}) => {
    const URL = `${endpoint.parseJob.documentupload}`;
    const headers  = {
        "Content-Type": "multipart/form-data", 
        "Authorization": `Bearer ${token}` 
    }
    return Http().post(URL, data, headers);
}

export const useParseJob = () => {
  return useMutation({
      mutationFn:parseJobData,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

export const useDocumentUploadJob = () => {
  return useMutation({
      mutationFn:documentupload,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

export const useCopyPastJob = () => {
  return useMutation({
      mutationFn:copypastjob,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}





