import { useMutation } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";

const addCampaign = (postData) => {
  const URL = endpoint.campaign.create;
  return Http().post(URL, postData);
};

export const useCreateCampaign = () => {
  return useMutation({
    mutationFn: addCampaign,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
};
