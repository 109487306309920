import React from "react";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";

export default function AdminDashboard(){
    return (
        <AdminLayouts>
            <div className="bg-green-50 w-full h-[100vh]">
                <div className="mt-[30px] mb-[30px]">
                    <div className="p-[30px] max-sm:p-[15px]">
                    </div>
                </div>
            </div>
        </AdminLayouts>
    );
}