import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const addTeamUser = ({data, token}) => {
    console.log(token);
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.team.team}`;
    const headers  = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` 
    }
    return Http().post(URL, data, headers);
  }


export const useTeamAdd = () => {
    return useMutation({
        mutationFn:addTeamUser,
        onSuccess: (data, variables, context) => {
          // I will fire first
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}
