import React from "react";
import UserLayout from "../../../Layouts/Users/UserLayout";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import { useQuery } from "@tanstack/react-query";
import { SlMenu } from "react-icons/sl";
import { Link } from "react-router-dom";
import { Spinner } from "../../Loader/Spinner";
import Token from "../../../Helpers/Token";
import { FaEdit } from "react-icons/fa";

export default function Team() {
  const getallpaneluser = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.team.team}`;
    return Http().get(URL, {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token.getAccessToken()}`,
    });
  };
  const { data, isLoading } = useQuery({
    queryKey: ["getallpanel"],
    queryFn: getallpaneluser,
    enabled: Boolean(Token.getAccessToken()),
  });

  return (
    <UserLayout>
      {({ open, setOpen }) => (
        <div className="h-[100vh] w-full  bg-green-50 flex items-center flex-col">
          <div className="w-full fixed top-0 block sm:hidden">
            <div className="w-full flex items-center justify-between px-6 min-h-[80px]">
              <p className="font-robo text-[30px] text-green-900">{process.env.REACT_APP_APP_NAME}</p>
              {open ? (
                ""
              ) : (
                <SlMenu onClick={() => setOpen((v) => !v)} size={20} />
              )}
            </div>
          </div>
          <div className="w-11/12 max-sm:w-11/12 h-[auto] flex justify-center max-sm:mt-[50px]">
            <div className="bg-white w-[100%] mt-[30px] mb-[30px] rounded-[10px] px-3 py-2">
              <div className="flex justify-between items-center">
                <h3>List of Member</h3>
                <Link
                  to={"/add-team"}
                  className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800"
                >
                  <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                    + Member
                  </span>
                </Link>
              </div>
              <div className="py-5">
                <div className="relative overflow-x-auto py-4">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          First Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          <div className="flex items-center">
                            Email
                            <Link>
                              <svg
                                className="w-3 h-3 ms-1.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                              </svg>
                            </Link>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                          <div className="flex items-center">
                            Role
                            <Link>
                              <svg
                                className="w-3 h-3 ms-1.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                              </svg>
                            </Link>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                          <div className="flex items-center">
                            Status
                            <Link>
                              <svg
                                className="w-3 h-3 ms-1.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                              </svg>
                            </Link>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                          <span className="sr-only">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <div className="text-center w-full">
                          <Spinner />
                        </div>
                      ) : (
                        ""
                      )}
                      {data?.data.map((row) => (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {row?.fname}
                          </th>
                          <td className="px-6 py-4">{row?.email}</td>
                          <td className="px-6 py-4">{row?.role}</td>
                          <td className="px-6 py-4">{row?.status}</td>
                          <td className="px-6 py-4 text-right">
                            <Link
                              to={`/add-team/${row?.userid}`}
                              className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            >
                              <FaEdit size={18} className="text-[green]" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </UserLayout>
  );
}
