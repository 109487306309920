import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const contactDetail = ({postdata, token}) => {
    const URL = `${endpoint.parseContactDetail.contactdetail}`
    const headers  = {
        "Authorization": `Bearer ${token}` 
      }
    return Http().post(URL, postdata, headers);
}

export const useContactDetail = () => {
  return useMutation({
      mutationFn:contactDetail,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}



