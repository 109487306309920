// src/components/TransactionHistory.js
import React from 'react';
import UserLayout from '../../../Layouts/Users/UserLayout';

const TransactionHistory = () => {
  // Example transactions data
  const transactions = [
    { id: 1, date: '2024-11-01', amount: '$250.00', status: 'Completed', paymentMethod: 'Credit Card' },
    { id: 2, date: '2024-10-15', amount: '$120.00', status: 'Pending', paymentMethod: 'PayPal' },
    { id: 3, date: '2024-09-30', amount: '$350.00', status: 'Failed', paymentMethod: 'Debit Card' },
    { id: 4, date: '2024-09-15', amount: '$600.00', status: 'Completed', paymentMethod: 'Bank Transfer' },
    { id: 5, date: '2024-08-25', amount: '$50.00', status: 'Completed', paymentMethod: 'Credit Card' },
  ];

  return (
    <UserLayout>
    <div className="w-[95%] mx-auto my-10 p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Transaction History</h1>

      <table className="min-w-full bg-gray-100 border-collapse">
        <thead>
          <tr>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Transaction ID</th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Date</th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Amount</th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Status</th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Payment Method</th>
          </tr>
        </thead>

        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id} className="border-t border-gray-200">
              <td className="py-3 px-6 text-sm text-gray-700">{`#${transaction.id}`}</td>
              <td className="py-3 px-6 text-sm text-gray-700">{transaction.date}</td>
              <td className="py-3 px-6 text-sm text-gray-700">{transaction.amount}</td>
              <td className={`py-3 px-6 text-sm text-gray-700 ${getStatusClass(transaction.status)}`}>
                {transaction.status}
              </td>
              <td className="py-3 px-6 text-sm text-gray-700">{transaction.paymentMethod}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </UserLayout>
  );
};

// Helper function to style the status column
const getStatusClass = (status) => {
  switch (status) {
    case 'Completed':
      return 'text-green-500';
    case 'Pending':
      return 'text-yellow-500';
    case 'Failed':
      return 'text-red-500';
    default:
      return '';
  }
};

export default TransactionHistory;
