import { useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useDocumentUploadJob } from "../../../../hooks/apihooks/useParseJob";
import Token from "../../../../Helpers/Token";
import { Spinner } from "../../../Loader/Spinner";
import PreviewPopup from "./PreviewPopup";
import { toast } from "react-toastify";
import DocumentUploadLoader from "../../../Loader/DocumentUploadLoader";
export function UploadDocument({setGoogleSearch}){
    const {mutateAsync, isPending} = useDocumentUploadJob();
    const [file, setFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const handleFileChange = (e) => {
      setFile(e.target.files[0]); // Get the selected file
    };

    const onSubmitHandle = (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('file', file); // Append file
      mutateAsync({data: formData, token : Token.getAccessToken()}).then((resp) => {

        if(resp?.status===true){
          setData(resp);
          setOpen(true)
        }else{
          toast.error(resp?.error);
        }
      })
    }

    return (
      <>
        {isPending ? <DocumentUploadLoader size="medium"/>: ""}
        <PreviewPopup open={open} setOpen={setOpen} getdata={data} setGoogleSearch={setGoogleSearch} />
        <form className="w-full" onSubmit={onSubmitHandle}>
        <div className="mt-3 text-center sm:text-left w-full">
          <div className="w-full flex items-start justify-center flex-col gap-2">
            <label for="uploadFile1"
              class=" w-full bg-white text-gray-500 font-semibold text-base rounded  h-52 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed mx-auto">
              <IoCloudUploadOutline  size={50}/>
              Upload file
              <input type="file" accept=".pdf, .docx, .doc" onChange={handleFileChange} id='uploadFile1' class="hidden" />
              <p class="text-xs font-medium text-gray-400 mt-2">Only PDF, Docx.</p>
            </label>
          </div>
        </div>
        <div className="py-3 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
              >
                Generate
              </button>
            </div>
        </form>
    </>
    )
}