import axios from "axios";
import { getHeader } from "./Helpers/header";
import { toast } from "react-toastify";

export const API_ROOT = process.env.REACT_APP_APIENDPOINT;

export const ERROR = {
  Signature: "Signature verification failed",
  Service: "Service Unavailable , please try again",
  Token: "Error: Token is expired or user has logged in from other device",
  TokenExpire: "Token is expired",
  Token2: "You are not authorized. Please contact at contact@hiringplug.com",
};

export const AMAZONS3 = process.env.REACT_APP_S3_URL;

const Http = (baseURL = API_ROOT, timeout = 20000, headertype = "json") => {
  const header = getHeader(headertype);

  const client = axios.create({
    // withCredentials: false,
    baseURL,
    timeout,
    header,
  });

  // Intercept response object and handleSuccess and Error Object
  client.interceptors.response.use(handleSuccess, handleError);

  function handleSuccess(response) {
    return response;
  }

  function handleError(error) {
    const status = error.response?.status;
    if (status === 401 || status === 503) {
      handleCommonError(status);
    }
    return status !== 500
      ? Promise.reject(error.response?.data)
      : Promise.reject(error);
  }

  function handleCommonError(status) {
    if (typeof window !== "undefined") {
      if (status === 401) {
        window.location.replace("/error?error=token is expire pls login agin");
        // handleTokenExpired();
      } else {
        toast.warn(ERROR.Service);
      }
    } else {
      console.warn(
        "Window is not defined, skipping redirect and localStorage clearing."
      );
    }
  }

  function handleTokenExpired() {
    if (typeof window !== "undefined") {
      localStorage.clear();
      window.location.reload();
    } else {
      console.warn(
        "Window is not defined, skipping redirect and localStorage clearing."
      );
    }
  }

  async function get(path, headers = null) {
    try {
      const response = await client.get(path, {
        headers: headers ? headers : header,
      });
      const data = response.data;
      return data;
    } catch (error) {
      if (!error.status && error.message === "Unauthorized") {
        handleTokenExpired();
      }
      throw error;
    }
  }

  async function post(path, payload, headers = null) {
    try {
      const response = await client.post(path, payload, {
        headers: headers ? headers : header,
        onUploadProgress: (progressEvent) => {
          // Return loaded and total to track progress
          return {
              loaded: progressEvent.loaded,
              total: progressEvent.total,
          };
        },
      });
      return response.data;
    } catch (error) {
      if (!error?.status && error?.message === "Unauthorized") {
        handleTokenExpired();
      }
    }
  }

  async function put(path, payload, headers=null) {
    try {
      const response = await client.put(path, payload, {
        headers: headers ? headers : header,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function patch(path, payload, headers = null) {
    try {
      const response = await client.patch(path, payload, {headers :header});
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function _delete(path, data, header) {
    try {
      if (data) {
        const response = await client.delete(path, {
          data: data,
          headers: header,
        });
        return response.data;
      } else {
        const response = await client.delete(path, {
          headers: header,
        });
        return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return {
    get: get,
    post,
    put,
    patch,
    delete: _delete,
  };
};

export default Http;
